import React from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import { PADDING_SIDES } from "../../../../static/misc/constants";
import { guidGenerator } from "../../../../static/misc/utils";
import { ExamParam, ExamQuestion } from "../../../modules/exams/types/exam";
import { ModifyBookMarkedAction } from "../../../modules/examTaking/types/examTaking";
import { TimerTypeEnum } from "../../atoms/ExamTakingTimer";
import ExamHeaderQuestion from "../../molecules/ExamHeaderQuestion";
import HtmlRenderer from "../../atoms/HtmlRenderer";

export interface QuestionTemplateProps {
  examParams?: ExamParam;
  questionNumber: number;
  question: ExamQuestion;
  examId: string;
  isBookMarked: boolean | undefined;
  examEntitled?: string;
  questionPoints: number;
  duration?: number;
  forceStopTimers: boolean;
  currentExamPart?: number;
  onFinishedTimer: (timerType: TimerTypeEnum) => void;
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
}

export interface QuestionTemplateState {
  width: number;
}

class QuestionTemplate extends React.PureComponent<
  QuestionTemplateProps,
  QuestionTemplateState
> {
  constructor(props: QuestionTemplateProps) {
    super(props);
    this.state = {
      width: 0
    };
  }

  onPageLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    this.setState({ width });
  };

  render(): JSX.Element {
    const {
      examParams,
      questionNumber,
      question,
      questionPoints,
      examEntitled,
      examId,
      currentExamPart,
      isBookMarked,
      forceStopTimers,
      duration,
      modifyBookMarked,
      onFinishedTimer
    } = this.props;
    const { width } = this.state;

    return (
      <View style={styles.container} onLayout={this.onPageLayout}>
        <ExamHeaderQuestion
          key={guidGenerator()}
          examId={examId}
          questionNumber={questionNumber}
          questionPoints={questionPoints}
          currentExamPart={currentExamPart}
          question={question}
          isBookMarked={isBookMarked}
          forceStopTimers={forceStopTimers}
          modifyBookMarked={modifyBookMarked}
          onFinishedTimer={(timerType: TimerTypeEnum) =>
            onFinishedTimer(timerType)
          }
          duration={duration}
        />
        {examEntitled && width ? (
          <HtmlRenderer
            content={examEntitled}
            isLinkUsable={examParams?.openBook || false}
            width={width}
          />
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: PADDING_SIDES * 0.3,
    width: "100%"
  }
});

export default QuestionTemplate;
