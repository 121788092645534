import { IS_WEB_ENABLE } from "../constants";
import {
  demoEars,
  demoFace,
  demoId,
  demoRoom,
  demoTable
} from "./onboardingvideos";
import { micro, networkCheck, outlineVideo, userIcon } from "../images";
import { ExamOnboarding } from "../../../src/modules/exams/types/exam";

export const ONBOARDING_DEFAULT: ExamOnboarding = {
  requires: ["INTERNET_CONNECTION"],
  steps: [
    {
      auto: false,
      order: 2,
      type: "UNLOCK_EXAM"
    }
  ]
};

export interface OnboardingAsset {
  demoVideo: string;
  video: {
    fileName: string;
    mp4: any;
    webm?: any;
  };
  proctorElementId: number;
}

const defaultAssets: OnboardingAsset[] = [
  {
    demoVideo: "FacePic",
    video: {
      fileName: "demo_face_pic",
      mp4: demoFace
    },
    proctorElementId: 1
  },
  {
    demoVideo: "IDPic",
    video: {
      fileName: "demo_id_pic",
      mp4: demoId
    },
    proctorElementId: 2
  },
  {
    demoVideo: "TableTopUnder",
    video: {
      fileName: "demo_table_top_under",
      mp4: demoTable
    },
    proctorElementId: 3
  },
  {
    demoVideo: "Room4Corners",
    video: {
      fileName: "demo_room_4_corners",
      mp4: demoRoom
    },
    proctorElementId: 4
  },
  {
    demoVideo: "Ears",
    video: {
      fileName: "demo_ears",
      mp4: demoEars
    },
    proctorElementId: 5
  }
];

// Dynamically import webm files for seb browser
// This is needed because the webm files are not included in the desktop build by default
// and must be imported dynamically here if needed
function dynamicallyImportWebmFiles(
  assets: OnboardingAsset[],
  skip = true
): OnboardingAsset[] {
  if (skip) return assets;

  return assets.reduce<OnboardingAsset[]>((accumulator, asset) => {
    import(`../../assets/onboarding360/${asset.video.fileName}.webm`).then(
      (module) => {
        accumulator.push({
          ...asset,
          video: {
            ...asset.video,
            webm: module.default
          }
        });
      }
    );

    return accumulator;
  }, []);
}

const shouldSkipWebmFiles =
  (window.navigator.userAgent.includes("Windows") &&
    window.navigator.userAgent.includes("SEB")) === false;

export const ONBOARDING_ASSETS: OnboardingAsset[] = dynamicallyImportWebmFiles(
  defaultAssets,
  shouldSkipWebmFiles
);

export const ONBOARDING_WELCOME = "WELCOME";
export const ONBOARDING_TEACHER_INSTRUCTIONS = "TEACHER_INSTRUCTIONS";
export const ONBOARDING_GENERAL_CONDITIONS = "GENERAL_CONDITIONS";
export const ONBOARDING_PERMISSIONS = "PERMISSIONS";
export const ONBOARDING_FACE_PHOTO = "FACE_PHOTO";
export const ONBOARDING_ID_PHOTO = "ID_PHOTO";
export const ONBOARDING_CUSTOM = "CUSTOM";
export const ONBOARDING_MICROPHONE_PERMISSION = "MICROPHONE_PERMISSION";
export const ONBOARDING_MICROPHONE = "MICROPHONE";
export const ONBOARDING_CAMERA_PERMISSION = "CAMERA_PERMISSION";
export const ONBOARDING_VIDEO_360 = "VIDEO_360";
export const ONBOARDING_UNLOCK_EXAM = "UNLOCK_EXAM";
export const ONBOARDING_SOUND = "SOUND";
export const ONBOARDING_CONNECTION = "CONNECTION";
export const ONBOARDING_PROCTORING_INSTRUCTIONS = "PROCTORING_INSTRUCTIONS";

export interface OnboardingStepperOption {
  label: string;
  elements: string[];
}

export const ONBOARDING_STEPPER_OPTIONS: OnboardingStepperOption[] = [
  {
    label: "homepage.onboardingSteps.generalConditions",
    elements: [ONBOARDING_GENERAL_CONDITIONS, ONBOARDING_PERMISSIONS]
  },
  {
    label: "homepage.onboardingSteps.equipmentCheck",
    elements: [
      ONBOARDING_MICROPHONE,
      ONBOARDING_SOUND,
      ONBOARDING_CONNECTION,
      ONBOARDING_CAMERA_PERMISSION,
      ONBOARDING_MICROPHONE_PERMISSION
    ]
  },
  {
    label: "homepage.onboardingSteps.identification",
    elements: [ONBOARDING_FACE_PHOTO, ONBOARDING_ID_PHOTO]
  },
  {
    label: "homepage.onboardingSteps.environment",
    elements: [ONBOARDING_VIDEO_360]
  },
  {
    label: "homepage.onboardingSteps.examLaunch",
    elements: [
      ONBOARDING_UNLOCK_EXAM,
      ONBOARDING_CUSTOM
      // ONBOARDING_PROCTORING_INSTRUCTIONS
    ]
  }
];

export interface OnboardingSubStepperOption {
  type: string;
  showRules: boolean;
  icon?: any;
  subtitle?: string;
  videoIndex: number;
}

export const ONBOARDING_ORDERED_STEPS: OnboardingSubStepperOption[] = [
  {
    type: ONBOARDING_WELCOME,
    showRules: true,
    videoIndex: -1
  },
  {
    type: ONBOARDING_TEACHER_INSTRUCTIONS,
    showRules: true,
    videoIndex: -1
  },
  {
    type: ONBOARDING_GENERAL_CONDITIONS,
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_PERMISSIONS,
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_MICROPHONE_PERMISSION,
    subtitle: "homepage.onboardingSteps.microphoneTestSubtitle",
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_MICROPHONE,
    icon: micro,
    subtitle: IS_WEB_ENABLE
      ? "homepage.onboardingSteps.soundTestSubtitle"
      : "homepage.onboardingSteps.soundTestSubtitleDesktop",
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_CAMERA_PERMISSION,
    subtitle: "homepage.onboardingSteps.cameraTestSubtitle",
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_CONNECTION,
    icon: networkCheck,
    subtitle: "homepage.onboardingSteps.connectionTestSubtitle",
    showRules: false,
    videoIndex: -1
  },
  {
    type: ONBOARDING_FACE_PHOTO,
    icon: userIcon,
    subtitle: "homepage.onboardingSteps.faceIdentificationSubtitle",
    videoIndex: 0,
    showRules: false
  },
  {
    type: ONBOARDING_ID_PHOTO,
    icon: userIcon,
    subtitle: "homepage.onboardingSteps.idIdentificationSubtitle",
    videoIndex: 1,
    showRules: false
  },
  {
    type: ONBOARDING_VIDEO_360,
    icon: outlineVideo,
    subtitle: "homepage.onboardingSteps.earsEnvironmentSubtitle",
    videoIndex: 2,
    showRules: false
  },
  {
    type: ONBOARDING_VIDEO_360,
    icon: outlineVideo,
    subtitle: "homepage.onboardingSteps.deskEnvironmentSubtitle",
    videoIndex: 3,
    showRules: false
  },
  {
    type: ONBOARDING_VIDEO_360,
    icon: outlineVideo,
    subtitle: "homepage.onboardingSteps.roomEnvironmentSubtitle",
    videoIndex: 4,
    showRules: false
  },
  {
    type: ONBOARDING_PROCTORING_INSTRUCTIONS,
    showRules: true,
    videoIndex: -1
  },
  {
    type: ONBOARDING_UNLOCK_EXAM,
    showRules: true,
    videoIndex: -1
  },
  {
    type: ONBOARDING_CUSTOM,
    showRules: true,
    videoIndex: -1
  }
];
