import { ExamType } from "../../exams/types/exam";

export const UPDATE_TIMER = "UPDATE_TIMER";
export const CREATE_TIMER = "CREATE_TIMER";
export const CLEAR_TIMER = "CLEAR_TIMER";

export interface TimerState {
  timers: TimerType[];
}

export interface TimerType {
  id: string;
  timerType: TimerTypeEnum;
  remainingDuration: number;
  timers: TimerType[] | undefined;
}

export enum TimerTypeEnum {
  EXERCISE,
  QUESTION,
  PART,
  EXAM,
  MODAL
}
export interface UpdateTimerAction {
  type: typeof UPDATE_TIMER;
  payload: any;
}
export interface ClearTimerAction {
  type: typeof CLEAR_TIMER;
  payload: any;
}
export interface CreateTimerAction {
  type: typeof CREATE_TIMER;
  payload: {
    currentExam: ExamType;
  };
}

export type TimerActionsType =
  | UpdateTimerAction
  | CreateTimerAction
  | ClearTimerAction;
