import _ from "lodash";
import {
  PARTINDEX_TYPE_EXERCISE,
  PARTINDEX_TYPE_QUESTION
} from "../../../../static/misc/constants";
import { shuffleQuestion } from "../../../../static/misc/utils";
import { ExamIndex, ExamPart, ExamType } from "../../exams/types/exam";
import {
  CLEAR_EXAM_TAKING,
  CREATE_EXAM_TAKING,
  ExamTakingActionsType,
  ExamTakingState,
  MODIFY_BOOKMARKED,
  START_EXAM,
  END_EXAM,
  ADD_PDF_HIGHLIGHT,
  REMOVE_PDF_HIGHLIGHT,
  MODIFY_PDF_HIGHLIGHT,
  UPDATE_SPREADSHEET,
  UPDATE_EXAM_TAKING
} from "../types/examTaking";

const initialState: ExamTakingState = {
  exams: undefined,
  isTakingExam: false
};

export default function reducer(
  state = initialState,
  action: ExamTakingActionsType
): ExamTakingState {
  switch (action.type) {
    case START_EXAM: {
      return {
        ...state,
        isTakingExam: true
      };
    }

    case END_EXAM: {
      return {
        ...state,
        isTakingExam: false
      };
    }

    case CLEAR_EXAM_TAKING: {
      if (action.payload && action.payload.length > 0 && state.exams) {
        const { exams } = state;
        const examId = action.payload;
        const currentExamIndex = state.exams.findIndex((e) => e.id === examId);
        const examList = [...exams] as ExamType[];
        if (currentExamIndex > -1) {
          examList.splice(currentExamIndex, 1);
        }
        return {
          ...state,
          exams: examList
        };
      }
      return initialState;
    }

    case MODIFY_BOOKMARKED: {
      // Updating the exam data to bookmark questions
      const { exams } = state;
      if (exams) {
        const { examId, currentQuestion } = action.payload;
        const examList = [...exams] as ExamType[];
        const myExamIndex = examList.findIndex((e) => e.id === examId);
        const examParts = [...(examList[myExamIndex].examParts as ExamPart[])];

        if (examParts && typeof examParts !== "string") {
          const tmp = examParts.map((examPart) => {
            const part = { ...examPart };
            part.partIndexes = part.partIndexes.map((partIndex: ExamIndex) => {
              const pi = { ...partIndex };

              if (
                pi.type === PARTINDEX_TYPE_QUESTION &&
                pi.question &&
                pi.question.id === currentQuestion
              ) {
                const qn = { ...pi.question };

                qn.isBookMarked = !qn.isBookMarked;

                pi.question = qn;
              } else if (pi.type === PARTINDEX_TYPE_EXERCISE && pi.exercise) {
                const ex = { ...pi.exercise };
                const q = [...ex.questions];
                ex.questions = q.map((question) => {
                  const qn = { ...question };

                  if (question.id === currentQuestion) {
                    qn.isBookMarked = !qn.isBookMarked;
                  }
                  return { ...qn };
                });

                pi.exercise = ex;
              }
              return { ...pi };
            });
            return { ...part };
          });
          let examPartsTmp = [...examParts];
          examPartsTmp = [...tmp];
          examList[myExamIndex].examParts = examPartsTmp;
          return {
            ...state,
            exams: examList
          };
        }
        return { ...state };
      }

      return {
        ...state
      };
    }

    case CREATE_EXAM_TAKING: {
      const { exams, isTakingExam } = state;

      // if new exam already exists in exams list, then returning immediately
      if (
        exams &&
        exams.length > 0 &&
        exams.find((e) => e.id === action.payload.id)
      ) {
        return {
          ...state
        };
      }
      // Creating the exam taking data in the store
      const examTaking = { ...action.payload };
      const examParts = [...(examTaking.examParts as ExamPart[])];
      let examList;
      if (exams) examList = [...exams] as ExamType[];

      // We calculate the remaining duration for the timers

      examTaking.remainingDuration = examTaking.duration;

      const examPartsTmp = examParts.map((examPart) => {
        const part = { ...examPart };

        part.remainingDuration = part.duration;

        part.partIndexes = part.partIndexes.map((partIndex: ExamIndex) => {
          const pi = { ...partIndex };

          if (pi.type === PARTINDEX_TYPE_QUESTION && pi.question) {
            let qn;
            // We randomize questions which needs to be shuffled
            if (pi.question.randomChoices)
              qn = shuffleQuestion({ ...pi.question });
            else qn = { ...pi.question };

            qn.remainingDuration = qn.duration;

            pi.question = qn;
          } else if (pi.type === PARTINDEX_TYPE_EXERCISE && pi.exercise) {
            const ex = { ...pi.exercise };
            const q = [...ex.questions];

            ex.remainingDuration = ex.duration;

            ex.questions = q.map((question) => {
              let qn;
              // We randomize questions which needs to be shuffled
              if (question.randomChoices) {
                qn = shuffleQuestion({ ...question });
              } else qn = { ...question };

              qn.remainingDuration = qn.duration;

              return { ...qn };
            });

            pi.exercise = ex;
          }
          return { ...pi };
        });
        return { ...part };
      });

      examTaking.examParts = examPartsTmp;
      if (examList === undefined || _.isEmpty(examList))
        examList = [examTaking];
      else examList.push(examTaking);

      return {
        exams: examList,
        isTakingExam
      };
    }

    case UPDATE_EXAM_TAKING: {
      const filteredList = [...(state.exams || [])].filter(
        (exam) => exam.id !== action.payload.id
      );

      return {
        ...state,
        exams: [...filteredList, action.payload]
      };
    }

    case ADD_PDF_HIGHLIGHT: {
      const { exams } = state;
      const { examId, highlight, attachedFileId } = action.payload;

      let examList;
      if (exams) examList = [...exams] as ExamType[];

      if (examList) {
        const myExam = examList.find((e) => e.examId === examId);
        if (myExam) {
          if (!myExam.highlights) myExam.highlights = [];
          const { highlights } = myExam;
          let fileHighlights = highlights.find(
            (h) => h.fileId === attachedFileId
          );
          if (!fileHighlights) {
            fileHighlights = {
              fileId: attachedFileId,
              highlights: []
            };
            highlights.push(fileHighlights);
          }
          fileHighlights.highlights?.push(highlight);
        }
      }
      return {
        ...state,
        exams: examList
      };
    }

    case REMOVE_PDF_HIGHLIGHT: {
      const { exams } = state;
      const { examId, index, attachedFileId } = action.payload;
      let examList;
      if (exams) examList = [...exams] as ExamType[];

      if (examList) {
        const myExam = examList.find((e) => e.examId === examId);
        if (myExam) {
          if (!myExam.highlights) myExam.highlights = [];
          const { highlights } = myExam;
          const fileHighlights = highlights.find(
            (h) => h.fileId === attachedFileId
          );
          fileHighlights?.highlights?.splice(index, 1);
        }
      }
      return {
        ...state,
        exams: examList
      };
    }

    case MODIFY_PDF_HIGHLIGHT: {
      const { exams } = state;
      const { examId, highlight, attachedFileId } = action.payload;
      let examList;
      if (exams) examList = [...exams] as ExamType[];

      if (examList) {
        const myExam = examList.find((e) => e.examId === examId);
        if (myExam) {
          if (!myExam.highlights) myExam.highlights = [];
          const { highlights } = myExam;
          const fileHighlights = highlights.find(
            (h) => h.fileId === attachedFileId
          );
          let myHightlight = fileHighlights?.highlights?.find(
            (h) => h.id === highlight.id
          );
          if (myHightlight) {
            myHightlight = { ...highlight };
          }
        }
      }
      return {
        ...state,
        exams: examList
      };
    }

    case UPDATE_SPREADSHEET: {
      const { exams } = state;
      const { examId, fileId, content } = action.payload;

      let examList;
      if (exams) examList = [...exams] as ExamType[];

      if (examList) {
        const myExam = examList.find((e) => e.id === examId);
        if (myExam) {
          if (!myExam.workBooks) {
            myExam.workBooks = [];
          }
          const wb = myExam.workBooks.find((w) => w.id === fileId);
          if (wb) {
            wb.content = content;
          } else {
            myExam.workBooks.push({
              id: fileId,
              content
            });
          }
        }
      }

      return { ...state, exams: examList };
    }

    default:
      return state;
  }
}
