/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Animated,
  PanResponder,
  PanResponderInstance,
  GestureResponderEvent,
  LayoutChangeEvent
} from "react-native";
import _, { isArray } from "lodash";
import path from "path";
import { ipcRenderer } from "electron";
import {
  AUTOSAVE_TIMER,
  EXAM_FILES,
  FONT_GILROY_ITALIC,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_SPREADSHEET,
  PADDING_SIDES,
  PARTINDEX_TYPE_EXERCISE,
  PARTINDEX_TYPE_QUESTION
} from "../../../static/misc/constants";
import {
  getFileExtension,
  getQuestionFileMimeType
} from "../../../static/misc/utils";
import {
  ExamIndex,
  ExamPart,
  ExamType,
  ExamWorkBook
} from "../../modules/exams/types/exam";
import {
  FillStudentPaperAction,
  StudentAnswerType,
  StudentPaperType
} from "../../modules/examTaking/types/studentPaper";
import { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import ButtonExamTaking from "./ButtonExamTaking";
import PageExamContent from "./PageExamContent";
import { ModifyBookMarkedAction } from "../../modules/examTaking/types/examTaking";
import MediaMenu from "./MediaMenu";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import CalculatorView from "./CalculatorView";
import { CalculatorTypeEnum } from "../../modules/examTaking/types/calculator";
import { COLOR_BLUE_HR, COLOR_RED } from "../../../static/misc/colors";
import { LocalApiErrorAction } from "../../modules/main/types/error";
import {
  createFileIfNotExist,
  writeFileOnFsAsync
} from "../../../static/fileutils";
import ButtonLabel from "../atoms/ButtonLabel";
import i18n from "../../services/i18n";
import ExamNavbar from "./ExamNavbar";
import {
  completeStudentAnswerStatus,
  getExamPart
} from "../../services/exam-navbar-progress";

interface ExamTakingContentProps {
  examId: string;
  currentPdfPage: number;
  currentExam?: ExamType;
  examParts: ExamPart[];
  duration?: number;
  currentAttachedFiles: MediaType[] | undefined;
  currentStudentPaper: StudentPaperType | undefined;
  forceStopTimers: boolean;
  shouldStopRecording: boolean;
  stopSavingStudentAnswers: boolean;
  onEndExam: boolean;
  displayMedias: boolean;
  displayCalc: boolean;
  calcType?: CalculatorTypeEnum;
  token: string;
  appDataPath: string;
  isMediaFullscreen: boolean;
  workBooks?: ExamWorkBook[];
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
  fillStudentPaper: (
    examId: string,
    studentAnswers: StudentAnswerType[],
    currentUserId: string
  ) => FillStudentPaperAction;
  uploadMediaObjects: (
    studentAnswers: StudentAnswerType[],
    isLastQuestion: boolean
  ) => void;
  editAttachedFileUri(mediaId: string, uri: string): void;
  toggleAudioProctoring: () => void;
  onChangeQuestionOrPart: (
    currentExamPart: number,
    currentPartIndex: number
  ) => void;
  onFinishExam: () => void;
  onFinishTimer: (
    callback: (changeType: ChangeEnum) => void,
    timerType: TimerTypeEnum
  ) => void;
  onRemainingDurationWarning: (
    callback: (changeType: ChangeEnum) => void,
    changeType: ChangeEnum
  ) => void;
  navigateToHomepage: () => void;
  localApiError: (err: any) => LocalApiErrorAction;
  toggleMediaFullscreen: (mediaId: string, type: string) => void;
  onPdfPageChange: (newPage: number) => void;
  currentUserId: string;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
}

interface ExamTakingContentState {
  currentExamPart: number;
  currentPartIndex: number;
  studentAnswers: StudentAnswerType[];
  contentWidth: number;
  mediaPanelWidth: number;
  questionPanelWidth: number;
  isResizing: boolean;
  studentAnswerThatNeedToBeSaved?: StudentAnswerType;
}

export enum ChangeEnum {
  NEXTPART,
  PREVPART,
  NEXTQUESTION,
  PREVQUESTION,
  FINISHEXAM,
  NONE
}

class ExamTakingContent extends React.Component<
  ExamTakingContentProps,
  ExamTakingContentState
> {
  intervalTimer!: ReturnType<typeof setInterval>;

  pan = new Animated.ValueXY();

  panResponder: PanResponderInstance;

  private myRef = React.createRef<ScrollView>();

  constructor(props: ExamTakingContentProps) {
    super(props);
    this.state = {
      currentExamPart: 0,
      currentPartIndex: 0,
      studentAnswers: [],
      contentWidth: 0,
      mediaPanelWidth: 0,
      questionPanelWidth: 0,
      isResizing: false
    };
    this.onForceNavigationCallback = this.onForceNavigationCallback.bind(this);
    this.onSaveFileOnFS = this.onSaveFileOnFS.bind(this);
    this.onPanResponderGrant = this.onPanResponderGrant.bind(this);
    this.onPanResponderMove = this.onPanResponderMove.bind(this);
    this.onPanResponderRelease = this.onPanResponderRelease.bind(this);
    this.onPanResponderTerminate = this.onPanResponderTerminate.bind(this);
    this.onPanResponderTerminationRequest = this.onPanResponderTerminationRequest.bind(
      this
    );
    this.onChangeDisplayMedias = this.onChangeDisplayMedias.bind(this);
    this.onChangeQuestionUsingProgressBar = this.onChangeQuestionUsingProgressBar.bind(
      this
    );

    // Creating the panresponder to allow dynamic resizing of the media panel
    this.panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderTerminationRequest: this.onPanResponderTerminationRequest,
      onPanResponderGrant: this.onPanResponderGrant,
      onPanResponderMove: this.onPanResponderMove,
      onPanResponderRelease: this.onPanResponderRelease,
      onPanResponderTerminate: this.onPanResponderTerminate
    });
  }

  componentDidMount(): void {
    // If not previsualizing, retrieving the student answers already saved in the student paper
    // and triggering the auto save of the student answers
    if (!IS_PREVIEW) {
      this.mapStudentAnswersIfExists();
      this.autoSaveStudentAnswers(AUTOSAVE_TIMER);
    }
  }

  componentDidUpdate(
    prevProps: ExamTakingContentProps,
    prevState: ExamTakingContentState
  ): void {
    const {
      currentPartIndex,
      currentExamPart,
      studentAnswers,
      studentAnswerThatNeedToBeSaved
    } = this.state;
    const { currentStudentPaper, displayMedias } = this.props;

    // If the current exam part or current part index changed, remap the student paper to fill the existing answers in
    if (
      currentPartIndex !== prevState.currentPartIndex ||
      currentExamPart !== prevState.currentExamPart ||
      (!_.isEqual(currentStudentPaper, prevProps.currentStudentPaper) &&
        !_.isEqual(studentAnswers, currentStudentPaper?.studentAnswers))
    ) {
      this.mapStudentAnswersIfExists();
    }
    // Toggle the media panel
    if (prevProps.displayMedias !== displayMedias) {
      this.onChangeDisplayMedias();
    }

    if (studentAnswerThatNeedToBeSaved) {
      const currentAnswerTmp = studentAnswers.find(
        (sa) => sa.question === studentAnswerThatNeedToBeSaved?.question
      );

      if (currentAnswerTmp)
        currentAnswerTmp.status = studentAnswerThatNeedToBeSaved?.status;

      this.pushStudentAnswer(
        currentAnswerTmp ?? studentAnswerThatNeedToBeSaved
      );

      this.setState({ studentAnswerThatNeedToBeSaved: undefined });
    }
  }

  componentWillUnmount(): void {
    // clearing the autosave interval
    clearInterval(this.intervalTimer);
  }

  // For media panel resize
  onPanResponderTerminationRequest(): boolean {
    return false;
  }

  // For media panel resize
  onPanResponderGrant(): void {
    this.setState({
      isResizing: true
    });
  }

  // Calculating the new width for the media panel when resizing
  onPanResponderMove(e: GestureResponderEvent): void {
    const { contentWidth } = this.state;
    // We don't want the media panel to be resized too small or too wide
    const maxMediaPanelWidth = (contentWidth * 75) / 100;
    const minMediaPanelWidth = (contentWidth * 25) / 100;
    let offset = e.nativeEvent.pageX;

    if (offset > maxMediaPanelWidth) {
      offset = maxMediaPanelWidth;
    } else if (offset < minMediaPanelWidth) {
      offset = minMediaPanelWidth;
    }

    this.setState({
      mediaPanelWidth: offset,
      questionPanelWidth: contentWidth - offset - 30
    });
  }

  // For media panel resize
  onPanResponderRelease(): void {
    this.setState({
      isResizing: false
    });
  }

  // For media panel resize
  onPanResponderTerminate(): void {
    this.setState({
      isResizing: false
    });
  }

  // Resetting the media panel width when hiding it
  onChangeDisplayMedias(): void {
    this.pan.setOffset({ x: 0, y: 0 });
    this.setState({
      contentWidth: 0,
      mediaPanelWidth: 0,
      questionPanelWidth: 0
    });
  }

  // Triggered when changing part/question (using the progress bar) to update indexes everywhere
  onChangeQuestionUsingProgressBar(
    newExamPart: number,
    newPartIndex: number
  ): void {
    const { onChangeQuestionOrPart, examParts } = this.props;

    // Ensuring the student answers are saved
    this.writeStudentAnswers(false);

    // Changing the index of part/question to navigate
    onChangeQuestionOrPart(newExamPart, newPartIndex);
    this.logExamMove(
      newExamPart,
      newPartIndex,
      examParts[newExamPart].partIndexes[newPartIndex].id
    );
    this.setState({
      currentExamPart: newExamPart,
      currentPartIndex: newPartIndex
    });
  }

  // Triggered when changing part/question (using next or prev buttons) to update indexes everywhere
  onChangeQuestion(changeType: ChangeEnum): void {
    const { currentExamPart, currentPartIndex } = this.state;
    const { examParts, onChangeQuestionOrPart } = this.props;
    switch (changeType) {
      case ChangeEnum.NEXTPART: {
        // If the exam part has chrono and is already completed or visited
        // We shouldn't allow the user to move to this part so instead we switch to the next one
        const nextExamPartIndex = examParts.reduce((prev, current) => {
          const hasSeenExamPart =
            current.status === "completed" ||
            current.status === "initial" ||
            current.status === "partially-completed" ||
            current.status === "bookmarked";

          return this.hasChronoInExamPart(current) && hasSeenExamPart
            ? prev + 1
            : prev;
        }, currentExamPart + 1);

        const nextExamIndex = examParts[nextExamPartIndex].partIndexes[0];

        onChangeQuestionOrPart(nextExamPartIndex, 0);

        this.logExamMove(nextExamPartIndex, 0, nextExamIndex.id);

        this.setState({
          currentExamPart: nextExamPartIndex,
          currentPartIndex: 0
        });
        break;
      }
      case ChangeEnum.PREVPART: {
        onChangeQuestionOrPart(
          currentExamPart - 1,
          examParts[currentExamPart - 1].partIndexes.length - 1
        );
        this.logExamMove(
          currentExamPart - 1,
          examParts[currentExamPart - 1].partIndexes.length - 1,
          examParts[currentExamPart - 1].partIndexes[
            examParts[currentExamPart - 1].partIndexes.length - 1
          ].id
        );
        this.setState({
          currentExamPart: currentExamPart - 1,
          currentPartIndex:
            examParts[currentExamPart - 1].partIndexes.length - 1
        });
        break;
      }
      case ChangeEnum.NEXTQUESTION: {
        const nextQuestionIndex = currentPartIndex + 1;

        onChangeQuestionOrPart(currentExamPart, nextQuestionIndex);
        this.logExamMove(
          currentExamPart,
          nextQuestionIndex,
          examParts[currentExamPart].partIndexes[nextQuestionIndex].id
        );
        this.setState({
          currentPartIndex: nextQuestionIndex
        });
        break;
      }
      case ChangeEnum.PREVQUESTION: {
        onChangeQuestionOrPart(currentExamPart, currentPartIndex - 1);
        this.logExamMove(
          currentExamPart,
          currentPartIndex - 1,
          examParts[currentExamPart].partIndexes[currentPartIndex - 1].id
        );
        this.setState({
          currentPartIndex: currentPartIndex - 1
        });
        break;
      }
      default:
        break;
    }

    this.myRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  }

  // Triggered when the student clicks on the "OK" button when the forced navigation modal is displayed
  onForceNavigationCallback(changeType: ChangeEnum): void {
    const { onFinishExam } = this.props;

    if (changeType === ChangeEnum.FINISHEXAM) {
      onFinishExam();
    } else if (changeType !== ChangeEnum.NONE) {
      this.onChangeQuestion(changeType);
    }
  }

  // Triggered when a timer is over
  onFinishedTimer(timerType: TimerTypeEnum): void {
    const { onFinishTimer } = this.props;

    // Saves the student answers
    this.writeStudentAnswers(false);
    // Display the forced navigation modal with different callback depending on the kind of timer which ended
    // (if the timer was the exam timer, then the exam is over)
    onFinishTimer(this.onForceNavigationCallback, timerType);
  }

  // Triggered when downloading an audio/spreadsheet answer to the filesystem
  onSaveFileOnFS(
    questionId: string,
    questionType: string,
    fileContent?: string,
    mediaId?: string,
    saveSpreadsheetCb?: (filepath: string) => void
  ): void {
    const {
      examId,
      appDataPath,
      editAttachedFileUri,
      currentExam
    } = this.props;

    // We need the mimetype and the file extension to create the file locally
    const mimeType = getQuestionFileMimeType(questionType);
    const fileExtension = getFileExtension(mimeType);
    let filename = "";

    // As long as the file is not uploaded on the API, its name starts with "internal-" in the student paper
    // if file is spreadsheet, then adding the mediaId to its name so we can reuse the existing media if app
    // crashes and the student needs to come back in the exam
    if (_.includes(MIME_SPREADSHEET, mimeType) && mediaId !== undefined) {
      filename = `internal-${examId}-${questionId}-${mediaId}${fileExtension}`;
    } else {
      filename = `internal-${examId}-${questionId}${fileExtension}`;
    }

    // if filecontent not undefined and mimetype not spreadsheet, then it is not a spreadsheet file
    if (fileContent && !_.includes(MIME_SPREADSHEET, mimeType)) {
      if (!IS_WEB_ENABLE) {
        // Downloading the file locally
        writeFileOnFsAsync(fileContent, filename, appDataPath, EXAM_FILES);
      }
    } else if (saveSpreadsheetCb && mediaId) {
      const filepath = path.join(appDataPath, EXAM_FILES, filename);
      createFileIfNotExist(filepath);
      try {
        saveSpreadsheetCb(filepath);
        editAttachedFileUri(mediaId, filepath);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(
          "Could not update spreadsheet uri because spreadsheet save failed"
        );
      }
    }

    const answer = completeStudentAnswerStatus(
      {
        question: questionId,
        questionType,
        mediaObjects: [filename]
      } as StudentAnswerType,
      currentExam
    );

    if (IS_WEB_ENABLE && fileContent) {
      answer.mediaData = [
        {
          data: fileContent,
          filename
        }
      ];
    }

    // pushing answer at the end only
    this.pushStudentAnswer(answer);
  }

  hasChronoInExamPart = (examPart: ExamPart): boolean => {
    return (
      examPart.durationLimit === true && (examPart.remainingDuration || 0) > 0
    );
  };

  // Triggered when saving the student answers
  writeStudentAnswers = (isLastQuestion: boolean): void => {
    const {
      fillStudentPaper,
      uploadMediaObjects,
      examId,
      currentUserId
    } = this.props;
    const { studentAnswers } = this.state;
    if (IS_PREVIEW) {
      return;
    }

    // Updating the student paper in the store
    fillStudentPaper(examId, [...studentAnswers], currentUserId);

    // we don't want to upload the excel files before the very end of the exam
    const answersWithMedia = studentAnswers.filter(
      (answer) =>
        answer.mediaObjects &&
        isArray(answer.mediaObjects) &&
        answer.mediaObjects.length > 0 &&
        answer.mediaObjects.filter(
          (media) =>
            media.indexOf("internal-") > -1 && media.indexOf(".xlsx") === 0
        ).length > 0
    );

    if (answersWithMedia && answersWithMedia.length > 0) {
      // If there is an associated media to the answer and no async action running, trying to upload it
      uploadMediaObjects(answersWithMedia, isLastQuestion);
    }
  };

  // Triggered when pushing new answer to the state
  pushStudentAnswer = (answer: StudentAnswerType): void => {
    const { studentAnswers, currentExamPart } = this.state;
    const { currentExam, currentStudentPaper } = this.props;

    const studentPaperAnswers =
      currentStudentPaper?.studentAnswers &&
      typeof currentStudentPaper.studentAnswers !== "string"
        ? currentStudentPaper.studentAnswers
        : [];

    const studentAnswersTmp = _.uniqBy(
      [...studentAnswers, ...studentPaperAnswers],
      "question"
    );

    const index = studentAnswersTmp.findIndex(
      (a) => a.question === answer.question
    );

    if (index >= 0) studentAnswersTmp[index] = answer;
    else studentAnswersTmp.push(answer);

    // This part is to ensure that if at least one question exercise has been visited,
    // all the questions exercise are marked as visited aswell
    if (currentExam) {
      const examPart = getExamPart(currentExamPart, currentExam);

      if (examPart) {
        examPart.partIndexes.forEach((pi) => {
          if (pi.type === "exercise" && pi.exercise) {
            pi.exercise.questions.forEach((q) => {
              if (
                q.id &&
                studentAnswersTmp.map((sa) => sa.question).includes(q.id)
              ) {
                const exerciseAnswers = pi.exercise?.questions.map(
                  (eq) =>
                    // Question is already answered, nothing change
                    studentAnswersTmp.find((sa) => sa.question === eq.id) ??
                    // Question is not answered yet
                    ({
                      question: eq.id,
                      questionType: eq.type,
                      givenChoices: [],
                      givenAnswer: undefined,
                      mediaObjects: [],
                      mediaData: [],
                      noChoice: undefined,
                      status: "visited"
                    } as StudentAnswerType)
                );

                if (exerciseAnswers?.length)
                  studentAnswersTmp.push(...exerciseAnswers);
              }
            });
          }
        });
      }
    }

    this.setState({
      studentAnswers: studentAnswersTmp.map((sa) =>
        completeStudentAnswerStatus(sa, currentExam)
      ),
      studentAnswerThatNeedToBeSaved: completeStudentAnswerStatus(
        answer,
        currentExam
      )
    });
  };

  handlePartOrQuestionChange = (
    currentExamPart: number,
    currentPartIndex = 0
  ): void => {
    const { examParts, onChangeQuestionOrPart } = this.props;

    const examIndex = examParts[currentExamPart].partIndexes[currentPartIndex];

    // User cannot click because question or exercise has chrono
    if (
      this.hasChronoInQuestion(examIndex) ||
      this.hasChronoInExercise(examIndex)
    ) {
      return;
    }

    // Ensuring the student answers are saved
    this.writeStudentAnswers(false);

    this.setState({ currentExamPart, currentPartIndex });

    // FIXME: WHY is this needed ?
    // Two states are set (currentExamPart and currentPartIndex) in ExamTakingContent.tsx and ExamTakingBody.tsx
    onChangeQuestionOrPart(currentExamPart, currentPartIndex);

    this.logExamMove(currentExamPart, currentPartIndex, examIndex.id);
  };

  hasChronoInQuestion = (examIndex: ExamIndex): boolean => {
    return (
      examIndex.question?.durationLimit === true &&
      (examIndex.question?.remainingDuration || 0) > 0
    );
  };

  hasChronoInExercise = (examIndex: ExamIndex): boolean => {
    return (
      examIndex.exercise?.durationLimit === true &&
      (examIndex.exercise?.remainingDuration || 0) > 0
    );
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  logExamMove(logIndexPart: any, logIndexQuestion: any, logId: any): void {
    ipcRenderer.send(
      "LOG_INFO",
      `MOVE ACCROSS EXAM | Student went to part ${logIndexPart} - question ${logIndexQuestion}, id: ${logId}`
    );
  }

  // Autosaving the student answers in the store
  autoSaveStudentAnswers(interval: number): void {
    this.intervalTimer = setInterval(() => {
      const {
        currentStudentPaper,
        stopSavingStudentAnswers,
        currentUserId
      } = this.props;
      if (currentStudentPaper?.endDate === null && !stopSavingStudentAnswers) {
        const { fillStudentPaper, examId } = this.props;
        const { studentAnswers } = this.state;

        fillStudentPaper(examId, [...studentAnswers], currentUserId);
      }
    }, interval);
  }

  // Triggered when exam loading or part/question changing to fill the answers in when the student paper is not empty
  mapStudentAnswersIfExists(): void {
    const { currentPartIndex, currentExamPart } = this.state;
    const { currentStudentPaper, examParts } = this.props;

    const studentAnswers: StudentAnswerType[] = [];

    if (
      currentStudentPaper?.studentAnswers &&
      typeof currentStudentPaper?.studentAnswers !== "string" &&
      isArray(currentStudentPaper?.studentAnswers) &&
      currentStudentPaper.studentAnswers.length > 0
    ) {
      const current = examParts[currentExamPart].partIndexes[currentPartIndex];

      // TYPE QUESTION
      if (current.type === PARTINDEX_TYPE_QUESTION) {
        const index = currentStudentPaper.studentAnswers.findIndex(
          (answer) => answer.question === current.question?.id
        );
        if (index >= 0) {
          studentAnswers.push(currentStudentPaper.studentAnswers[index]);
        }
      } else {
        // TYPE EXERCISE
        current.exercise?.questions.map((question) => {
          if (typeof currentStudentPaper.studentAnswers !== "string") {
            const index = currentStudentPaper.studentAnswers.findIndex(
              (answer: StudentAnswerType) => answer.question === question?.id
            );
            if (index >= 0) {
              studentAnswers.push(currentStudentPaper.studentAnswers[index]);
            }
          }
        });
      }

      this.setState({ studentAnswers });
    }
  }

  isTimed(partIndex: ExamIndex): boolean {
    const { examParts } = this.props;
    const { currentExamPart, currentPartIndex } = this.state;

    return (
      (examParts[currentExamPart].partIndexes.length - 1 === currentPartIndex &&
        examParts[currentExamPart].durationLimit === true &&
        examParts[currentExamPart].duration > 0) ||
      (partIndex.type === PARTINDEX_TYPE_QUESTION &&
        partIndex.question?.durationLimit === true &&
        partIndex.question.duration > 0) ||
      (partIndex.type === PARTINDEX_TYPE_EXERCISE &&
        partIndex.exercise?.durationLimit === true &&
        partIndex.exercise.duration > 0)
    );
  }

  render(): JSX.Element {
    const {
      currentPdfPage,
      currentExam,
      examParts,
      currentStudentPaper,
      currentAttachedFiles,
      forceStopTimers,
      shouldStopRecording,
      displayMedias,
      onEndExam,
      displayCalc,
      examId,
      calcType,
      isMediaFullscreen,
      workBooks,
      modifyBookMarked,
      onFinishExam,
      localApiError,
      toggleAudioProctoring,
      onRemainingDurationWarning,
      toggleMediaFullscreen,
      onPdfPageChange,
      toggleRemainingDurationWarningModal
    } = this.props;
    const {
      currentExamPart,
      currentPartIndex,
      studentAnswers,
      contentWidth,
      mediaPanelWidth,
      questionPanelWidth,
      isResizing
    } = this.state;

    const partIndex = examParts[currentExamPart].partIndexes[currentPartIndex];

    const isButtonDisable =
      currentExam &&
      currentExam.examParams &&
      currentExam?.examParams.durationFixed &&
      this.isTimed(partIndex);

    const previousAnswers =
      currentStudentPaper &&
      typeof currentStudentPaper.studentAnswers !== "string"
        ? currentStudentPaper.studentAnswers
        : [];

    const filteredStudentAnswers = _.uniqBy(
      [...studentAnswers, ...previousAnswers],
      (a) => a.question
    );

    return (
      <View style={styles.container}>
        {/* {!currentExam?.examParams?.openBook && (
          <ModalResumingExam currentExam={currentExam} examId={examId} />
        )} */}
        {currentExam && currentStudentPaper && (
          <ExamNavbar
            currentExam={currentExam}
            currentPartIndex={currentPartIndex}
            currentExamPartIndex={currentExamPart}
            studentAnswers={filteredStudentAnswers}
            forceStopTimers={forceStopTimers}
            onFinishTimer={(timerType) => this.onFinishedTimer(timerType)}
            onChangePartIndex={this.handlePartOrQuestionChange}
            onChangeQuestionIndex={(pi) =>
              this.handlePartOrQuestionChange(currentExamPart, pi)
            }
            toggleRemainingDurationWarningModal={
              toggleRemainingDurationWarningModal
            }
          />
        )}
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1
            },
            contentWidth > 0 ? { width: contentWidth } : {}
          ]}
          onLayout={(e: LayoutChangeEvent) => {
            if (!isResizing && contentWidth !== e.nativeEvent.layout.width) {
              this.setState({
                contentWidth: e.nativeEvent.layout.width
              });
            }
          }}
        >
          {displayCalc && calcType !== undefined && currentExam ? (
            <CalculatorView calcType={calcType} />
          ) : (
            <Text />
          )}
          {displayMedias && currentExam && (
            <Animated.View
              style={[
                {
                  minWidth: "10%"
                },
                {
                  width: mediaPanelWidth > 0 ? mediaPanelWidth : "64%"
                }
              ]}
              onLayout={(e: LayoutChangeEvent) => {
                if (mediaPanelWidth !== e.nativeEvent.layout.width) {
                  this.setState({
                    mediaPanelWidth: e.nativeEvent.layout.width
                  });
                }
              }}
            >
              <MediaMenu
                currentPdfPage={currentPdfPage}
                currentExamPart={currentExamPart}
                currentPartIndex={currentPartIndex}
                currentExam={currentExam}
                currentAttachedFiles={currentAttachedFiles}
                isMediaFullscreen={isMediaFullscreen}
                onMediaFullscreen={(mediaId: string, type: string) =>
                  toggleMediaFullscreen(mediaId, type)
                }
                onPdfPageChange={onPdfPageChange}
              />
            </Animated.View>
          )}
          {displayMedias && currentExam && (
            <Animated.View
              style={{
                width: 30,
                flex: 1,
                cursor: "move",
                justifyContent: "center",
                alignItems: "center"
              }}
              {...this.panResponder.panHandlers}
            >
              <View
                style={{
                  width: 1,
                  flexGrow: 1,
                  backgroundColor: COLOR_BLUE_HR
                }}
              />
            </Animated.View>
          )}
          <Animated.View
            style={[
              {
                minWidth: "10%"
              },
              {
                width:
                  questionPanelWidth > 0 && displayMedias
                    ? questionPanelWidth
                    : displayMedias
                    ? "34%"
                    : "100%"
              }
            ]}
            onLayout={(e: LayoutChangeEvent) => {
              if (questionPanelWidth !== e.nativeEvent.layout.width) {
                this.setState({
                  questionPanelWidth: e.nativeEvent.layout.width
                });
              }
            }}
          >
            <ScrollView style={[styles.contentContainer]} ref={this.myRef}>
              <PageExamContent
                examId={examId}
                examParams={currentExam?.examParams}
                onEndExam={onEndExam}
                currentAttachedFiles={currentAttachedFiles}
                modifyBookMarked={modifyBookMarked}
                toggleAudioProctoring={toggleAudioProctoring}
                partIndex={partIndex}
                onPushStudentAnswerWithIndex={(e) =>
                  this.pushStudentAnswer(e as StudentAnswerType)
                }
                previousAnswers={
                  currentStudentPaper
                    ? (currentStudentPaper.studentAnswers as StudentAnswerType[])
                    : undefined
                }
                examParts={examParts}
                studentAnswers={studentAnswers}
                currentExamPart={currentExamPart}
                currentPartIndex={currentPartIndex}
                forceStopTimers={forceStopTimers}
                shouldStopRecording={shouldStopRecording}
                onFinishedTimer={(timerType: TimerTypeEnum) =>
                  this.onFinishedTimer(timerType)
                }
                onSaveFileOnFS={this.onSaveFileOnFS}
                localApiError={localApiError}
                isOpenBook={currentExam?.examParams?.openBook || false}
                workBooks={workBooks}
              />
              <View style={{ paddingTop: PADDING_SIDES / 2 }}>
                {!!isButtonDisable && (
                  <ButtonLabel
                    text={i18n.t("examTaking.durationFixed")}
                    textStyle={{
                      bottom: 80,
                      alignSelf: "flex-end",
                      fontFamily: FONT_GILROY_ITALIC,
                      paddingBottom: 10,
                      color: COLOR_RED
                    }}
                  />
                )}
                <ButtonExamTaking
                  isDisable={!!isButtonDisable}
                  isTimer={this.isTimed(partIndex)}
                  fixedDuration={examParts[currentExamPart].durationLimit}
                  examParts={examParts}
                  currentExamPart={currentExamPart}
                  currentPartIndex={currentPartIndex}
                  returnLastPart={() =>
                    this.onChangeQuestion(ChangeEnum.PREVPART)
                  }
                  returnLastQuestion={() =>
                    this.onChangeQuestion(ChangeEnum.PREVQUESTION)
                  }
                  onRemainingDurationWarning={(
                    callback: (changeType: ChangeEnum) => void,
                    changeType: ChangeEnum
                  ) => onRemainingDurationWarning(callback, changeType)}
                  onWriteStudentAnswers={(isLastQuestion: boolean) =>
                    this.writeStudentAnswers(isLastQuestion)
                  }
                  onNextQuestion={() =>
                    this.onChangeQuestion(ChangeEnum.NEXTQUESTION)
                  }
                  onNextPart={() => this.onChangeQuestion(ChangeEnum.NEXTPART)}
                  onFinishExam={() => onFinishExam()}
                />
              </View>
            </ScrollView>
          </Animated.View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 8
  },
  contentContainer: {
    flex: 1,
    width: "100%",
    paddingHorizontal: PADDING_SIDES * 0.5
  }
});

export default ExamTakingContent;
