import { NewHighlight } from "react-pdf-highlighter";
import { ExamType } from "../../exams/types/exam";
import {
  ClearExamTakingAction,
  CLEAR_EXAM_TAKING,
  CreateExamTakingAction,
  ModifyBookMarkedAction,
  MODIFY_BOOKMARKED,
  CREATE_EXAM_TAKING,
  StartExamAction,
  START_EXAM,
  END_EXAM,
  EndExamAction,
  PdfhighlightAction,
  ADD_PDF_HIGHLIGHT,
  REMOVE_PDF_HIGHLIGHT,
  MODIFY_PDF_HIGHLIGHT,
  UpdateSpreadsheetAction,
  UPDATE_SPREADSHEET,
  UpdateExamTakingAction,
  UPDATE_EXAM_TAKING
} from "../types/examTaking";

// Action called to create the exam taking data in the store
export function createExamTaking(
  currentExam: ExamType
): CreateExamTakingAction {
  return {
    type: CREATE_EXAM_TAKING,
    payload: currentExam
  };
}

// Action called to update the exam taking data in the store
export function updateExamTaking(
  currentExam: ExamType
): UpdateExamTakingAction {
  return {
    type: UPDATE_EXAM_TAKING,
    payload: currentExam
  };
}

// Action called to update the exam taking data with bookmark info when a question is bookmarked
export function modifyBookMarked(
  examId: string,
  currentQuestion: string
): ModifyBookMarkedAction {
  return {
    type: MODIFY_BOOKMARKED,
    payload: {
      currentQuestion,
      examId
    }
  };
}

export function startExamTaking(): StartExamAction {
  return {
    type: START_EXAM,
    payload: null
  };
}

export function endExamTaking(): EndExamAction {
  return {
    type: END_EXAM,
    payload: null
  };
}

// Action called to remove the exam taking data from the store
export function clearExamTaking(
  examId: string | undefined
): ClearExamTakingAction {
  return {
    type: CLEAR_EXAM_TAKING,
    payload: examId
  };
}

// PDF Highlighting
export function addPdfHighlight(
  examId: number,
  attachedFileId: string,
  highlight: NewHighlight
): PdfhighlightAction {
  return {
    type: ADD_PDF_HIGHLIGHT,
    payload: {
      examId,
      attachedFileId,
      highlight
    }
  };
}

export function removePdfHighlight(
  examId: number,
  attachedFileId: string,
  index: number
): PdfhighlightAction {
  return {
    type: REMOVE_PDF_HIGHLIGHT,
    payload: {
      examId,
      attachedFileId,
      index
    }
  };
}

export function modifyPdfHighlight(
  examId: number,
  attachedFileId: string,
  highlight: NewHighlight
): PdfhighlightAction {
  return {
    type: MODIFY_PDF_HIGHLIGHT,
    payload: {
      examId,
      attachedFileId,
      highlight
    }
  };
}

export function updateSpreadsheet(
  examId: string,
  questionId: string,
  fileId: string,
  content: string
): UpdateSpreadsheetAction {
  return {
    type: UPDATE_SPREADSHEET,
    payload: {
      examId,
      questionId,
      fileId,
      content
    }
  };
}
