/* eslint-disable no-console */
import { createStore, applyMiddleware, CombinedState } from "redux";
import thunk from "redux-thunk";
import { multiClientMiddleware } from "redux-axios-middleware";
import { persistStore, persistReducer } from "redux-persist";

import { createLogger } from "redux-logger";
import AsyncStorage from "@react-native-community/async-storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { ipcRenderer } from "electron";
import createRootReducer from "./rootreducer";
import { api, blob, keycloak } from "../services/axios";
import electronLogger from "./middlewares/electronLogger";
import {
  DEV,
  ENV,
  IS_PREVIEW,
  IS_WEB_ENABLE
} from "../../static/misc/constants";
import refreshTokenMiddleware from "./middlewares/refreshTokenMiddleware";
import { getOsHostname } from "../../static/misc/utils";
import actionHandlerMiddleware from "./middlewares/actionHandlerMiddleware";
import { UPDATE_TIMER } from "../modules/examTaking/types/timer";

async function createTwStore(): Promise<CombinedState<any>> {
  let rehydrationComplete: (value?: unknown) => void;
  // let rehydrationFailed: (reason?: any) => void; // If you want to triggered a failureOnRehydratation

  // const rehydrationPromise = new Promise((resolve, reject) => {
  const rehydrationPromise = new Promise((resolve) => {
    rehydrationComplete = resolve;
    // rehydrationFailed = reject;
  });

  // Rehydration promise for local storage
  function rehydration(): Promise<unknown> {
    return rehydrationPromise;
  }

  // Defining every API for our app
  const clientsAxios = {
    default: {
      client: api
    },
    keycloak: {
      client: keycloak
    },
    blob: {
      client: blob
    }
  };

  // Defining our persistance (for datas from redux)
  let persistConfig;
  const osHostname = await getOsHostname();

  if (IS_PREVIEW) {
    persistConfig = {
      key: "root",
      storage: AsyncStorage,
      blacklist: [
        "examTaking",
        "studentPaper",
        "exams",
        "timer",
        "connectionTesting"
      ],
      whitelist: [] // No reducer persisted
    };
  } else if (IS_WEB_ENABLE) {
    persistConfig = {
      key: "root",
      transforms: [
        encryptTransform({
          secretKey: osHostname,
          onError(error) {
            console.warn(
              "<ERROR> Error on encrypttransform persistor root",
              error
            );
          }
        })
      ],
      storage: AsyncStorage,
      blacklist: ["exams", "status", "attachedfiles", "connectionTesting"]
    };
  } else {
    persistConfig = {
      key: "root",
      transforms: [
        encryptTransform({
          secretKey: osHostname,
          onError(error) {
            console.warn(
              "<ERROR> Error on encrypttransform persistor root",
              error
            );
            ipcRenderer.send("EXIT_APP");
          }
        })
      ],
      storage: AsyncStorage,
      blacklist: [
        "status",
        "studentPaper",
        "examTaking",
        "exams",
        "connectionTesting",
        "attachedfiles"
      ] // Persist a specific reducer here will not be persisted (you cannot persist a single properties of a reducer) - Studentpaper and ExamTaking are persisted in order to let the nested persist to take these from electron storage
    };
  }

  const pReducer = persistReducer(persistConfig, createRootReducer(osHostname));

  // Defining our redux middleware
  // axios filehandlermiddleware must be after axios middleware because can override axios response
  // refresh token middleware must be before axios middleware because will override token
  const middlewares = [
    refreshTokenMiddleware,
    multiClientMiddleware(clientsAxios),
    actionHandlerMiddleware,
    electronLogger,
    thunk
  ];

  // logger middleware only on dev
  const logger = createLogger({
    predicate: (getState, action) => action.type !== UPDATE_TIMER
  });
  if (ENV === DEV) {
    middlewares.push(logger);
  }

  const middleware = applyMiddleware(...middlewares);
  const store = createStore(pReducer, middleware);

  persistStore(store, null, () => {
    rehydrationComplete();
  });

  await rehydration();

  return store;
}

export default createTwStore;
