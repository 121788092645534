import React from "react";
import {
  View,
  StyleSheet,
  TextStyle,
  StyleProp,
  ViewStyle
} from "react-native";
import _ from "lodash";
import {
  COLOR_BLUE_HR,
  COLOR_GREY_BACKGROUND,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  PADDING_SIDES,
  WIDTH_1,
  WIDTH_2,
  WIDTH_3,
  WIN
} from "../../../static/misc/constants";
import {
  DisplayedExamsEnum,
  DisplayedExamsOrderEnum,
  ExamsProps
} from "../../modules/exams/ExamsView";
import {
  ExamsOrdering,
  GetMyPaperBase64Action
} from "../../modules/exams/types/exams";
import ListColumnsTitle from "../atoms/ListColumnsTitle";
import TopListMenu from "./TopListMenu";
import NextExamsList from "./NextExamsList";
import PassedExamsList from "./PassedExamsList";
import ListPagination from "../molecules/ListPagination";
import i18n from "../../services/i18n";
import { ExamType } from "../../modules/exams/types/exam";
import { MediaObjectType } from "../../modules/exams/types/attachedfiles";

interface ColumnsProperties {
  title: string;
  width: string;
  orderCategory?: string;
  textStyle?: StyleProp<TextStyle>;
}

export const SUBJECT = "SUBJECT";
export const NAME = "NAME";
export const DURATION = "DURATION";
export const DATE = "DATE";
export const MARK = "MARK";

// FIXME: Why this component use ExamsProps instead of DisplayedExamsListProps?
interface DisplayedExamsListProps {
  token?: string | undefined;
  exams: ExamType[];
  attachedFiles: MediaObjectType[];
  columnsTitlesAndWidth: ColumnsProperties[];
  rowStyle?: StyleProp<ViewStyle>;
  borderStyle?: StyleProp<ViewStyle>;
  displaySingleExamInfo?: (examInfoId: string) => void;
  getMyPaperBase64?: (
    studentPaperId: string,
    userToken: string,
    examId: string
  ) => GetMyPaperBase64Action;
}

interface ExamsListState {
  orderedNextExams: ExamType[];
  orderedPassedExams: ExamType[];
  pageSelected: number;
  perPage: number;
  yearsList: string[];
  nbPreviousPagesVisible: number;
}

class ExamsList extends React.PureComponent<ExamsProps, ExamsListState> {
  constructor(props: ExamsProps) {
    super(props);
    const { nextExams, passedExams } = this.props;
    this.state = {
      orderedNextExams: nextExams,
      orderedPassedExams: passedExams,
      pageSelected: 1,
      perPage: 4,
      yearsList: this.getAllExamsAcademicYears(nextExams, passedExams),
      nbPreviousPagesVisible: 2
    };
  }

  componentDidMount(): void {
    this.updateExamsListOrder(false);
  }

  componentDidUpdate(prevProps: ExamsProps, prevState: ExamsListState): void {
    const {
      ordered,
      displayedExams,
      selectedAcademicYear,
      locale,
      nextExams,
      passedExams
    } = this.props;
    const { pageSelected } = this.state;
    // Update the exams list only for the following reasons
    if (
      !_.isEqual(_.sortBy(prevProps.passedExams), _.sortBy(passedExams)) ||
      !_.isEqual(_.sortBy(prevProps.nextExams), _.sortBy(nextExams)) ||
      prevProps.displayedExams !== displayedExams ||
      prevProps.ordered !== ordered ||
      prevProps.locale !== locale ||
      prevState.pageSelected !== pageSelected ||
      prevProps.selectedAcademicYear !== selectedAcademicYear
    ) {
      this.updateExamsListOrder(
        prevProps.displayedExams !== displayedExams ||
          prevProps.selectedAcademicYear !== selectedAcademicYear
      );
    }
  }

  // Retrieve all academic years from the exam list (to display it as a dropdown menu later)
  getAllExamsAcademicYears(
    nextExams: ExamType[],
    passedExams: ExamType[]
  ): string[] {
    const examsYears: string[] = [];

    examsYears.push(i18n.t("exam.allAcademicYears"));

    if (nextExams) {
      nextExams.forEach((exam) => {
        if (
          exam.academicYear &&
          examsYears.indexOf(exam.academicYear.name) === -1
        ) {
          examsYears.push(exam.academicYear.name);
        }
      });
    }

    if (passedExams) {
      passedExams.forEach((exam) => {
        if (
          exam.academicYear &&
          examsYears.indexOf(exam.academicYear.name) === -1
        ) {
          examsYears.push(exam.academicYear.name);
        }
      });
    }

    return examsYears;
  }

  // Sort the exams list
  sortElements(
    order: DisplayedExamsOrderEnum,
    a?: Date | string | number,
    b?: Date | string | number
  ): number {
    if (!a) return order ? -1 : 1;
    if (!b) return order ? 1 : -1;
    if (a > b) return order ? 1 : -1;
    if (a === b) {
      return 0;
    }
    return order ? -1 : 1;
  }

  // Reorder the exams list depending on the chosen column to order
  orderExamsList(
    examList: ExamType[],
    order: DisplayedExamsOrderEnum,
    category: string
  ): ExamType[] {
    return examList.sort((a: ExamType, b: ExamType) => {
      switch (category) {
        case DATE: {
          return this.sortElements(order, a.startDate, b.startDate);
        }
        case SUBJECT: {
          return this.sortElements(
            order,
            a.subject?.name.toLocaleLowerCase() || "",
            b.subject?.name.toLocaleLowerCase() || ""
          );
        }
        case NAME: {
          return this.sortElements(
            order,
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase()
          );
        }
        case MARK: {
          return this.sortElements(
            order,
            a.myStudentPaper?.totalPoints,
            b.myStudentPaper?.totalPoints
          );
        }
        default: {
          return this.sortElements(order, a.startDate, b.startDate);
        }
      }
    });
  }

  // Paginating the exams list
  paginateList(exams: ExamType[]): ExamType[] {
    const { pageSelected, perPage } = this.state;
    const calcIndex = (pageSelected - 1) * perPage;

    return (exams as ExamType[]).filter((exam: ExamType, index: number) => {
      return index >= calcIndex && index < calcIndex + perPage;
    });
  }

  // Sorting the exams list based on the academic year filter
  sortExamsList(exams: ExamType[], ordering: ExamsOrdering): ExamType[] {
    const { selectedAcademicYear } = this.props;
    const filteredExams = exams.filter((exam) =>
      selectedAcademicYear
        ? exam.academicYear?.name === selectedAcademicYear
        : true
    );

    return this.orderExamsList(
      filteredExams,
      ordering.order,
      ordering.category
    );
  }

  // Updating the exams list ordering
  updateExamsListOrder(resetPage: boolean): void {
    const { displayedExams, ordered, nextExams, passedExams } = this.props;

    if (displayedExams === DisplayedExamsEnum.NEXT_EXAMS) {
      this.setState({
        orderedNextExams: this.sortExamsList(nextExams, ordered.nextExams)
      });
    } else {
      this.setState({
        orderedPassedExams: this.sortExamsList(passedExams, ordered.passedExams)
      });
    }
    if (resetPage) {
      this.setState({ pageSelected: 1 });
    }
  }

  render(): JSX.Element {
    const {
      token,
      nextExams,
      passedExams,
      displayedExams,
      selectedAcademicYear,
      attachedFiles,
      updateDisplayedExams,
      changeExamsOrder,
      updateSelectedAcademicYear,
      displaySingleExamInfo,
      getMyPaperBase64,
      ordered
    } = this.props;

    const {
      pageSelected,
      perPage,
      yearsList,
      nbPreviousPagesVisible,
      orderedPassedExams,
      orderedNextExams
    } = this.state;
    const totalCount =
      displayedExams === DisplayedExamsEnum.NEXT_EXAMS
        ? (orderedNextExams && orderedNextExams.length) || 0
        : (orderedPassedExams && orderedPassedExams.length) || 0;

    const columnsTitlesAndWidthNext: ColumnsProperties[] = [
      { title: i18n.t("homepage.date"), width: WIDTH_2, orderCategory: DATE },
      {
        title: i18n.t("homepage.subject"),
        width: WIDTH_3,
        orderCategory: SUBJECT
      },
      { title: i18n.t("name"), width: WIDTH_3, orderCategory: NAME },
      {
        title: i18n.t("homepage.duration"),
        width: WIDTH_1,
        orderCategory: DURATION
      },
      {
        title: i18n.t("homepage.rules"),
        width: WIDTH_1,
        textStyle: { textAlign: "center" }
      },
      { title: "", width: WIDTH_2 }
    ];

    const columnsTitlesAndWidthPassed: ColumnsProperties[] = [
      { title: i18n.t("homepage.date"), width: WIDTH_2, orderCategory: DATE },
      {
        title: i18n.t("homepage.subject"),
        width: WIDTH_3,
        orderCategory: SUBJECT
      },
      { title: i18n.t("name"), width: WIDTH_2, orderCategory: NAME },
      { title: i18n.t("homepage.mark"), width: WIDTH_1, orderCategory: MARK },
      {
        title: i18n.t("homepage.commentary"),
        width: WIDTH_2
      },
      { title: i18n.t("homepage.statut"), width: WIDTH_1 },
      { title: "", width: WIDTH_1 }
    ];

    return (
      <View style={[styles.defaultContainerStyle]}>
        <TopListMenu
          countPassedExams={(passedExams && passedExams.length) || 0}
          countNextExams={(nextExams && nextExams.length) || 0}
          displayedExams={displayedExams}
          selectedAcademicYear={selectedAcademicYear}
          academicYearsList={yearsList}
          updateDisplayedExams={(examType: DisplayedExamsEnum) =>
            updateDisplayedExams(examType)
          }
          updateSelectedAcademicYear={(index: number) =>
            updateSelectedAcademicYear(index ? yearsList[index] : undefined)
          }
        />
        <View style={styles.view}>
          <ListColumnsTitle
            columnsTitle={
              displayedExams === DisplayedExamsEnum.NEXT_EXAMS
                ? columnsTitlesAndWidthNext
                : columnsTitlesAndWidthPassed
            }
            listType={displayedExams}
            ordered={ordered}
            changeExamsOrder={(
              examType: DisplayedExamsEnum,
              order: DisplayedExamsOrderEnum,
              category: string
            ) => changeExamsOrder(examType, order, category)}
          />
          {(displayedExams === DisplayedExamsEnum.NEXT_EXAMS && (
            <NextExamsList
              exams={this.paginateList(orderedNextExams)}
              columnsTitlesAndWidth={columnsTitlesAndWidthNext}
              rowStyle={[styles.rowView]}
              borderStyle={[styles.rowViewBordered]}
              displaySingleExamInfo={displaySingleExamInfo}
              attachedFiles={attachedFiles}
            />
          )) || (
            <PassedExamsList
              token={token}
              getMyPaperBase64={getMyPaperBase64}
              exams={this.paginateList(orderedPassedExams)}
              columnsTitlesAndWidth={columnsTitlesAndWidthPassed}
              rowStyle={[styles.rowView]}
              borderStyle={[styles.rowViewBordered]}
              attachedFiles={attachedFiles}
              displaySingleExamInfo={() => undefined}
            />
          )}
          {totalCount > 0 && (
            <ListPagination
              numberOfPages={Math.ceil(totalCount / perPage)}
              pageSelected={pageSelected}
              changePage={(pageNumber: number) => {
                this.setState({
                  pageSelected: pageNumber
                });
              }}
              nbPreviousPagesVisible={nbPreviousPagesVisible}
            />
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    width: "100%",
    minWidth: WIN.width * 0.4,
    marginTop: PADDING_SIDES,
    flexDirection: "column",
    backgroundColor: COLOR_WHITE,
    flexGrow: 1
  },
  view: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    width: "100%",
    flexGrow: 1
  },
  rowView: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: PADDING_SIDES * 0.25,
    flex: 1,
    zIndex: -10
  },
  rowViewBordered: {
    borderBottomColor: COLOR_BLUE_HR,
    borderBottomWidth: 1
  }
});

export { ExamsList, ColumnsProperties, DisplayedExamsListProps };
