import { RefreshTokenAction } from "../../authentification/types/auth";
import {
  DownloadMediaObjectAction,
  SetMediaObjectAction,
  UpdateMediaObjectAction,
  UploadMediaObjectAction
} from "../../exams/types/attachedfiles";
import { GetExamsHashAction, GetSyncExamAction } from "../../exams/types/exams";
import { GetExamPublicKeyAction } from "../../exams/types/publickeys";
import {
  PostStudentPaperAction,
  UpdateStudentPaperAction
} from "../../examTaking/types/studentPaper";
import { GetUserAction, SetEstablishmentAction } from "./user";

export const ISONLINE = "ISONLINE";
export const ADD_EXAM_ASYNC_ACTION = "ADD_EXAM_ASYNC_ACTION";
export const DELETE_EXAM_ASYNC_ACTION = "DELETE_EXAM_ASYNC_ACTION";
export const SET_NB_SYNC_EXAMS = "SET_NB_SYNC_EXAMS";
export const SET_SYNC_EXAM = "SET_SYNC_EXAM";
export const START_STUDENTPAPER_SUBMISSION = "START_STUDENTPAPER_SUBMISSION";
export const ABORT_STUDENTPAPER_SUBMISSION = "ABORT_STUDENTPAPER_SUBMISSION";
export const RETRY_STUDENTPAPER_SUBMISSION = "RETRY_STUDENTPAPER_SUBMISSION";
export const END_STUDENTPAPER_SUBMISSION = "END_STUDENTPAPER_SUBMISSION";
export const COMPARE_TIME_WITH_SERVER = "COMPARE_TIME_WITH_SERVER";
export const COMPARE_TIME_WITH_SERVER_SUCCESS =
  "COMPARE_TIME_WITH_SERVER_SUCCESS";
export const COMPARE_TIME_WITH_SERVER_FAIL = "COMPARE_TIME_WITH_SERVER_FAIL";
export const EXITING_APPLICATION = "EXITING_APPLICATION";
export const CANCEL_EXITING_APPLICATION = "CANCEL_EXITING_APPLICATION";
export const SAFE_EXAM_BROWSER_NOT_FOUND = "SAFE_EXAM_BROWSER_NOT_FOUND";
export const TOGGLE_STARTNG_EXAM = "TOGGLE_STARTNG_EXAM";
export const SET_EXAM_SYNC_STATUS = "SET_EXAM_SYNC_STATUS";

export enum httpStatus {
  INIT,
  PENDING,
  SUCCESS,
  FAIL,
  ABORTED,
  NONE
}

export enum TimeVerificationStatus {
  NOT_VERIFIED,
  PENDING,
  OK,
  KO,
  ERROR
}

export enum SyncStatus {
  PENDING,
  SUCCESS,
  FAIL
}

export interface SetExamSyncStatusAction {
  type: typeof SET_EXAM_SYNC_STATUS;
  payload: SyncStatus;
}

export interface AddExamAsyncAction {
  type: typeof ADD_EXAM_ASYNC_ACTION;
  payload: string;
}

export interface DeleteExamAsyncAction {
  type: typeof DELETE_EXAM_ASYNC_ACTION;
  payload: string;
}
export interface SetSyncExamAction {
  type: typeof SET_SYNC_EXAM;
  payload: boolean;
}

export interface SetNbSyncExamsAction {
  type: typeof SET_NB_SYNC_EXAMS;
  payload: number;
}

export interface IsOnlineAction {
  type: typeof ISONLINE;
  payload: any;
}

export interface StudentPaperSubmissionAction {
  type:
    | typeof START_STUDENTPAPER_SUBMISSION
    | typeof RETRY_STUDENTPAPER_SUBMISSION
    | typeof ABORT_STUDENTPAPER_SUBMISSION
    | typeof END_STUDENTPAPER_SUBMISSION;
  payload: any;
}

export interface CompareTimeWithServerAction {
  type:
    | typeof COMPARE_TIME_WITH_SERVER
    | typeof COMPARE_TIME_WITH_SERVER_SUCCESS
    | typeof COMPARE_TIME_WITH_SERVER_FAIL;
  payload: any;
}
export interface ExitApplicationAction {
  type: typeof EXITING_APPLICATION | typeof CANCEL_EXITING_APPLICATION;
  payload: any;
}

export interface SafeExamBrowserNotFound {
  type: typeof SAFE_EXAM_BROWSER_NOT_FOUND;
}

export interface ToggleExamStartingAction {
  type: typeof TOGGLE_STARTNG_EXAM;
  payload: boolean;
}

export interface SubmittingMediasObjectsStatus {
  examId: string;
  studentPaperId?: string;
  questionId: string;
  mediaId: string; // our own ID to check media status
  submittingMediaObject: boolean;
  submittingMediaObjectStatus: httpStatus;
  retryCount: number;
}

export interface SubmittingStudentPaperStatus {
  examId: string;
  studentPaperId?: string;
  submittingStudentPaper: boolean;
  submittingStudentPaperStatus: httpStatus;
  submittingMediaObjects: boolean;
  submittingMediaObjectsStatus: httpStatus;
  retryCount: number;
}

export interface StatusState {
  isOnline: boolean;
  isRefreshingToken: boolean;
  showLoader: boolean;
  isExamSynchronising: boolean;
  gettingUserInfo: boolean;
  nbExamsToSync: number;
  nbMediaObjectToGet: number;
  nbStudentPaperToSync: number;
  examAsyncActions: string[];
  examAsyncNb: number;
  syncExams: boolean;
  gettingExamsHash: boolean;
  gettingExam: boolean;
  gettingPublicKey: boolean;
  gettingPreviewExam: boolean;
  submittingStudentPaperStatus: SubmittingStudentPaperStatus[];
  submittingMediaObjectsStatus: SubmittingMediasObjectsStatus[];
  postingEstablishment: boolean;
  timeVerificationStatus: TimeVerificationStatus;
  timeVerificationCheck: boolean;
  exitingApplication: boolean;
  exitApplicationAction?: string;
  isExamStarting: boolean;
  examSyncStatus: SyncStatus;
}

export type StatusActionType =
  | IsOnlineAction
  | RefreshTokenAction
  | GetUserAction
  | GetSyncExamAction
  | GetExamsHashAction
  | SetNbSyncExamsAction
  | SetSyncExamAction
  | SetMediaObjectAction
  | UpdateMediaObjectAction
  | DownloadMediaObjectAction
  | AddExamAsyncAction
  | GetExamPublicKeyAction
  | DeleteExamAsyncAction
  | PostStudentPaperAction
  | UpdateStudentPaperAction
  | UploadMediaObjectAction
  | StudentPaperSubmissionAction
  | SetEstablishmentAction
  | CompareTimeWithServerAction
  | ExitApplicationAction
  | ToggleExamStartingAction
  | SafeExamBrowserNotFound
  | SetExamSyncStatusAction;
