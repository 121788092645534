/* eslint-disable no-underscore-dangle */
// Declaring network variables to be injected through webpack
declare const _TESTWE_API: string;
declare const _KEYCLOAK_BASE_URL: string;

export const TESTWE_API = _TESTWE_API;
export const KEYCLOAK_BASE_URL = _KEYCLOAK_BASE_URL;
export const KEYCLOAK_API = `${_KEYCLOAK_BASE_URL}/auth/realms/users/protocol/openid-connect`;
export const LOCAL_API = "http://localhost:38540";

export const SENTRY_DSN_APP =
  "https://68467f8bfe544c968dc6e836bbe6309f@o388031.ingest.sentry.io/5742531";
export const SENTRY_DSN_WEB =
  "https://21eeb39bc196400d9a8d2d555334f772@o388031.ingest.sentry.io/6347025";

export const MAX_RETRY_NUMBER = 5;

export const CONNECTION_TEST_FILE_URL =
  "https://upload.wikimedia.org/wikipedia/commons/2/2c/Rotating_earth_%28large%29.gif";

export const FILE_SIZE = 1001718;

// API URLS
export const TOKEN = "/token";

export const GET_USER_ME = "/users/me";
export const USERS_ID = (id: string): string => `/users/${id}`;

export const EXAMS_SYNC = "/exams-sync";
export const SYNCRO_EXAMS = "/exams/synchronize";

export const EXAMS_STUDENT = "/exams-student";
export const PAST_EXAMS_STUDENT = "/get-passed-exams";
export const STUDENT_PAPER_RESUMING = "/student-paper-resumings";

export const EXAMS = "/exams";
export const PAPERS = "/student-papers";

export const UPLOAD_MEDIA_OBJECTS = "/media-objects";
export const GET_SIGNED_URL = "/media-objects/generate-signed-url";

export const EQUIPMENT_CHECK = "/equipments";
export const EQUIPMENT_CHECK_ID = (id: string): string => `/equipments/${id}`;

export const DOWNLOAD_STUDENT_PAPER = (id: string): string =>
  `/student-papers/${id}/download`;

export const DOWNLOAD_MEDIA_OBJECTS = (id: string): string =>
  `/media-objects/${id}/download`;

export const GET_SERVER_TIMES = "/server-times";

// LOCAL API URLS
export const TINY_MCE_LOCAL = `${LOCAL_API}/assets/tinymce/js/tinymce/tinymce.min.js`;
export const FMATH_PLUGIN_LOCAL = `${LOCAL_API}/assets/FMathEditor/plugin.js`;

export const MEDIA = "/media";
export const MEDIA_REPLACE = "/media/replace";
export const UNCIPHER_MEDIA = "/media/uncipher";
export const BLOB = "/media/blob";
export const MEDIA_UPLOAD = "/media/upload";
export const PROCTOR = "/proctor";
export const PROCTOR_SEND = "/proctor/send";
export const PROCTOR_SEND_ARCHIVES = "/proctor/send/archives";
export const LOGS = "/student/logs";
export const STUDENT_EXPORT = "/student/export";

export const USER = (id: string): string => `/users/${id}`;

// FAQ redirection
export const FAQ_LINK = "https://support.testwe.eu/";
