import _ from "lodash";
import React from "react";
import { View, StyleSheet, Text, LayoutChangeEvent } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { COLOR_BLUE } from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONTSIZE_21,
  FONT_GILROY_EXTRA_BOLD,
  FONT_GILROY_MEDIUM,
  PARTINDEX_TYPE_EXERCISE,
  PARTINDEX_TYPE_QUESTION
} from "../../../static/misc/constants";
import { chronoExamPart } from "../../../static/misc/images";
import { guidGenerator } from "../../../static/misc/utils";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import {
  ExamIndex,
  ExamParam,
  ExamPart,
  ExamQuestion,
  ExamType,
  ExamWorkBook
} from "../../modules/exams/types/exam";
import { updateSpreadsheet } from "../../modules/examTaking/actions/examTaking";
import {
  ModifyBookMarkedAction,
  UpdateSpreadsheetAction
} from "../../modules/examTaking/types/examTaking";
import { StudentAnswerType } from "../../modules/examTaking/types/studentPaper";
import { LocalApiErrorAction } from "../../modules/main/types/error";
import { completeStudentAnswerStatus } from "../../services/exam-navbar-progress";
import { RootState } from "../../store/rootreducer";
import ExamTakingTimer, { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import HtmlRenderer from "../atoms/HtmlRenderer";
import QuestionTemplate from "../templates/exam/QuestionTemplate";
import SwitcherQuestionsTypes from "./SwitcherQuestionsTypes";

interface PageExamContentProps {
  currentExam: ExamType;
  examParams?: ExamParam;
  partIndex: ExamIndex;
  examId: string;
  examParts: ExamPart[];
  previousAnswers: StudentAnswerType[] | undefined;
  studentAnswers: StudentAnswerType[];
  currentExamPart: number;
  currentPartIndex: number;
  onEndExam: boolean;
  currentAttachedFiles: MediaType[] | undefined;
  forceStopTimers: boolean;
  shouldStopRecording: boolean;
  isOpenBook: boolean;
  workBooks?: ExamWorkBook[];
  onFinishedTimer: (timerType: TimerTypeEnum) => void;
  onSaveFileOnFS: (
    questionId: string,
    questionType: string,
    fileContent?: string,
    mediaId?: string,
    saveSpreadsheetCb?: (filepath: string) => void
  ) => void;
  onPushStudentAnswerWithIndex: (
    StudentAnswer: Partial<StudentAnswerType>
  ) => void;
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
  toggleAudioProctoring: () => void;
  localApiError: (err: any) => LocalApiErrorAction;
  updateWorkBook: (
    examId: string,
    questionId: string,
    fileId: string,
    content: string
  ) => UpdateSpreadsheetAction;
}

interface PageExamContentState {
  containerWidth: number;
}

class PageExamContent extends React.Component<
  PageExamContentProps,
  PageExamContentState
> {
  constructor(props: PageExamContentProps) {
    super(props);

    this.state = {
      containerWidth: 0
    };
  }

  // We want the component to re-render only for the following reasons
  shouldComponentUpdate(
    nextProps: PageExamContentProps,
    nextState: PageExamContentState
  ): boolean {
    const {
      currentExamPart,
      currentPartIndex,
      forceStopTimers,
      onEndExam
    } = this.props;
    const { containerWidth } = this.state;

    return (
      nextProps.onEndExam !== onEndExam ||
      nextProps.currentExamPart !== currentExamPart ||
      nextProps.currentPartIndex !== currentPartIndex ||
      nextProps.forceStopTimers !== forceStopTimers ||
      nextState.containerWidth !== containerWidth
    );
  }

  onContainerLayout(event: LayoutChangeEvent): void {
    const { containerWidth } = this.state;
    const { width } = event.nativeEvent.layout;

    if (!containerWidth) {
      this.setState({
        containerWidth: width
      });
    }
  }

  // Render the question
  renderQuestion = (
    partIndexType: string,
    item: ExamQuestion,
    questionNumber: number
  ): JSX.Element => {
    const {
      examParams,
      studentAnswers,
      previousAnswers,
      onEndExam,
      currentAttachedFiles,
      forceStopTimers,
      examId,
      currentExamPart,
      shouldStopRecording,
      workBooks,
      onPushStudentAnswerWithIndex,
      onFinishedTimer,
      onSaveFileOnFS,
      modifyBookMarked,
      toggleAudioProctoring,
      localApiError,
      updateWorkBook,
      currentExam
    } = this.props;

    const currentAnswerIndex = studentAnswers.findIndex(
      (answer) => answer.question === item.id
    );
    let previousAnswerIndex = -1;
    if (
      previousAnswers &&
      typeof previousAnswers !== "string" &&
      _.isArray(previousAnswers)
    ) {
      previousAnswerIndex = previousAnswers.findIndex(
        (answer) => answer.question === item.id
      );
    }
    return (
      <View key={guidGenerator()}>
        <QuestionTemplate
          key={guidGenerator()}
          examId={examId}
          examParams={examParams}
          isBookMarked={item.isBookMarked}
          modifyBookMarked={modifyBookMarked}
          question={item}
          currentExamPart={currentExamPart}
          questionNumber={questionNumber}
          questionPoints={item.numberPoint}
          examEntitled={item.name}
          duration={
            partIndexType === PARTINDEX_TYPE_QUESTION &&
            item.durationLimit === true
              ? item.remainingDuration
              : undefined
          }
          forceStopTimers={forceStopTimers}
          onFinishedTimer={(timerType: TimerTypeEnum) =>
            onFinishedTimer(timerType)
          }
        />
        <SwitcherQuestionsTypes
          key={guidGenerator()}
          onEndExam={onEndExam}
          currentAttachedFiles={currentAttachedFiles}
          previousAnswer={
            previousAnswers && previousAnswerIndex >= 0
              ? previousAnswers[previousAnswerIndex]
              : undefined
          }
          toggleAudioProctoring={toggleAudioProctoring}
          shouldStopRecording={shouldStopRecording}
          onSaveFileOnFS={onSaveFileOnFS}
          currentExam={currentExam}
          onPushStudentAnswer={(sa) =>
            onPushStudentAnswerWithIndex(
              completeStudentAnswerStatus(sa as StudentAnswerType, currentExam)
            )
          }
          localApiError={localApiError}
          currentAnswer={
            currentAnswerIndex >= 0
              ? studentAnswers[currentAnswerIndex]
              : undefined
          }
          item={{ ...item }}
          questionId={item.id || ""}
          workBooks={workBooks}
          updateWorkBook={(fileId: string, content: string) => {
            updateWorkBook(examId, item.id || "", fileId, content);
          }}
        />
      </View>
    );
  };

  render(): JSX.Element | null {
    const {
      examParts,
      currentExamPart,
      currentPartIndex,
      examId,
      partIndex,
      forceStopTimers,
      onFinishedTimer,
      isOpenBook
    } = this.props;

    const { containerWidth } = this.state;

    if (!partIndex) return null;
    const currentPart = examParts[currentExamPart];
    // TODO review exercise header because it does not display the part header
    return (
      <View
        style={styles.viewContainer}
        onLayout={(event) => this.onContainerLayout(event)}
      >
        <View style={styles.titleContainer}>
          {containerWidth ? (
            <View style={styles.instructionsContainer}>
              <View style={styles.titleRow}>
                <Text style={styles.title}>{currentPart.name}</Text>

                {currentPart.durationLimit && (
                  <ExamTakingTimer
                    key={guidGenerator()}
                    currentExamPart={currentExamPart}
                    examId={examId}
                    elementId={currentPart.id}
                    timerType={TimerTypeEnum.PART}
                    duration={currentPart.remainingDuration}
                    running={currentPart.durationLimit && !forceStopTimers}
                    onFinish={(timerType: TimerTypeEnum) =>
                      onFinishedTimer(timerType)
                    }
                    customIcon={chronoExamPart}
                  />
                )}
              </View>
              <HtmlRenderer
                content={currentPart.instruction || ""}
                containerStyle={styles.instruction}
                isLinkUsable={isOpenBook}
                width="100%"
              />
              {partIndex.type === PARTINDEX_TYPE_EXERCISE && (
                <>
                  <Text style={styles.titleExercice}>
                    {partIndex.exercise?.name}
                    {partIndex.type === PARTINDEX_TYPE_EXERCISE &&
                    partIndex.exercise?.durationLimit === true &&
                    partIndex.exercise?.duration > 0 &&
                    partIndex.exercise?.remainingDuration !== undefined ? (
                      <ExamTakingTimer
                        key={guidGenerator()}
                        // duration={partIndex.exercise?.remainingDuration}
                        currentExamPart={currentExamPart}
                        examId={examId}
                        elementId={partIndex.exercise.id}
                        timerType={TimerTypeEnum.EXERCISE}
                        running={!forceStopTimers}
                        onFinish={(timerType: TimerTypeEnum) =>
                          onFinishedTimer(timerType)
                        }
                      />
                    ) : null}
                  </Text>

                  <HtmlRenderer
                    content={partIndex.exercise?.instruction || ""}
                    containerStyle={styles.instruction}
                    isLinkUsable={isOpenBook}
                    width="100%"
                  />
                </>
              )}
            </View>
          ) : null}
        </View>
        <View style={styles.questionContainer}>
          {(partIndex.type === PARTINDEX_TYPE_QUESTION &&
            partIndex.question &&
            this.renderQuestion(
              partIndex.type,
              partIndex.question,
              currentPartIndex + 1
            )) ||
            (partIndex.type === PARTINDEX_TYPE_EXERCISE &&
              partIndex.exercise &&
              partIndex.exercise.questions.map((item, index) => {
                return this.renderQuestion(partIndex.type, item, index + 1);
              }))}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewContainer: {
    flexDirection: "column",
    flexGrow: 1
  },
  titleContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  instructionsContainer: {
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: "wrap"
  },
  title: {
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_21,
    color: COLOR_BLUE
  },
  titleExercice: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_21,
    color: COLOR_BLUE
  },
  instruction: {
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE
  },
  questionContainer: {
    flexGrow: 1
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 10
  }
});

export default connect(
  (state: RootState, props: PageExamContentProps) => ({
    currentExam: state.examTaking.exams?.find((e) => e.id === props.examId)
  }),
  (dispatch: Dispatch) => {
    return {
      ...bindActionCreators(
        {
          updateWorkBook: updateSpreadsheet
        },
        dispatch
      )
    };
  }
  // FIXME: Fix type here
)(PageExamContent as any);
