/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../store/rootreducer";
import { getError } from "../main/actions/error";
import {
  createExamTaking,
  endExamTaking,
  startExamTaking
} from "../examTaking/actions/examTaking";
import { createPublicKey, getExamPublicKey } from "./actions/publickeys";
import {
  updateMediaObject,
  createArchiveBeforeUpload
} from "./actions/attachedfiles";
import ExamInfoView, { ExamInfoViewProps } from "./ExamInfoView";
import { getExamHash, getSyncExam } from "./actions/exams";
import { setExamSyncStatus } from "../main/actions/status";

const mapStateToProps = (state: RootState, props: ExamInfoViewProps) => {
  const examId = props.route.params?.examInfoId as string;
  return {
    userId: state.user.id,
    appDataPath: state.user.appDataPath || "",
    mediaObjects: state.attachedfiles.mediaObjects,
    token: state.auth.credentials?.access_token,
    publicKeys: state.publickeys.examsPublicKeys,
    gettingExam: state.status.gettingExam,
    gettingPublicKey: state.status.gettingPublicKey,
    locale: state.user.locale,
    isOnline: state.status.isOnline,
    nextExams: state.exams.nextExams,
    studentPapers: state.studentPaper.studentPapers,
    currentExamTaking: state.examTaking.exams?.find((e) => e.id === examId),
    examSyncStatus: state.status.examSyncStatus,
    examsHash: state.exams.examsHash
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        createExamTaking,
        updateMediaObject,
        createPublicKey,
        getExamPublicKey,
        createArchiveBeforeUpload,
        getError,
        startExamTaking,
        endExamTaking,
        getExamHash,
        setExamSyncStatus,
        getSyncExam
      },
      dispatch
    )
  };
};

const ExamsContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(ExamInfoView);

export default ExamsContainer;
