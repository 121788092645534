import React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { COLOR_BLUE_LIGHT } from "../../../static/misc/colors";
import Bookmark from "../atoms/Bookmark";
import Label from "../atoms/Label";
import QuestionTitle from "../atoms/QuestionTitle";
import i18n from "../../services/i18n";
import { PADDING_SIDES } from "../../../static/misc/constants";
import ExamTakingTimer, { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import { guidGenerator } from "../../../static/misc/utils";
import { ExamQuestion } from "../../modules/exams/types/exam";
import { ModifyBookMarkedAction } from "../../modules/examTaking/types/examTaking";
import { RootState } from "../../store/rootreducer";

interface ExamHeaderQuestionProps {
  questionNumber: number;
  questionPoints: number;
  question: ExamQuestion;
  examId: string;
  isBookMarked: boolean | undefined;
  duration?: number;
  currentExamPart?: number;
  forceStopTimers: boolean;
  showQuestionsPoints: boolean;
  onFinishedTimer: (timerType: TimerTypeEnum) => void;
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
}

class ExamHeaderQuestion extends React.PureComponent<ExamHeaderQuestionProps> {
  render(): JSX.Element {
    const {
      questionNumber,
      questionPoints,
      question,
      isBookMarked,
      examId,
      duration,
      forceStopTimers,
      currentExamPart,
      showQuestionsPoints,
      modifyBookMarked,
      onFinishedTimer
    } = this.props;
    return (
      <View style={styles.rowHeaderQuestion}>
        <QuestionTitle questionNumber={questionNumber} />
        <View style={styles.padding} />
        {showQuestionsPoints && (
          <Label
            text={`${questionPoints} ${i18n.t("exam.points")}`}
            backgroundColor={COLOR_BLUE_LIGHT}
          />
        )}
        <View style={styles.padding} />
        <Bookmark
          key={guidGenerator()}
          bookmarkOn={isBookMarked}
          turnOn={() =>
            question.id ? modifyBookMarked(examId, question.id) : null
          }
        />
        {duration !== undefined ? (
          <ExamTakingTimer
            key={guidGenerator()}
            timerType={TimerTypeEnum.QUESTION}
            currentExamPart={currentExamPart}
            examId={examId}
            elementId={question.id}
            running={!forceStopTimers}
            onFinish={(timerType: TimerTypeEnum) => onFinishedTimer(timerType)}
          />
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  rowHeaderQuestion: {
    paddingBottom: PADDING_SIDES * 0.1,
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap"
  },
  padding: {
    paddingHorizontal: PADDING_SIDES * 0.15
  }
});

export default connect((state: RootState, props: ExamHeaderQuestionProps) => {
  return {
    showQuestionsPoints:
      state.examTaking.exams?.find((e) => e.id === props.examId)?.examParams
        ?.showQuestionsPoints ?? true
  };
})(ExamHeaderQuestion);
