import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../store/rootreducer";
import {
  createStudentPaper,
  clearStudentPaper,
  fillStudentPaper,
  postStudentPaper,
  editStudentPaper,
  updateStudentPaper
} from "./actions/studentPaper";
import {
  uploadMediaObjectOnAPI,
  removeMediaObject,
  createArchiveBeforeUpload,
  initUploadMediaObjectOnAWS,
  uploadDataOnAPI
} from "../exams/actions/attachedfiles";
import {
  initStudentPaperSubmission,
  stopStudentPaperSubmission
} from "../main/actions/status";
import ExamTakingView, { ExamTakingViewProps } from "./ExamTakingView";
import {
  clearExamTaking,
  endExamTaking,
  modifyBookMarked
} from "./actions/examTaking";
import { clearTimer, createExamTimer } from "./actions/timer";
import { clearPublicKeys } from "../exams/actions/publickeys";
import {
  changingExamMyStudPaperStatus,
  getSyncExam
} from "../exams/actions/exams";
import { putHasTakingTraining } from "../main/actions/user";
import { localApiError, getError } from "../main/actions/error";

const mapStateToProps = (state: RootState, props: ExamTakingViewProps) => {
  const examId = props.route.params?.examId as string;
  return {
    isOnline: state.status.isOnline,
    studentId: state.user.id,
    appDataPath: state.user.appDataPath || "",
    currentExam: state.examTaking.exams?.find((e) => e.id === examId),
    mediaObjects: state.attachedfiles.mediaObjects,
    studentPapers: state.studentPaper.studentPapers,
    examsPublicKeys: state.publickeys.examsPublicKeys,
    submittingStudentPaperStatus: state.status.submittingStudentPaperStatus,
    submittingMediaObjectsStatus: state.status.submittingMediaObjectsStatus,
    // eslint-disable-next-line camelcase
    authorizationToken: state.auth.credentials?.access_token
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        createExamTimer,
        createStudentPaper,
        clearStudentPaper,
        clearTimer,
        removeMediaObject,
        clearExamTaking,
        modifyBookMarked,
        getSyncExam,
        editStudentPaper,
        clearPublicKeys,
        changingExamMyStudPaperStatus,
        postStudentPaper,
        updateStudentPaper,
        fillStudentPaper,
        uploadMediaObjectOnAPI,
        uploadDataOnAPI,
        initUploadMediaObjectOnAWS,
        putHasTakingTraining,
        endExamTaking,
        initStudentPaperSubmission,
        stopStudentPaperSubmission,
        createArchiveBeforeUpload,
        localApiError,
        getError
      },
      dispatch
    )
  };
};

const ExamTakingContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(ExamTakingView);

export default ExamTakingContainer;
