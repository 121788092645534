import _ from "lodash";

import {
  TEXT_BLANKS_QUESTION,
  TEXT_DROPDOWN_QUESTION
} from "../../static/misc/constants";
import { ExamQuestionExtended } from "../atomic/organisms/ExamNavbar/ExamBottombar";
import {
  ExamType,
  ExamIndex,
  ExamPart,
  ExamPartStatus
} from "../modules/exams/types/exam";
import { StudentAnswerType } from "../modules/examTaking/types/studentPaper";

export const hasBookmarkedQuestion = (
  questionId: string,
  exam: ExamType
): boolean => {
  const { examParts } = exam;
  const bookMarkedQuestion: boolean[] = [];
  if (examParts && typeof examParts !== "string") {
    examParts.forEach((examPart) => {
      examPart.partIndexes.forEach((index) => {
        bookMarkedQuestion.push(
          !!(questionId === index.question?.id && index.question.isBookMarked)
        );
      });
    });
  }

  return bookMarkedQuestion.includes(true);
};

/**
 * Check if the given answer is completed or not
 * @param answer the answer to check
 * @returns boolean true if user has answered the question
 */
export const hasCompletedGivenAnswer = (answer: StudentAnswerType): boolean => {
  const hasCompletedSelectValue: boolean[] = [];
  if (answer.givenAnswer) {
    switch (answer.questionType) {
      case TEXT_DROPDOWN_QUESTION: {
        const regArray = answer.givenAnswer.match(/<\s*select (.*?)>/g);

        regArray?.forEach((currentSelectLine) => {
          if (
            currentSelectLine.includes('value=""') ||
            !currentSelectLine.includes("value") ||
            currentSelectLine.includes('value="empty"')
          ) {
            hasCompletedSelectValue.push(false);
          } else {
            hasCompletedSelectValue.push(true);
          }
        });
        break;
      }

      case TEXT_BLANKS_QUESTION: {
        const regArray = answer.givenAnswer.match(/<\s*input (.*?)>/g);

        regArray?.forEach((currentSelectLine) => {
          if (currentSelectLine.includes('value=""')) {
            hasCompletedSelectValue.push(false);
          } else {
            hasCompletedSelectValue.push(true);
          }
        });
        break;
      }

      default:
        hasCompletedSelectValue.push(answer.givenAnswer?.length > 0);
        break;
    }
  }
  return hasCompletedSelectValue.includes(true);
};

export const hasCompletedGivenChoices = (answer: StudentAnswerType): boolean =>
  (answer.givenChoices || [])?.length > 0;

export const hasCompletedMediaObjects = (answer: StudentAnswerType): boolean =>
  (answer.mediaObjects || [])?.length > 0;

export const hasCompletedMediaData = (answer: StudentAnswerType): boolean =>
  (answer.mediaData || [])?.length > 0;

export const hasVisitedQuestion = (answer: StudentAnswerType): boolean =>
  answer.status === "visited";

export const completeStudentAnswerStatus = (
  answer: StudentAnswerType,
  exam?: ExamType
): StudentAnswerType => {
  const studentAnswerTmp: StudentAnswerType = { ...answer };

  if (
    answer.status === undefined ||
    answer.status === "init" ||
    hasVisitedQuestion(answer)
  ) {
    studentAnswerTmp.status = "visited";
  }

  if (hasCompletedAnswer(answer)) {
    studentAnswerTmp.status = "completed";
  }

  if (exam && hasBookmarkedQuestion(answer.question, exam)) {
    studentAnswerTmp.status = "bookmarked";
  }

  return studentAnswerTmp;
};

export const hasCompletedAnswer = (answer: StudentAnswerType): boolean =>
  hasCompletedGivenAnswer(answer) ||
  hasCompletedGivenChoices(answer) ||
  hasCompletedMediaObjects(answer) ||
  hasCompletedMediaData(answer) ||
  answer.noChoice ||
  false;

export const hasBookmarkedCurrentExamPartIndexes = (
  currentExamPartIndexes: ExamIndex[]
): boolean => {
  const isBookMarked: boolean[] = [];

  currentExamPartIndexes.forEach((index) =>
    isBookMarked.push(!!index.question?.isBookMarked)
  );

  return isBookMarked.includes(true);
};

export const getExamPartUtils = (
  currentExam: ExamType,
  currentExamPartIndex: number,
  studentAnswers: StudentAnswerType[],
  examPart: ExamPart
): {
  hasPartiallyCompletedExamPart?: boolean;
  hasCompletedExamPart?: boolean;
  hasBookmarkedExamPart?: boolean;
} => {
  if (
    !currentExam.examParts ||
    currentExam.examParts.length <= 1 ||
    typeof currentExam.examParts === "string"
  )
    return {};

  const currentExamPartIndexes =
    currentExam.examParts[currentExamPartIndex].partIndexes;

  const idxFromCurrentExamPartIndexes = currentExamPartIndexes.reduce<string[]>(
    (acc, pi) => {
      if (pi.question?.id) acc.push(pi.question.id);

      if (pi.exercise?.id)
        pi.exercise.questions.forEach((q) => q.id && acc.push(q.id));

      return acc;
    },
    []
  );

  const studentAnswersFromCurrentExamPart = studentAnswers
    .filter((sa) => idxFromCurrentExamPartIndexes.includes(sa.question))
    .filter(hasCompletedAnswer);

  return {
    hasPartiallyCompletedExamPart:
      studentAnswersFromCurrentExamPart.length > 0 &&
      studentAnswersFromCurrentExamPart.length <
        idxFromCurrentExamPartIndexes.length &&
      _.isEqual(currentExamPartIndex, examPart.position),
    hasCompletedExamPart:
      _.isEqual(
        studentAnswersFromCurrentExamPart.length,
        idxFromCurrentExamPartIndexes.length
      ) && _.isEqual(currentExamPartIndex, examPart.position),
    hasBookmarkedExamPart: hasBookmarkedCurrentExamPartIndexes(
      currentExamPartIndexes
    )
  };
};

export const completeExamPartsWithStatus = (
  currentExamPartIndex: number,
  examParts: ExamPart[],
  status: ExamPartStatus
): ExamPart[] =>
  [...examParts].map((ep, index) =>
    index === currentExamPartIndex ? { ...ep, status } : ep
  );

export const completeExamPartsInExamTakingObject = (
  currentExam: ExamType,
  currentExamPartIndex: number,
  studentAnswers: StudentAnswerType[],
  examPart: ExamPart
): ExamType => {
  if (!currentExam.examParts || typeof currentExam.examParts === "string")
    return currentExam;

  const {
    hasCompletedExamPart,
    hasPartiallyCompletedExamPart,
    hasBookmarkedExamPart
  } = getExamPartUtils(
    currentExam,
    currentExamPartIndex,
    studentAnswers,
    examPart
  );

  if (hasCompletedExamPart) {
    return {
      ...currentExam,
      examParts: completeExamPartsWithStatus(
        currentExamPartIndex,
        currentExam.examParts,
        "completed"
      )
    };
  }

  if (hasPartiallyCompletedExamPart) {
    return {
      ...currentExam,
      examParts: completeExamPartsWithStatus(
        currentExamPartIndex,
        currentExam.examParts,
        "partially-completed"
      )
    };
  }

  if (hasBookmarkedExamPart) {
    return {
      ...currentExam,
      examParts: completeExamPartsWithStatus(
        currentExamPartIndex,
        currentExam.examParts,
        "bookmarked"
      )
    };
  }

  return {
    ...currentExam,
    examParts: completeExamPartsWithStatus(
      currentExamPartIndex,
      currentExam.examParts,
      "initial"
    )
  };
};

export const getQuestions = (
  currentExam: ExamType,
  selectedExamPart?: ExamPart
): ExamQuestionExtended[] => {
  if (typeof currentExam.examParts === "string" || !currentExam.examParts)
    return [];

  const examIndexes = selectedExamPart?.partIndexes.filter(
    (partIndex) => partIndex
  );
  const result: ExamQuestionExtended[] = [];

  examIndexes?.forEach((pi, i) => {
    if (pi.type === "question" && pi.question)
      result.push({ ...pi.question, position: i });

    if (pi.type === "exercise" && pi.exercise) {
      const { questions } = pi.exercise;
      const exerciseIndex = examIndexes
        .filter((p) => p.type === "exercise" && p.exercise)
        .findIndex((e) => e.exercise?.id === pi.exercise?.id);

      if (questions)
        result.push(
          ...questions.map((q) => ({
            ...q,
            position: i,
            // This is used to ensure that the exercise questions are still in the same position as the exercise
            // on which they are based
            positionInExercise: q.position,
            isQuestionInExercise: true,
            exerciseIndex: exerciseIndex > -1 ? exerciseIndex + 1 : 0,
            hasExerciseDuration: !!pi.exercise?.duration
          }))
        );
    }
  });

  return result;
};

export const getExamPart = (
  index: number,
  currentExam: ExamType
): ExamPart | undefined => {
  return currentExam.examParts && typeof currentExam.examParts !== "string"
    ? currentExam.examParts[index]
    : undefined;
};

export const getQuestionNumber = (
  currentExam: ExamType,
  questionId?: string,
  selectedExamPart?: ExamPart
): number => {
  if (typeof currentExam.examParts === "string" || !currentExam.examParts)
    return 0;

  const examIndexes = selectedExamPart?.partIndexes.filter((ei) => ei);
  const questions: ExamQuestionExtended[] = [];
  examIndexes?.forEach((ei) => {
    if (ei.type === "question" && ei.question) {
      questions.push(ei.question);
    }
  });

  const index = questions.findIndex((q) => q.id === questionId);
  return index > -1 ? index + 1 : 0;
};

export const examHasPartOrQuestionWithChrono = (
  currentExam: ExamType,
  durationFixed?: boolean
): boolean => {
  if (typeof currentExam.examParts === "string" || !currentExam.examParts)
    return false;

  const arr: boolean[] = [];

  currentExam.examParts.forEach((ep) => {
    arr.push(ep.durationLimit ?? false);

    ep.partIndexes.forEach((ei) => {
      if (ei.type === "question" && ei.question) {
        arr.push(ei.question.durationLimit ?? false);
      }

      if (ei.type === "exercise" && ei.exercise) {
        arr.push(ei.exercise.durationLimit ?? false);
      }
    });
  });

  return durationFixed !== undefined
    ? durationFixed && arr.includes(true)
    : arr.includes(true);
};
