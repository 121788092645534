import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import * as Battery from "expo-battery";
import { connect } from "react-redux";
import moment from "../../services/moment";
import { COLOR_WHITE } from "../../../static/misc/colors";
import {
  nowifi,
  placeholderProfil,
  tel,
  wifi
} from "../../../static/misc/images";
import UserAvatar from "../atoms/UserAvatar";
import {
  EACH_SECONDS,
  FONTSIZE_12,
  FONTSIZE_13,
  FONTSIZE_14,
  FONT_GILROY_EXTRA_BOLD,
  FONT_GILROY_MEDIUM,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import BatteryImage from "../atoms/BatteryImage";
import Icon from "../atoms/Icon";
import { RootState } from "../../store/rootreducer";

export interface HeaderExamBlockContentProps {
  phoneNumberSupport: string;
  showPhoneNumber: boolean;
  isOnline: boolean;
}

interface HeaderExamBlockContentState {
  now: moment.Moment;
  batteryLevel: number | undefined;
}

class HeaderExamBlockContent extends React.PureComponent<
  HeaderExamBlockContentProps,
  HeaderExamBlockContentState
> {
  intervalClock!: ReturnType<typeof setInterval>;

  constructor(props: HeaderExamBlockContentProps) {
    super(props);
    this.state = {
      now: moment(),
      batteryLevel: undefined
    };
  }

  async componentDidMount(): Promise<void> {
    // Setting up the interval checking the battery level
    this.intervalClock = setInterval(async () => {
      const batteryLevel = await Battery.getBatteryLevelAsync();
      this.setState({ now: moment(), batteryLevel });
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalClock);
  }

  render(): JSX.Element {
    const { now, batteryLevel } = this.state;
    const { phoneNumberSupport, showPhoneNumber, isOnline } = this.props;
    return (
      <View style={styles.blockContent}>
        <Text>
          <Text style={styles.hours}>{now.format("HH:mm")}</Text>
          <Text style={styles.date}>{now.format("DD/MM/YYYY")}</Text>
        </Text>
        {batteryLevel && (
          <View style={styles.textImgContaner}>
            <BatteryImage
              batteryLevel={Number((batteryLevel * 100).toFixed(0))}
            />
            <Text style={styles.textStyle}>
              {`${(batteryLevel * 100).toFixed(0).toString()} %`}
            </Text>
          </View>
        )}
        <Icon
          icon={isOnline ? wifi : nowifi}
          iconStyle={[styles.defaultIconStyle]}
        />
        {showPhoneNumber && (
          <View style={styles.textImgContaner}>
            <Image source={tel} style={{ width: 16, height: 16 }} />
            <Text style={styles.textStyle}>{`${phoneNumberSupport}`}</Text>
          </View>
        )}
        <UserAvatar avatar={placeholderProfil} onPress={() => null} disabled />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultIconStyle: {
    marginHorizontal: 20,
    width: 20,
    height: 20
  },
  blockContent: {
    width: "40%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  textImgContaner: {
    justifyContent: "center",
    flexDirection: "row"
  },
  textStyle: {
    paddingLeft: 6,
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_13,
    color: COLOR_WHITE
  },
  hours: {
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_14
  },
  date: {
    paddingLeft: 6,
    opacity: 0.5,
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_12
  }
});

export default connect((state: RootState) => {
  return {
    isOnline: state.status.isOnline
  };
})(HeaderExamBlockContent);
