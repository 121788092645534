import React from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle
} from "react-native";
import { ipcRenderer } from "electron";
import { ReactMicGold, ReactMicStopEvent } from "../../../libs/react-mic-gold";
import {
  COLOR_BLACK,
  COLOR_BLUE_TESTWE,
  COLOR_RED,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  EACH_SECONDS,
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  IS_PREVIEW,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { micro } from "../../../static/misc/images";
import { formatNumberWithLeadingZero } from "../../../static/misc/utils";
import MultipleExplains from "../molecules/MultipleExplains";
import i18n from "../../services/i18n";

export interface RecordExamProps {
  maxRecordingTime?: number;
  reload?: boolean;
  disabled?: boolean;
  startRecordTime?: number;
  shouldStopRecording?: boolean;
  containerStyle?: StyleProp<ViewStyle>[];
  recordEnd?: () => void;
  recordStarted?: () => void;
  getBlob?: (b64blob: string) => void;
  getAudioRecord?: (blob: Blob) => void;
  toggleAudioProctoring?: () => void;
}

interface RecordExamState {
  showReactMic: boolean;
  isRecording: boolean;
  startRecordTimeState: number | undefined;
  recordsTerminated: boolean;
  minutes: number;
  recordingDuration: number;
  seconds: number;
}

class RecordExam extends React.PureComponent<RecordExamProps, RecordExamState> {
  static defaultProps = { reload: false, disabled: false };

  intervalTimer!: ReturnType<typeof setInterval>;

  intervalStartRecord!: ReturnType<typeof setInterval>;

  constructor(props: RecordExamProps) {
    super(props);
    const { startRecordTime, disabled } = this.props;
    this.state = {
      showReactMic: false,
      isRecording: false,
      startRecordTimeState: startRecordTime,
      recordsTerminated: !!disabled,
      minutes: 0,
      recordingDuration: -3,
      seconds: -3
    };
    this.onStop = this.onStop.bind(this);
  }

  componentDidMount(): void {
    // If not web preview, we set up the interval for automatic starting
    if (!IS_PREVIEW) {
      const { startRecordTime } = this.props;
      const { startRecordTimeState } = this.state;
      if (startRecordTime && startRecordTimeState) {
        let tmp = startRecordTimeState;
        this.intervalStartRecord = setInterval(() => {
          tmp -= 1;
          this.setState({ startRecordTimeState: tmp });
        }, EACH_SECONDS);
        setTimeout(() => {
          this.startRecording();
        }, (startRecordTime - 3) * EACH_SECONDS);
      }
    }
  }

  componentDidUpdate(): void {
    const {
      startRecordTime,
      reload,
      shouldStopRecording,
      disabled,
      recordEnd
    } = this.props;
    const { recordsTerminated } = this.state;
    if (disabled)
      this.setState({
        isRecording: false,
        showReactMic: false,
        recordsTerminated: true
      });

    // Stop event fired when clicking on next ou prev button on exam taking
    if (shouldStopRecording) {
      this.stopRecording();
    } else if (
      reload === true &&
      recordsTerminated === true &&
      recordEnd !== undefined
    ) {
      this.setState({
        isRecording: false,
        startRecordTimeState: startRecordTime,
        recordsTerminated: false,
        minutes: 0,
        recordingDuration: -3,
        seconds: -3
      });
      recordEnd();
    } else if (
      reload === true &&
      recordsTerminated === false &&
      recordEnd !== undefined
    )
      recordEnd();
  }

  componentWillUnmount(): void {
    // Stopping recording and deleting the intervals before unmounting the component
    this.stopRecording();
    clearInterval(this.intervalTimer);
    clearInterval(this.intervalStartRecord);
  }

  // Triggered when the audio stops recording
  onStop(recordedBlob: ReactMicStopEvent): void {
    const { getBlob, getAudioRecord } = this.props;

    if (recordedBlob && getAudioRecord) {
      // Used to listen to the registered audio
      getAudioRecord(recordedBlob.blob);
    }

    if (recordedBlob && recordedBlob.blobURL && getBlob) {
      // Used after recording an audio answer to download the registered audio to local filesystem
      fetch(recordedBlob.blobURL)
        .then((response) => {
          response
            .blob()
            .then((blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64 = reader.result?.toString();
                if (base64) {
                  getBlob(base64);
                }
              };
            })
            .catch((err) => {
              ipcRenderer.send(
                "LOG_ERROR",
                "Could not fetch blobUrl to save the file on filesystem",
                JSON.stringify(err)
              );
            });
        })
        .catch((err) => {
          ipcRenderer.send(
            "LOG_ERROR",
            "Could not fetch blobUrl to save the file on filesystem",
            JSON.stringify(err)
          );
        });
    }
    this.setState({
      showReactMic: false
    });
  }

  // Stops the recording
  stopRecording = (): void => {
    this.setState({ isRecording: false, recordsTerminated: true }, () =>
      clearInterval(this.intervalTimer)
    );
  };

  // Starts the recording
  startRecording = (): void => {
    const { recordStarted } = this.props;

    if (recordStarted) recordStarted();

    this.intervalTimer = setInterval(() => {
      const { seconds, minutes, recordingDuration } = this.state;
      const { maxRecordingTime } = this.props;
      const secondsTmp = seconds;
      const minutesTmp = minutes;
      const tmp = recordingDuration;
      if (secondsTmp === 59) {
        this.setState({
          seconds: 0,
          minutes: minutesTmp + 1,
          recordingDuration: tmp + 1
        });
      } else
        this.setState({
          seconds: secondsTmp + 1,
          recordingDuration: tmp + 1
        });
      if (maxRecordingTime && tmp >= maxRecordingTime - 1) this.stopRecording();
    }, EACH_SECONDS);
    this.setState({ showReactMic: true }, () => {
      setTimeout(() => {
        const { startRecordTimeState } = this.state;
        clearInterval(this.intervalStartRecord);
        this.setState({
          isRecording: true,
          startRecordTimeState:
            startRecordTimeState !== undefined ? 0 : undefined
        });
      }, 2500);
    });
  };

  render(): JSX.Element {
    const {
      maxRecordingTime,
      startRecordTime,
      containerStyle,
      disabled,
      toggleAudioProctoring
    } = this.props;
    const {
      isRecording,
      seconds,
      minutes,
      recordingDuration,
      recordsTerminated,
      startRecordTimeState,
      showReactMic
    } = this.state;

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.recorderContainer}>
          <TouchableOpacity
            disabled={
              !!IS_PREVIEW ||
              (startRecordTime !== undefined &&
                startRecordTimeState !== undefined &&
                startRecordTimeState > 0)
            }
            onPress={() => {
              if (recordsTerminated) {
                this.setState({
                  showReactMic: false,
                  isRecording: false,
                  startRecordTimeState: 0,
                  recordsTerminated: false,
                  minutes: 0,
                  recordingDuration: -3,
                  seconds: -3
                });
              } else if (!showReactMic && !startRecordTime) {
                if (toggleAudioProctoring) {
                  toggleAudioProctoring();
                }
                this.startRecording();
              } else if (showReactMic && recordingDuration > 0) {
                if (toggleAudioProctoring) {
                  toggleAudioProctoring();
                }
                this.stopRecording();
              }
            }}
            style={[styles.contentContainer]}
          >
            <View style={styles.microContainer}>
              <Image source={micro} style={styles.microIcon} />
              {!showReactMic && !recordsTerminated ? (
                <Text style={styles.timerStyle}>
                  {startRecordTime !== undefined
                    ? i18n.t("record.startIn2")
                    : i18n.t("record.start")}
                </Text>
              ) : recordingDuration < 0 && !recordsTerminated ? (
                <Text style={styles.timerStyle}>
                  {i18n.t("record.countdown")}
                  <Text
                    style={{ fontFamily: FONT_GILROY_BOLD, color: COLOR_RED }}
                  >
                    {" "}
                    {Math.abs(recordingDuration)}
                  </Text>{" "}
                  {i18n.t("seconds")}
                </Text>
              ) : (
                !disabled && (
                  <Text style={styles.timerStyle}>
                    {`${seconds < 0 ? "-" : ""}${formatNumberWithLeadingZero(
                      minutes
                    )}:${formatNumberWithLeadingZero(Math.abs(seconds))}`}
                  </Text>
                )
              )}
            </View>
            {recordsTerminated && (
              <Text style={[styles.timerStyle, { paddingTop: 5 }]}>
                {i18n.t("record.over")}
              </Text>
            )}
            {showReactMic && recordingDuration >= 0 && (
              <Text style={[styles.timerStyle]}>{i18n.t("record.stop")}</Text>
            )}
          </TouchableOpacity>
          {showReactMic && (
            <ReactMicGold
              record={isRecording}
              className="sound-wave"
              onStop={this.onStop}
              strokeColor={COLOR_BLACK}
              backgroundColor={COLOR_WHITE}
              sampleRate={48000}
            />
          )}
        </View>
        {!recordsTerminated && (
          <MultipleExplains
            showFirstText={!!startRecordTime}
            firstText={i18n.t("record.startIn")}
            firstJustifiedText={` ${startRecordTimeState} ${i18n.t("seconds")}`}
            showSecondText={!!maxRecordingTime}
            secondText={i18n.t("record.maxRecord")}
            secondJustifiedText={` ${
              maxRecordingTime ? Math.floor(maxRecordingTime).toString() : ""
            } ${i18n.t("seconds")}`}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 300
  },
  recorderContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: PADDING_SIDES * 0.2,
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 5
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  microContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  microIcon: {
    marginTop: PADDING_SIDES * 0.2,
    width: 18,
    height: 30,
    marginRight: 14,
    marginBottom: 15
  },
  timerStyle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    paddingBottom: PADDING_SIDES * 0.1
  }
});

export default RecordExam;
