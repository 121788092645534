import { ExamType } from "../../exams/types/exam";
import {
  UPDATE_TIMER,
  CREATE_TIMER,
  UpdateTimerAction,
  CreateTimerAction,
  TimerTypeEnum,
  TimerType,
  ClearTimerAction,
  CLEAR_TIMER
} from "../types/timer";

export function clearTimer(examId: string): ClearTimerAction {
  return {
    type: CLEAR_TIMER,
    payload: { examId }
  };
}

export function createExamTimer(currentExam: ExamType): CreateTimerAction {
  return {
    type: CREATE_TIMER,
    payload: { currentExam }
  };
}

export function updateTimer(
  examId: string,
  remainingDuration: number,
  timerType: TimerTypeEnum,
  itemId?: string, // have to be setted if timerType one of Question or Exercices
  currentExamPart?: number
): UpdateTimerAction {
  return {
    type: UPDATE_TIMER,
    payload: {
      examId,
      remainingDuration,
      itemId,
      timerType,
      currentExamPart
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getItemTimer(timers: TimerType[], data: any): TimerType {
  const { timerType, examId, elementId, currentExamPart } = data;
  let currentTimer: TimerType = {
    id: examId,
    remainingDuration: 0,
    timerType,
    timers: []
  };
  timers.forEach((element) => {
    if (element.id === examId) {
      if (
        timerType === TimerTypeEnum.EXAM &&
        element.timerType === TimerTypeEnum.EXAM
      ) {
        currentTimer = JSON.parse(JSON.stringify(element));
      } else {
        const partTimers = element.timers;
        partTimers?.forEach((part, index) => {
          if (currentExamPart === index) {
            if (timerType === TimerTypeEnum.PART) {
              currentTimer = JSON.parse(JSON.stringify(part));
            } else {
              part.timers?.forEach((pi) => {
                if (timerType === TimerTypeEnum.EXERCISE) {
                  if (
                    pi.timerType === TimerTypeEnum.EXERCISE &&
                    elementId === pi.id
                  ) {
                    currentTimer = JSON.parse(JSON.stringify(pi));
                  }
                } else if (pi.timerType === TimerTypeEnum.EXERCISE) {
                  pi.timers?.forEach((q) => {
                    if (q.id === elementId) {
                      currentTimer = JSON.parse(JSON.stringify(q));
                    }
                  });
                } else if (
                  pi.id === elementId &&
                  pi.timerType === TimerTypeEnum.QUESTION
                ) {
                  currentTimer = JSON.parse(JSON.stringify(pi));
                }
              });
            }
          }
        });
      }
    }
  });
  return currentTimer;
}
