/* eslint-disable no-underscore-dangle */
import { Dimensions } from "react-native";

import GilroyRegular from "../assets/fonts/Gilroy-Regular.otf";
import GilroyItalic from "../assets/fonts/Gilroy-RegularItalic.otf";
import GilroyLight from "../assets/fonts/Gilroy-Light.otf";
import GilroyMedium from "../assets/fonts/Gilroy-Medium.otf";
import GilroyBold from "../assets/fonts/Gilroy-Bold.otf";
import GilroySemiBold from "../assets/fonts/Gilroy-SemiBold.otf";
import GilroyExtraBold from "../assets/fonts/Gilroy-ExtraBold.otf";
import TungstenCondensedLight from "../assets/fonts/TungstenCondensed-Light.ttf";
import TungstenCondensedMedium from "../assets/fonts/TungstenCondensed-Medium.ttf";
import TungstenCondensedSemiBold from "../assets/fonts/TungstenCondensed-SemiBold.ttf";
import TungstenCondensedBold from "../assets/fonts/TungstenCondensed-Bold.ttf";

export const APP_VERSION = "1.9.21"; // APPVersion

// Declaring env variables to be injected through webpack
declare const _ENV: string;
export const ENV = _ENV;

declare const _APP_NAME: string;
export const APP_NAME = _APP_NAME;

declare const _IS_PREVIEW: boolean;
export const IS_PREVIEW = _IS_PREVIEW;

declare const _IS_WEB_ENABLE: boolean;
export const IS_WEB_ENABLE = _IS_WEB_ENABLE;

declare const _TINY_MCE_PREVIEW: string;
export const TINY_MCE_PREVIEW = _TINY_MCE_PREVIEW;

declare const _SEB_USER_AGENT_KEY: string;
export const SEB_USER_AGENT_KEY = _SEB_USER_AGENT_KEY;

// ENV TYPE
export const PROD = "production";
export const STAGING = "staging";
export const DEV = "development";

// export const PROD_INDEX = 0;
// export const STAGING_INDEX = 1;
// export const DEV_INDEX = 2;

// const ENV_ARRAY = [PROD, STAGING, DEV];

// export const ENV = ENV_ARRAY[DEV_INDEX];

export const PHONE_NUMBERS = {
  "8f0ce3df-9f91-4a8a-9fb7-d45e99e2f1e8": "+33 1 76 31 10 71", // Sésame Prod
  "dbec39a2-bac1-425b-8aa7-6ae9afd0a7ec": "+33 1 76 31 10 71", // Pôle Emploi Prod
  "f62008cb-528d-46dc-b51c-0a394819ddcc": "+33 1 76 31 10 71", // BS Paris Prod school for testing
  "a56648d5-2005-4ee7-abad-e0e66b94bc48": "+33 1 76 31 10 71", // BS Paris Staging school for testing
  "aac523a4-7285-4403-a993-5deaf9cac43e": "+33 1 76 31 10 71", // OnePoint University Dev school for testing
  "80d7f484-e70f-43d3-9166-e708a62eefcf": "+33 1 76 31 10 71", // CDJ University Staging for testing
  default: "+33 1 76 41 14 88"
};

export const PREVIEW_CLIENT_ID = "web-preview";

export const SEB_QUITTING_LINK = "https://testwe.eu/quitSeb";

export const DURATION_TIMEOUT_AXIOS = 100000;

export const CACHED_PROCTORNB_KEY = "CACHED_PROCTORNB_KEY";

export const EMPTY_STRING = "";

export const TRAINING = "training";
export const TRAINING_SPREADSHEET = "trainingSpreadSheet";
export const TRAINING_PDF = "trainingPdf";
export const TRAINING_IMG1 = "trainingImg1";
export const TRAINING_IMG2 = "trainingImg2";
export const TRAINING_IMG3 = "trainingImg3";
export const TRAINING_IMG4 = "trainingImg4";
export const TRAINING_IMG5 = "trainingImg5";
export const TRAINING_IMG6 = "trainingImg6";
export const TRAINING_IMG7 = "trainingImg7";
export const TRAINING_IMG8 = "trainingImg8";
export const TRAINING_IMG9 = "trainingImg9";

export const PROCTOR_END_EXAM = "PROCTOR_END_EXAM";

export const DEFAULT_LANG = "defaultlang";
export const TIMEZONE = "timezone";

export const CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS";

export const WIN = Dimensions.get("window");
export const ASPECT_RATIO = WIN.height / WIN.width;

export const PADDING_SIDES = Math.round((WIN.width / 100) * 4);

// INPUT
export const INPUT_HEIGHT = 50;
export const INPUT_PADDING = 10;

// HEIGHT
export const HEIGHT_HEADER = 80;

// NEXT EXAM STATUS
export const AVAILABLE = "AVAILABLE";
export const UNAVAILABLE = "UNAVAILABLE";

// STUDENT PAPER & EXAMS STATUS
export const CORRECTED = "corrected";
export const TOINTEGRATE = "to_integrate";
// exams
export const FINALIZED = "finalized";
export const IN_CORRECTION = "in_correction";
// student paper
export const NOT_REALIZED = "notrealized";
export const CORRECTION_COMPLETED = "correction_completed";
export const STARTED = "started";
export const SENT = "completed";
export const SYNCRO = "synchronized";
export const NOT_SYNCRO = "notsynchronized";
export const PROCTOR_NOT_SEND = "incomplete";

// exam sharing status
export const CORRECTION_NOT_SHARED = "none";
export const CORRECTION_GRADE_ONLY = "grade_only";
export const CORRECTION_SHARED = "grade_and_correction";

// WIDTH EXAM TAKING CONTENT
export const LARGE_WIDTH = "84.666667%";
export const MEDIUM_WIDTH = "68%";

// WIDTH BOOTSTRAP
export const WIDTH_0_5 = "7%";
export const WIDTH_1 = "8.333333%";
export const WIDTH_1_2 = "10%";
export const WIDTH_1_5 = "14.285715%";
export const WIDTH_2 = "16.666667%";
export const WIDTH_2_5 = "20%";
export const WIDTH_3 = "25%";
export const WIDTH_4 = "33.333333%";
export const WIDTH_5 = "41.666667%";
export const WIDTH_6 = "50%";
export const WIDTH_7 = "58.333333%";
export const WIDTH_8 = "66.666667%";
export const WIDTH_9 = "75%";
export const WIDTH_10 = "83.333333%";
export const WIDTH_11 = "91.666667%";
export const WIDTH_12 = "100%";

// FONTSIZES
export const FONTSIZE_8 = 8;
export const FONTSIZE_9 = 9;
export const FONTSIZE_10 = 10;
export const FONTSIZE_11 = 11;
export const FONTSIZE_12 = 12;
export const FONTSIZE_13 = 13;
export const FONTSIZE_14 = 14;
export const FONTSIZE_16 = 16;
export const FONTSIZE_18 = 18;
export const FONTSIZE_21 = 21;
export const FONTSIZE_24 = 24;
export const FONTSIZE_36 = 36;
export const FONTSIZE_40 = 40;
export const FONTSIZE_55 = 55;
export const FONTSIZE_65 = 65;

// FONTFAMILY
export const FONT_GILROY_REGULAR = "Gilroy-Regular";
export const FONT_GILROY_ITALIC = "Gilroy-Italic";
export const FONT_GILROY_LIGHT = "Gilroy-Light";
export const FONT_GILROY_MEDIUM = "Gilroy-Medium";
export const FONT_GILROY_BOLD = "Gilroy-Bold";
export const FONT_GILROY_SEMI_BOLD = "Gilroy-SemiBold";
export const FONT_GILROY_EXTRA_BOLD = "Gilroy-ExtraBold";
export const FONT_TUNGSTENCONDENSED_LIGHT = "TungstenCondensed-Light";
export const FONT_TUNGSTENCONDENSED_MEDIUM = "TungstenCondensed-Medium";
export const FONT_TUNGSTENCONDENSED_SEMIBOLD = "TungstenCondensed-SemiBold";
export const FONT_TUNGSTENCONDENSED_BOLD = "TungstenCondensed-Bold";

export const CUSTOM_FONTS = {
  "Gilroy-Regular": GilroyRegular,
  "Gilroy-Italic": GilroyItalic,
  "Gilroy-Light": GilroyLight,
  "Gilroy-Medium": GilroyMedium,
  "Gilroy-Bold": GilroyBold,
  "Gilroy-SemiBold": GilroySemiBold,
  "Gilroy-ExtraBold": GilroyExtraBold,
  "TungstenCondensed-Light": TungstenCondensedLight,
  "TungstenCondensed-Medium": TungstenCondensedMedium,
  "TungstenCondensed-SemiBold": TungstenCondensedSemiBold,
  "TungstenCondensed-Bold": TungstenCondensedBold
};

export const MULTIPLE_CHOICE_QUESTION = "multiple_choice_question";
export const SINGLE_CHOICE_QUESTION = "single_choice_question";
export const SHORT_QUESTION = "short_question";
export const TEXT_BLANKS_QUESTION = "text_blanks_question";
export const TEXT_DROPDOWN_QUESTION = "text_dropdown_question";
export const GRID_SINGLE_CHOICE_QUESTION = "grid_single_choice_question";
export const GRID_MULTIPLE_CHOICE_QUESTION = "grid_multiple_choice_question";
export const AUDIO_QUESTION = "audio_question";
export const SPREADSHEET_QUESTION = "spreadsheet_question";
export const DISSERTATION_QUESTION = "dissertation_question";

export const PARTINDEX_TYPE_QUESTION = "question";
export const PARTINDEX_TYPE_EXERCISE = "exercise";

export const NOTIFICATION_END_POINT = HEIGHT_HEADER;
export const DURATION_NOTIFICATION = 5000;
export const DURATION_ANIMATION_NOTIFICATION = 180;

// FILES FOLDER
export const ATTACHED_FILES = "attachedFiles";
export const PROCTOR_FILES = "proctor";
export const EXAM_FILES = "files";
export const EXPORT_FILES = "export";

// MIMETYPE
export const MIME_JPEG = "image/jpeg";
export const MIME_JPG = "image/jpg";
export const MIME_PNG = "image/png";
export const MIME_GIF = "image/gif";
export const MIME_IMAGE = [MIME_JPEG, MIME_JPG, MIME_PNG, MIME_GIF];
export const MIME_PDF = "application/pdf";
export const MIME_CSV = "text/csv";
export const MIME_MS = "application/vnd.ms-excel";
export const MIME_SHEET =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const MIME_SPREADSHEET = [MIME_CSV, MIME_MS, MIME_SHEET];
export const MIME_MP4 = "video/mp4";
export const MIME_AVI = "video/avi";
export const MIME_WEBM = "video/webm";
export const MIME_QUICKTIME = "video/quicktime";
export const MIME_3GP = "video/3gpp";
export const MIME_VIDEO = [
  MIME_MP4,
  MIME_AVI,
  MIME_WEBM,
  MIME_QUICKTIME,
  MIME_3GP
];
export const MIME_OCTETSTREAM = "application/octet-stream";
export const MIME_MP3 = "audio/mp3";
export const MIME_MPEG = "audio/mpeg";
export const MIME_WAV = "audio/wav";
export const MIME_M4A = "audio/x-m4a";
export const MIME_AUDIO = [
  MIME_MP3,
  MIME_MPEG,
  MIME_WAV,
  MIME_M4A,
  MIME_OCTETSTREAM
];
export const MIME_DEFAULT = "application/octet-stream";

// PROCTORING
export const WIDTH_PROCTORING_CAMERA = 0;
export const HEIGHT_PROCTORING_CAMERA = 0;
export const WIDTH_PROCTOR_PICTURE = 1280;
export const HEIGHT_PROCTOR_PICTURE = 1024;
export const PROCTORING_PHOTO_TIMER = 5000;
export const PROCTORING_AUDIO_TIMER = 870000; // 15 minutes - 30 seconds

// ONBOARDING
export const WIDTH_DEFAULT_ONBOARDING = 640;
export const HEIGHT_DEFAULT_ONBOARDING = 480;

// AUTOSAVE TIMER
export const AUTOSAVE_TIMER = 30000;

// EACH SECONDS TIMER
export const EACH_SECONDS = 1000;

// VIDEO CONSTRAINT
export const VIDEO_CONSTRAINTS = {
  width: 255,
  height: 200,
  facingMode: "user"
};

export const DEFAULT_EQUIPMENT_CHECK = {
  syncDate: "",
  webBrowser: "",
  webVersion: "",
  desktopVersion: "",
  osName: "",
  microphone: false,
  sound: false,
  camera: false,
  networkDownload: -1,
  networkUpload: -1
};
