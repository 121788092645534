import React from "react";
import { StyleSheet, View, Text, TouchableWithoutFeedback } from "react-native";
import { ipcRenderer } from "electron";
import { MainProps } from "./types/MainTypes";
import Homepage from "../../atomic/templates/homepage/Homepage";
import HeaderContainer from "../../atomic/organisms/Header/HeaderContainer";
import { DisplayedExamsEnum } from "../exams/ExamsView";
import Loader from "../../atomic/atoms/Loader";
import i18n from "../../services/i18n";
import {
  FONTSIZE_18,
  FONT_GILROY_ITALIC,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import TrainingModal from "../../atomic/organisms/TrainingModal";
import { getTrainingExam } from "../../../static/misc/mockexam";
import CguModalFullscreen from "../../atomic/templates/homepage/CguModalFullscreen";
import { TimeVerificationStatus } from "./types/status";
import ServerTimeSynchronisationDesktopModal from "../../atomic/templates/homepage/ServerTimeSynchronisationDesktopModal";
import ServerTimeSynchronisationWebModal from "../../atomic/templates/homepage/ServerTimeSynchronisationWebModal";

export interface MainViewState {
  showMenu: boolean;
  showCguModal: boolean;
  hasReadCgu: boolean;
  showTrainingModal?: boolean;
}

class MainView extends React.PureComponent<MainProps, MainViewState> {
  constructor(props: MainProps) {
    super(props);
    this.state = {
      showMenu: false,
      showCguModal: false,
      hasReadCgu: false,
      showTrainingModal: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async componentDidMount(): Promise<void> {
    ipcRenderer.send("LOG_INFO", "PAGEMOVE | Home");
  }

  componentDidUpdate(prevProps: MainProps): void {
    const {
      hasTakenTraining,
      showLoader,
      cguAcceptance,
      gettingUserInfo,
      timeVerificationStatus,
      timeVerificationCheck,
      token,
      verifyTimeWithServer
    } = this.props;

    if (hasTakenTraining !== prevProps.hasTakenTraining) {
      ipcRenderer.send(
        "LOG_INFO",
        "Has user taken training yet ?",
        hasTakenTraining === true
      );
    }

    if (!IS_PREVIEW) {
      // if getting user info is over and cguAcceptance is false, then display the modal
      if (prevProps.gettingUserInfo && !gettingUserInfo && !cguAcceptance) {
        ipcRenderer.send(
          "LOG_INFO",
          "User has not accepted CGU yet, will display CGU modal"
        );
        this.setState({
          showCguModal: true
        });
      }

      if (
        (timeVerificationStatus === TimeVerificationStatus.NOT_VERIFIED ||
          timeVerificationStatus === TimeVerificationStatus.ERROR) &&
        !timeVerificationCheck
      ) {
        verifyTimeWithServer(token || "");
      }

      // If it is the first time the student log-in show the training modal
      if (cguAcceptance && !showLoader && !hasTakenTraining) {
        this.setState({ showTrainingModal: true });
      }
    }
  }

  // function which launch the training
  startTraining = (): void => {
    const { navigation, createExamTaking } = this.props;
    const examTraining = getTrainingExam();
    if (createExamTaking) createExamTaking(examTraining);
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "ExamFlow" as any,
          params: {
            screen: "ExamTaking",
            params: { isTraining: true, examId: examTraining.id }
          }
        }
      ]
    });
    ipcRenderer.send("START_EXAM", examTraining.examParams);
  };

  // show taining modal
  toggleTrainingModal(bool: boolean): void {
    this.setState({
      showTrainingModal: bool
    });
  }

  // function to navigate to the exam info page
  navigateToExamInfo(examInfoId: string): void {
    const { navigation } = this.props;
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "ExamFlow" as any,
          params: {
            screen: "ExamInfo",
            params: { examInfoId }
          }
        }
      ]
    });
  }

  toggleMenu(toggle?: boolean): void {
    const { showMenu } = this.state;
    this.setState({
      showMenu: toggle !== undefined ? toggle : !showMenu
    });
  }

  logout(): void {
    const { clearCredentials } = this.props;
    ipcRenderer.send("KC_LOGOUT");
    clearCredentials();
  }

  render(): JSX.Element {
    const {
      token,
      studentID,
      locale,
      givenName,
      familyName,
      isOnline,
      showLoader,
      nextExam,
      navigation,
      nextExams,
      passedExams,
      attachedFiles,
      currentExamTaking,
      timeVerificationStatus,
      timeVerificationCheck,
      currentEstablishment,
      establishments,
      setLocale,
      updateDisplayedExams,
      putUserCguAcceptance,
      getMyPaperBase64,
      verifyTimeWithServer,
      setEstablishment
    } = this.props;
    const {
      showMenu,
      showTrainingModal,
      showCguModal,
      hasReadCgu
    } = this.state;

    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (showMenu) this.toggleMenu(false);
        }}
      >
        <View style={{ flex: 1 }}>
          <CguModalFullscreen
            isVisible={showCguModal}
            rejectCgu={() => this.logout()}
            validateCgu={() =>
              putUserCguAcceptance(token || "", studentID, true)
            }
            hasReadCgu={hasReadCgu}
            setHasReadCgu={() => this.setState({ hasReadCgu: !hasReadCgu })}
            onShowCguModal={(show) => this.setState({ showCguModal: show })}
            setLocale={setLocale}
          />
          <ServerTimeSynchronisationDesktopModal
            givenName={givenName}
            familyName={familyName}
            token={token}
            verifyTimeWithServer={verifyTimeWithServer}
            isVisible={
              timeVerificationStatus !== TimeVerificationStatus.OK &&
              timeVerificationCheck &&
              !showCguModal &&
              isOnline &&
              !IS_WEB_ENABLE
            }
          />
          <ServerTimeSynchronisationWebModal
            givenName={givenName}
            familyName={familyName}
            token={token}
            verifyTimeWithServer={verifyTimeWithServer}
            isVisible={
              timeVerificationStatus !== TimeVerificationStatus.OK &&
              timeVerificationCheck &&
              !showCguModal &&
              isOnline &&
              IS_WEB_ENABLE
            }
          />
          <TrainingModal
            isSynchronizing={showLoader}
            showTrainModal={(bool: boolean) => this.toggleTrainingModal(bool)}
            isVisible={!!showTrainingModal}
            startTraining={this.startTraining}
          />
          <HeaderContainer
            showMenu={showMenu}
            navigation={navigation}
            toggleMenu={this.toggleMenu}
          />
          {(showLoader && (
            <View style={styles.loaderContainer}>
              <Loader iconStyle={styles.loaderStyle} />
              <Text style={styles.textContainer}>
                {IS_PREVIEW ? i18n.t("loadPreview") : i18n.t("load")}
              </Text>
            </View>
          )) || (
            <Homepage
              token={token || ""}
              nextExam={nextExam}
              nextExams={nextExams}
              passedExams={passedExams}
              currentExamTaking={currentExamTaking}
              attachedFiles={attachedFiles}
              navigation={navigation}
              locale={locale}
              givenName={givenName}
              isOnline={isOnline}
              familyName={familyName}
              displaySingleExamInfo={(examInfoId: string) =>
                this.navigateToExamInfo(examInfoId)
              }
              updateDisplayedExams={(examType: DisplayedExamsEnum) =>
                updateDisplayedExams(examType)
              }
              getMyPaperBase64={getMyPaperBase64}
              currentEstablishment={currentEstablishment}
              establishments={establishments}
              setEstablishment={setEstablishment}
              userId={studentID}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  loaderContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  loaderStyle: {
    height: 50,
    width: 50
  },
  textContainer: {
    paddingTop: PADDING_SIDES / 2,
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_ITALIC,
    fontSize: FONTSIZE_18
  }
});

export default MainView;
