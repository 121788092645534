import React from "react";
import {
  StyleSheet,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle
} from "react-native";
import {
  FONTSIZE_18,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import Loader from "../atoms/Loader";
import InputSelector from "../atoms/InputSelector";
import ItemSelector from "../atoms/ItemSelector";

interface SelectorFieldProps {
  label?: string;
  choiceList: Array<string>;
  defaultIndex?: number;
  changeChoice: (index: number) => void;
  loading?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  selectorStyle?: StyleProp<ViewStyle>;
  choiceListStyle?: StyleProp<ViewStyle>;
  choiceItemStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  placeHolder?: string;
  textChevron?: boolean;
  selectorContainerStyle?: StyleProp<ViewStyle>;
  isOpened?: boolean;
  onToggle?: (isOpened: boolean) => void;
}

export interface SelectorFieldState {
  choicesVisible: boolean;
  choiceIndex?: number;
  choiceListPosition: number;
}

class SelectorField extends React.PureComponent<
  SelectorFieldProps,
  SelectorFieldState
> {
  constructor(props: SelectorFieldProps) {
    super(props);
    const { defaultIndex } = this.props;
    this.state = {
      choicesVisible: false,
      choiceIndex: defaultIndex,
      choiceListPosition: 0
    };
  }

  componentDidUpdate(prevProps: SelectorFieldProps): void {
    const { defaultIndex, isOpened } = this.props;
    if (prevProps.defaultIndex !== defaultIndex) {
      this.setState({
        choiceIndex: defaultIndex
      });
    }
    if (prevProps.isOpened !== isOpened) {
      this.setState({
        choicesVisible: isOpened || false
      });
    }
  }

  toggleChoices = (): void => {
    const { choiceList, onToggle } = this.props;
    const { choicesVisible, choiceIndex } = this.state;
    this.setState({
      choicesVisible:
        choiceList.length > (choiceIndex !== undefined ? 1 : 0) &&
        !choicesVisible
    });
    if (onToggle) {
      onToggle(!choicesVisible);
    }
  };

  onChangeChoice = (index: number): void => {
    const { changeChoice } = this.props;
    this.toggleChoices();
    this.setState({
      choiceIndex: index
    });
    changeChoice(index);
  };

  adjustListPosition = (height: number): void => {
    this.setState({ choiceListPosition: height - 2 });
  };

  render(): JSX.Element {
    const {
      label,
      choiceList,
      loading,
      containerStyle,
      placeHolder,
      textChevron,
      selectorStyle,
      choiceListStyle,
      inputStyle,
      textStyle,
      choiceItemStyle,
      selectorContainerStyle
    } = this.props;
    const { choicesVisible, choiceIndex, choiceListPosition } = this.state;
    return (
      <View style={[styles.selectorFieldContainer, containerStyle]}>
        {label && <Text style={styles.titleText}>{label}</Text>}
        {(loading && <Loader iconStyle={styles.loader} />) || (
          <View
            style={[styles.selectorContainer, selectorContainerStyle]}
            onLayout={(event) =>
              this.adjustListPosition(event.nativeEvent.layout.height)
            }
          >
            <InputSelector
              isVisible={choicesVisible}
              choice={
                choiceIndex !== undefined &&
                choiceIndex >= 0 &&
                choiceIndex < choiceList.length
                  ? choiceList[choiceIndex]
                  : placeHolder || ""
              }
              toggleChoices={this.toggleChoices}
              textChevron={textChevron}
              containerStyle={[selectorStyle, inputStyle]}
              textStyle={textStyle}
            />
            {choicesVisible && (
              <ItemSelector
                currentIndex={choiceIndex}
                onChangeChoice={this.onChangeChoice}
                choices={choiceList}
                containerStyle={[
                  selectorStyle,
                  choiceListStyle,
                  { top: choiceListPosition }
                ]}
                textStyle={textStyle}
                choiceStyle={choiceItemStyle}
              />
            )}
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  selectorFieldContainer: {
    flexDirection: "row",
    zIndex: 10,
    alignItems: "center",
    height: 50,
    justifyContent: "space-between"
  },
  selectorContainer: {
    position: "relative",
    width: 250
  },
  titleText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontsize: FONTSIZE_18,
    marginRight: 20
  },
  loader: {
    margin: "auto"
  }
});

export default SelectorField;
