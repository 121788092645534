import { ClearCredentialsActions } from "../../../store/rootreducer";
import { ConnectUserAction } from "../../authentification/types/auth";

export const NEWLANG = "NEWLANG";

export const SET_APP_DATA = "SET_APP_DATA";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const SET_ESTABLISHMENT = "SET_ESTABLISHMENT";
export const SET_ESTABLISHMENT_SUCCESS = "SET_ESTABLISHMENT_SUCCESS";
export const SET_ESTABLISHMENT_FAIL = "SET_ESTABLISHMENT_FAIL";

export const PUT_USER_TRAINING = "PUT_USER";
export const PUT_USER_TRAINING_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_TRAINING_FAIL = "PUT_USER_FAIL";

export const PUT_CGU_ACCEPTANCE = "PUT_CGU_ACCEPTANCE";
export const PUT_CGU_ACCEPTANCE_SUCCESS = "PUT_CGU_SUCCESS";
export const PUT_CGU_ACCEPTANCE_FAIL = "PUT_CGU_FAIL";
export interface GetUserAction {
  type: typeof GET_USER | typeof GET_USER_SUCCESS | typeof GET_USER_FAIL;
  payload: any;
}

export interface PutUserCguAcceptanceAction {
  type:
    | typeof PUT_CGU_ACCEPTANCE
    | typeof PUT_CGU_ACCEPTANCE_SUCCESS
    | typeof PUT_CGU_ACCEPTANCE_FAIL;
  payload: any;
}

export interface PutUserTrainingAction {
  type:
    | typeof PUT_USER_TRAINING
    | typeof PUT_USER_TRAINING_SUCCESS
    | typeof PUT_USER_TRAINING_FAIL;
  payload: any;
}
export interface SetLocale {
  type: typeof NEWLANG;
  payload: string;
}

export interface SetAppDataAction {
  type: typeof SET_APP_DATA;
  payload: any;
}

export interface SetEstablishmentAction {
  type:
    | typeof SET_ESTABLISHMENT
    | typeof SET_ESTABLISHMENT_SUCCESS
    | typeof SET_ESTABLISHMENT_FAIL;
  payload: any;
}

export interface UserState extends DetailedUser {
  locale: string;
  appDataPath: string | null;
  logsPath: string | null;
  appVersion: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export type SchoolClassesType = {
  id: string;
  name: string;
};

export type EstablishmentsType = {
  name: string;
  id: string;
  requireSafeExamBrowser: boolean;
};

export interface DetailedUser extends User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  roles: string[];
  avatar: null | string;
  hasExtraTime: boolean | undefined;
  studentNumber: string;
  locale: string;
  hasTakenTraining: boolean;
  takenTrainingDate: string;
  blocked: boolean | undefined;
  schoolClasses: SchoolClassesType[];
  establishments: EstablishmentsType[];
  currentEstablishment: EstablishmentsType | undefined;
  createdAt: string;
  updatedAt: string;
  cguAcceptance: boolean;
  osPlatform: string;
  osRelease: string;
  equipmentId: string;
}

export type UserAction =
  | SetLocale
  | SetAppDataAction
  | PutUserCguAcceptanceAction
  | ClearCredentialsActions
  | GetUserAction
  | ConnectUserAction
  | PutUserTrainingAction
  | SetEstablishmentAction;
