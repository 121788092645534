/* eslint-disable no-await-in-loop */
import React from "react";
import { StyleSheet, ScrollView, Platform } from "react-native";
import moment from "moment";
import fse from "fs-extra";
import path from "path";
import { ipcRenderer } from "electron";
import CryptoJS from "crypto-js";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ExamType } from "../../modules/exams/types/exam";
import { COLOR_WHITE } from "../../../static/misc/colors";
import {
  ENV,
  DEV,
  EACH_SECONDS,
  ATTACHED_FILES,
  MIME_SPREADSHEET,
  EXAM_FILES,
  IS_WEB_ENABLE
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import {
  enigma,
  displayStaticTimer,
  shuffle,
  b64toblob
} from "../../../static/misc/utils";

import { StackNavigatorProp } from "../../router/StackNavigator";
import {
  CreatePublicKeyAction,
  GetExamPublicKeyAction,
  PublicKeyType
} from "../../modules/exams/types/publickeys";
import { GetErrorAction } from "../../modules/main/types/error";
import TokenModal from "./TokenModal";
import {
  CreateExamTakingAction,
  EndExamAction,
  StartExamAction
} from "../../modules/examTaking/types/examTaking";
import {
  CreateArchiveBeforeUploadAction,
  MediaObjectType,
  UpdateMediaObjectAction
} from "../../modules/exams/types/attachedfiles";
import { StudentPaperType } from "../../modules/examTaking/types/studentPaper";
import { findFileOnFs } from "../../../static/fileutils";
import { RootState } from "../../store/rootreducer";
import uncipherMedia from "../../services/web/cipher";
import ExamInfoOnboarding from "./ExamInfoOnboarding";
import { CreateTimerAction } from "../../modules/examTaking/types/timer";
import { createExamTimer } from "../../modules/examTaking/actions/timer";
import { toggleExamStarting } from "../../modules/main/actions/status";
import { ToggleExamStartingAction } from "../../modules/main/types/status";
import LoadingModal from "./LoadingModal";
import { hasStudentPaper } from "../../modules/exams/utils";

export interface ExamInfoProps {
  token: string | undefined;
  gettingExam: boolean;
  gettingPublicKey: boolean;
  userId: string;
  appDataPath: string;
  currentAttachedFiles: MediaObjectType | undefined;
  isOnline: boolean;
  currentExam: ExamType;
  publicKeys: PublicKeyType[];
  allExamTaking: ExamType[] | undefined;
  currentExamTaking: ExamType | undefined;
  studentPapers: StudentPaperType[];
  navigation: StackNavigatorProp;
  isExamLoading: boolean;
  getExamPublicKey: (examId: string, token: string) => GetExamPublicKeyAction;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  createPublicKey: (examId: string, publicKey: string) => CreatePublicKeyAction;
  updateMediaObject: (
    examId: string,
    mediaObjectId: string,
    mimeType: string | undefined,
    isUnciphered: boolean,
    blobUrl?: string
  ) => UpdateMediaObjectAction;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  createExamTaking: (exam: ExamType) => CreateExamTakingAction;
  createTimer: (exam: ExamType) => CreateTimerAction;
  toggleExamStart: (val: boolean) => ToggleExamStartingAction;
  startExamTaking: () => StartExamAction;
  endExamTaking: () => EndExamAction;
}

export interface ExamInfoState {
  hasAgreed: boolean;
  isExamAvailable: boolean;
  isGoingBackToExam: boolean;
  displayAvailabilityTag: boolean;
  delayExpired: boolean;
  tokenModalVisible: boolean;
  isOnboardingStarted: boolean;
  examLaunching: boolean;
  wentToExam: boolean;
}

class ExamInfo extends React.Component<ExamInfoProps, ExamInfoState> {
  intervalTimer!: ReturnType<typeof setInterval>;

  constructor(props: ExamInfoProps) {
    super(props);

    const examStartingDate = new Date(props.currentExam.startDate);

    this.state = {
      hasAgreed: false,
      examLaunching: false,
      tokenModalVisible: false,
      isGoingBackToExam:
        moment(
          examStartingDate.getTime() +
            props.currentExam.timeZeroSecond * 1000 +
            props.currentExam.duration * 1000
        ).isSameOrAfter(new Date().getTime()) &&
        hasStudentPaper(
          props.currentExam.id,
          props.userId,
          props.studentPapers
        ),
      isOnboardingStarted: false,
      isExamAvailable: false,
      displayAvailabilityTag: false,
      delayExpired: false,
      wentToExam: false
    };

    this.updateAgreement = this.updateAgreement.bind(this);
    this.startExam = this.startExam.bind(this);
  }

  componentDidMount(): void {
    const {
      currentExam,
      startExamTaking,
      allExamTaking,
      studentPapers,
      userId
    } = this.props;
    startExamTaking();
    ipcRenderer.send("PAUSE_UPDATE_SERVICE");
    // TODO Create a global method to do this for all 4 needs
    let isAlreadyTakingExam = false;
    isAlreadyTakingExam = allExamTaking
      ? allExamTaking.filter((examTaking) => {
          return examTaking.id === currentExam.id;
        }).length > 0
      : false;
    isAlreadyTakingExam = !isAlreadyTakingExam
      ? studentPapers.filter((es: StudentPaperType) => {
          return es.examId === currentExam.id;
        }).length > 0
      : true;

    // Setting the interval to check every seconds if an exam becomes available
    this.intervalTimer = setInterval(() => {
      const { isOnboardingStarted } = this.state;
      if (currentExam.startDate !== undefined) {
        const today = new Date();
        const examDate = new Date(currentExam.startDate);

        const canJoin =
          moment(today.getTime()).isSameOrAfter(examDate.getTime()) &&
          moment(today.getTime()).isSameOrBefore(
            examDate.getTime() + currentExam.timeZeroSecond * 1000
          );
        const canRejoin =
          isAlreadyTakingExam &&
          moment(
            examDate.getTime() +
              currentExam.timeZeroSecond * 1000 +
              currentExam.duration * 1000
          ).isSameOrAfter(new Date().getTime()) &&
          hasStudentPaper(currentExam.id, userId, studentPapers);
        const isLate = moment(today.getTime()).isAfter(
          examDate.getTime() + currentExam.timeZeroSecond * 1000
        );

        if (currentExam?.examParams?.proctoring) {
          if (canRejoin) {
            this.setState({
              isGoingBackToExam: true,
              hasAgreed: true,
              isExamAvailable: true
            });
          } else if (canJoin) {
            this.setState({
              isExamAvailable: true,
              displayAvailabilityTag: true
            });
          } else if (
            isOnboardingStarted && // if student started onboarding onTime he have 5 more minutes to start exam (if onboarding crash for example)
            moment(today.getTime()).isSameOrBefore(
              moment(examDate.getTime())
                .add(currentExam.timeZeroSecond, "seconds")
                .add(5, "minutes")
                .toDate()
            )
          ) {
            this.setState({
              isExamAvailable: true,
              displayAvailabilityTag: false
            });
          } else if (isLate) {
            this.setState({
              isExamAvailable: false,
              displayAvailabilityTag: false,
              delayExpired: true
            });
          }
        } else if (canRejoin) {
          this.setState({
            isGoingBackToExam: true,
            hasAgreed: true,
            isExamAvailable: true
          });
        } else if (canJoin) {
          this.setState({
            isExamAvailable: true,
            displayAvailabilityTag: true
          });
        } else if (isLate) {
          this.setState({
            isExamAvailable: false,
            displayAvailabilityTag: false,
            delayExpired: true
          });
        }
      }
    }, EACH_SECONDS);
  }

  // We want the component to re-render only for the following reasons
  shouldComponentUpdate(
    nextProps: ExamInfoProps,
    nextState: ExamInfoState
  ): boolean {
    const {
      delayExpired,
      displayAvailabilityTag,
      isExamAvailable,
      examLaunching,
      tokenModalVisible,
      hasAgreed,
      isGoingBackToExam
    } = this.state;
    const {
      gettingExam,
      gettingPublicKey,
      currentAttachedFiles,
      isOnline,
      currentExam,
      studentPapers,
      userId
    } = this.props;

    return (
      // props
      isOnline !== nextProps.isOnline ||
      gettingExam !== nextProps.gettingExam ||
      gettingPublicKey !== nextProps.gettingPublicKey ||
      !_.isEqual(currentAttachedFiles, nextProps.currentAttachedFiles) ||
      currentExam !== nextProps.currentExam ||
      studentPapers !== nextProps.studentPapers ||
      userId !== nextProps.userId ||
      // state
      hasAgreed !== nextState.hasAgreed ||
      delayExpired !== nextState.delayExpired ||
      displayAvailabilityTag !== nextState.displayAvailabilityTag ||
      isExamAvailable !== nextState.isExamAvailable ||
      examLaunching !== nextState.examLaunching ||
      tokenModalVisible !== nextState.tokenModalVisible ||
      !_.isEqual(currentAttachedFiles, nextProps.currentAttachedFiles) ||
      isGoingBackToExam !== nextState.isGoingBackToExam
    );
  }

  async componentDidUpdate(prevProps: ExamInfoProps): Promise<void> {
    const {
      gettingExam,
      gettingPublicKey,
      currentExam,
      publicKeys,
      toggleExamStart,
      getError
    } = this.props;
    const { isGoingBackToExam } = this.state;

    if (prevProps.gettingExam !== gettingExam && gettingExam === false) {
      // If no public key after getting the exam, then it is not the right hour
      if (publicKeys && publicKeys.length > 0) {
        const indexPublicKey = publicKeys.findIndex(
          (item) => item.examId === currentExam.id
        );
        // Deciphering ONLINE
        if (indexPublicKey >= 0) {
          toggleExamStart(true);
          await this.canExamBeLaunched(
            currentExam,
            publicKeys[indexPublicKey].publicKey
          );
        } else if (
          !currentExam.examParams?.proctoring &&
          !currentExam.examParams?.proctoringLive
        ) {
          // else, toggling the token modal
          this.setState({ tokenModalVisible: true });
        } else if (
          prevProps.gettingPublicKey !== gettingPublicKey &&
          gettingPublicKey === false
        ) {
          getError(i18n.t("errors.publicKey"), false);
        }
      } else if (
        (ENV === DEV && currentExam.examParams?.proctoring) ||
        !currentExam.examParams?.proctoring
      ) {
        // if no proctoring, token modal by default
        this.setState({ tokenModalVisible: true });
      } else if (currentExam.examParams?.proctoring) {
        if (
          (!isGoingBackToExam &&
            moment().isBetween(
              currentExam.startDate,
              moment(currentExam.startDate)
                .add(currentExam.timeZeroSecond, "seconds")
                .toDate()
            )) ||
          (isGoingBackToExam &&
            moment().isBetween(
              currentExam.startDate,
              moment(currentExam.startDate)
                .add(currentExam.duration, "seconds")
                .add(currentExam.timeZeroSecond, "seconds")
                .toDate()
            ))
        ) {
          this.onBoardingExam();
        }
        // if proctoring and time check not good, display an error because cannot access the exam before the right hour
        getError(i18n.t("errors.proctorNot"), false);
      } else if (
        prevProps.gettingPublicKey !== gettingPublicKey &&
        gettingPublicKey === false
      ) {
        getError(i18n.t("errors.publicKey"), false);
      }
    }
  }

  componentWillUnmount(): void {
    const { wentToExam } = this.state;
    const { endExamTaking, toggleExamStart } = this.props;
    // removing the interval before unmounting
    toggleExamStart(false);
    clearInterval(this.intervalTimer);
    if (!wentToExam) {
      endExamTaking();
      ipcRenderer.send("RESUME_UPDATE_SERVICE");
    }
  }

  // Triggered when onboarding is complete
  async onBoardingExam(): Promise<boolean> {
    const {
      currentExamTaking,
      studentPapers,
      publicKeys,
      toggleExamStart
    } = this.props;

    this.setState({ isOnboardingStarted: true });
    // if there is already an exam taking and a student paper with the corresponding exam id and no end date,
    // redirecting user directly to the exam
    const publicKey = publicKeys.find(
      (pk) => currentExamTaking && pk.examId === currentExamTaking.id
    );
    if (
      currentExamTaking &&
      publicKey &&
      studentPapers.some(
        (sp) => sp.examId === currentExamTaking.id && sp.endDate === null
      )
    ) {
      toggleExamStart(true);
      await this.canExamBeLaunched(currentExamTaking, publicKey?.publicKey);
    } else if (!currentExamTaking) {
      return true;
    } else {
      // If no test needed, starting the exam
      toggleExamStart(true);
      this.startExam();
    }

    return false;
  }

  // Ensuring the randomisation on questions
  shuffleExam(examTaking: ExamType): ExamType {
    const examTakingTmp = examTaking;
    if (typeof examTakingTmp.examParts !== "string") {
      examTakingTmp.examParts = examTakingTmp.examParts?.map((item) => {
        if (item.randomPartIndexes === true)
          shuffle(item.partIndexes, item.partIndexes.length - 1);
        item.partIndexes.map((partIndex) => {
          if (partIndex.exercise && partIndex.exercise.randomQuestions === true)
            shuffle(
              partIndex.exercise.questions,
              partIndex.exercise.questions.length - 1
            );
          return partIndex;
        });
        return item;
      });
    }
    return examTakingTmp;
  }

  // Updating the exam taking data to add bookmark boolean for questions if not already present
  bookMarkExam(examTaking: ExamType): ExamType {
    const examTakingTmp = examTaking;
    if (typeof examTakingTmp.examParts !== "string") {
      examTakingTmp.examParts = examTakingTmp.examParts?.map((item) => {
        item.partIndexes.map((partIndex) => {
          const partIndexTmp = { ...partIndex };
          if (partIndexTmp.exercise !== undefined) {
            partIndexTmp.exercise.questions.map((_question, questionIndex) => {
              if (
                partIndexTmp.exercise &&
                !_.has(
                  partIndexTmp.exercise.questions[questionIndex],
                  "isBookMarked"
                )
              )
                partIndexTmp.exercise.questions[
                  questionIndex
                ].isBookMarked = false;
              return partIndexTmp;
            });
          }
          if (
            partIndexTmp.question !== undefined &&
            !_.has(partIndexTmp.question, "isBookMarked")
          )
            partIndexTmp.question.isBookMarked = false;
          return partIndexTmp;
        });
        return item;
      });
    }
    return examTakingTmp;
  }

  // Starting the exam
  async launchExam(publicKey: string): Promise<void> {
    const {
      createExamTaking,
      createTimer,
      getError,
      toggleExamStart
    } = this.props;
    this.setState({ examLaunching: true, tokenModalVisible: false });

    try {
      const examDecrypt = await this.examDecryption(publicKey);
      if (
        examDecrypt !== undefined &&
        examDecrypt.examParts !== undefined &&
        typeof examDecrypt.examParts !== "string"
      ) {
        await this.mediasDecryption(publicKey);
        this.readMedias();
        const examBookMarked = this.bookMarkExam(examDecrypt);
        const examTaking = this.shuffleExam(examBookMarked);
        // Storing the exam taking data in the store
        createExamTaking(examTaking);
        createTimer(examTaking);
        this.setState({ examLaunching: false });
        // Navigating to the exam taking page
        // Notifying the main process to handle screen and process killer things
        this.navigateToExam(examTaking);
      }
    } catch (err: any) {
      ipcRenderer.send(
        "LOG_ERROR",
        "Could not launch exam",
        JSON.stringify(err)
      );
      this.setState({ examLaunching: false }, () => {
        toggleExamStart(false);
        getError(err, false);
      });
    }
  }

  navigateToExam(examTaking: ExamType): void {
    const { navigation, getError } = this.props;
    ipcRenderer.invoke("START_EXAM", examTaking.examParams).then((result) => {
      const res = JSON.parse(result);
      if (res.type === "START_EXAM_OK") {
        this.setState({ wentToExam: true }, () => {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: "ExamFlow" as any,
                params: {
                  screen: "ExamTaking",
                  params: {
                    examId: examTaking.id
                  }
                }
              }
            ]
          });
        });
      } else {
        this.setState(
          { examLaunching: false, tokenModalVisible: false },
          () => {
            getError(
              res.args
                ? i18n.t("errors.processNotKilled", res.args)
                : i18n.t("errors.unknownError"),
              false
            );
          }
        );
      }
    });
  }

  // Decryptin the exam data
  examDecryption(publicKey: string): ExamType | undefined {
    const { currentExam, createPublicKey } = this.props;
    const examTakingTmp = { ...currentExam };
    if (currentExam.examParts && typeof currentExam.examParts === "string") {
      const explodedStr = currentExam.examParts.split("|");
      if (explodedStr.length === 3) {
        try {
          if (currentExam.proctorCode) {
            // UNCIPHERING PROCTOR CODE
            const explodedStrProctor = currentExam.proctorCode.split("|");
            const proctorDeciphered = enigma(publicKey, explodedStrProctor);
            // Check if it is right deciphered
            if (
              CryptoJS.HmacSHA256(
                CryptoJS.enc.Base64.parse(explodedStrProctor[0]),
                CryptoJS.enc.Hex.parse(CryptoJS.SHA256(publicKey).toString())
              ).toString() === atob(explodedStrProctor[1])
            )
              examTakingTmp.proctorCode = proctorDeciphered;
          }
          const examDeciphered = enigma(publicKey, explodedStr);
          // Check if it is right deciphered
          if (
            CryptoJS.HmacSHA256(
              CryptoJS.enc.Base64.parse(explodedStr[0]),
              CryptoJS.enc.Hex.parse(CryptoJS.SHA256(publicKey).toString())
            ).toString() === atob(explodedStr[1])
          ) {
            this.setState({ tokenModalVisible: false });
            createPublicKey(currentExam.id, publicKey);

            // LAUNCH EXAM
            examTakingTmp.examParts = JSON.parse(examDeciphered);
            return examTakingTmp;
          }
          throw i18n.t("errors.cipher");
        } catch {
          throw i18n.t("errors.keyError");
        }
      } else throw i18n.t("errors.cipher");
    }
    // if not string then exam already deciphered
    return examTakingTmp;
  }

  // Toggle agreement boolean to enable/disable the start exam button
  updateAgreement(): void {
    const { hasAgreed } = this.state;

    this.setState({
      hasAgreed: !hasAgreed
    });
  }

  // Starts the exam
  startExam(): void {
    const { token, getExamPublicKey, isOnline, currentExam } = this.props;
    if (
      !currentExam.examParams?.proctoring &&
      !currentExam.examParams?.proctoringLive
    ) {
      this.setState({ tokenModalVisible: true });
    } else if (token && isOnline) {
      getExamPublicKey(currentExam.id, token);
    }
  }

  // Run event to check if multi screen
  // creating listener only once
  // isMultiScreen(): Promise<any> {
  //   return new Promise((resolve) => {
  //     ipcRenderer.once("IS_MULTI_SCREEN_RESPOND", (event, arg) => {
  //       if (ENV !== DEV) {
  //         resolve(arg);
  //       } else {
  //         resolve([]);
  //       }
  //     });
  //     ipcRenderer.send("IS_MULTI_SCREEN");
  //   });
  // }

  // Run event to ensure a security software is running - otherwise the exam cannot start
  isWindowExecExist(): Promise<any> {
    return new Promise((resolve) => {
      ipcRenderer.once("IS_EXEC_WIN_AVAILABLE_RESPOND", (event, arg) => {
        resolve(arg);
      });
      ipcRenderer.send("IS_EXEC_WIN_AVAILABLE");
    });
  }

  // Triggered to ensure the exam can start properly
  async canExamBeLaunched(
    currentExam: ExamType,
    publicKey: string
  ): Promise<void> {
    const { getError } = this.props;

    // let respDualScreen = [];
    // if (!IS_WEB_ENABLE) respDualScreen = await this.isMultiScreen();

    let respIsWindowExec = true;
    if (Platform.OS === "windows")
      respIsWindowExec = await this.isWindowExecExist();

    // if (respDualScreen.length > 1 && !currentExam.examParams?.openBook) {
    //   getError(i18n.t("errors.dualScreen"), false);
    // } else
    if (!respIsWindowExec && !currentExam.examParams?.openBook) {
      getError(i18n.t("errors.setupChanged"), false);
    } else {
      this.launchExam(publicKey);
    }
  }

  readMedias(): void {
    const {
      currentExam,
      currentAttachedFiles,
      appDataPath,
      updateMediaObject
    } = this.props;

    if (currentAttachedFiles && currentAttachedFiles.medias.length > 0) {
      for (let i = 0; i < currentAttachedFiles.medias.length; i++) {
        const media = currentAttachedFiles.medias[i];

        // even if blobUrl was filled in in a previous run of the application, must be renewed if app reloads
        // otherwise blobUrl is invalidated
        if (media.isDownloaded && !media.isErrored && media.isUnciphered) {
          try {
            let resp: string | undefined;
            if (!IS_WEB_ENABLE) {
              // must be sync because not working otherwise
              resp = fse.readFileSync(
                path.join(appDataPath, ATTACHED_FILES, media.mediaId),
                "utf-8"
              );
            } else {
              resp = media.data;
            }

            if (resp) {
              // if not a spreadsheet, then we need the blob url
              if (
                !_.includes(MIME_SPREADSHEET, media.mimeType) ||
                IS_WEB_ENABLE
              ) {
                const blob = b64toblob(resp, media.mimeType);
                const blobUrl = URL.createObjectURL(blob);
                ipcRenderer.send(
                  "LOG_INFO",
                  `File ${media.mediaId} of type ${media.mimeType} was read and converted to blobUrl for media reader`
                );

                updateMediaObject(
                  currentExam.id,
                  media.mediaId,
                  media.mimeType,
                  true,
                  blobUrl
                );
              } else {
                // checking if exam files already has a file with this media id in name
                // if yes, it means the user has already started the exam and there is already
                // a potentially modified spreadsheet file so we use it as source instead of original file
                let uri = findFileOnFs(
                  currentAttachedFiles.examId,
                  media.mediaId,
                  appDataPath,
                  EXAM_FILES
                );
                if (!uri) {
                  // otherwise, using the original file
                  uri = path.join(appDataPath, ATTACHED_FILES, media.mediaId);
                }
                ipcRenderer.send(
                  "LOG_INFO",
                  `File ${media.mediaId} of type ${media.mimeType} was read and its URI was retrieved for spreadsheet display`
                );

                // updating the media object with the file uri instead of a blob url
                updateMediaObject(
                  currentExam.id,
                  media.mediaId,
                  media.mimeType,
                  true,
                  uri
                );
              }
            } else {
              // TODO delete file
              throw i18n.t("errors.media");
            }
          } catch (err) {
            // TODO delete file
            ipcRenderer.send(
              "LOG_ERROR",
              `Could not read file ${media.mediaId}`,
              JSON.stringify(err)
            );
            throw err;
          }
        } else {
          ipcRenderer.send(
            "LOG_INFO",
            `Media ${media.mediaId} might be errored, not downloaded or not unciphered and could not be read`,
            JSON.stringify(media)
          );
        }
      }
    }
  }

  // Triggered to decrypt medias
  async mediasDecryption(publicKey: string): Promise<void> {
    const { currentAttachedFiles, updateMediaObject, currentExam } = this.props;

    if (currentAttachedFiles && currentAttachedFiles.medias.length > 0) {
      for (let i = 0; i < currentAttachedFiles.medias.length; i++) {
        const media = currentAttachedFiles.medias[i];
        if (media.isDownloaded && !media.isErrored && !media.isUnciphered) {
          let res: any = true;
          if (!IS_WEB_ENABLE) {
            res = await ipcRenderer.invoke("UNCIPHER_MEDIA", {
              mediaId: media.mediaId,
              publicKey,
              isSpreadsheet: _.includes(MIME_SPREADSHEET, media.mimeType)
            });
          } else if (media.data) {
            res = await uncipherMedia(media.data, publicKey);
            media.data = res;
          }
          // if file could be unciphered, then reading it to generate a blobUrl to store in store
          if (res) {
            updateMediaObject(
              currentExam.id,
              media.mediaId,
              media.mimeType,
              true
            );
          } else {
            // TODO delete file
            throw i18n.t("errors.media");
          }
        } else if (
          !media.isDownloaded ||
          (media.isErrored && !media.isUnciphered)
        ) {
          // TODO delete file
          throw i18n.t("errors.mediaNotDeciphered", [media.mediaId]);
        } else if (media.isUnciphered) {
          ipcRenderer.send(
            "LOG_INFO",
            `Media ${media.mediaId} is already unciphered`
          );
        } else {
          ipcRenderer.send(
            "LOG_INFO",
            `Media ${media.mediaId} could not be deciphered`,
            JSON.stringify(media)
          );
        }
      }
    }
  }

  render(): JSX.Element {
    const {
      currentExam,
      navigation,
      appDataPath,
      isOnline,
      userId,
      token,
      currentAttachedFiles,
      isExamLoading,
      getError,
      createArchiveBeforeUpload,
      toggleExamStart
    } = this.props;
    const {
      hasAgreed,
      tokenModalVisible,
      isExamAvailable,
      displayAvailabilityTag,
      delayExpired,
      examLaunching,
      isGoingBackToExam,
      isOnboardingStarted
    } = this.state;

    const currentExamTmp = { ...currentExam };

    const questionTypes: string[] = [];
    currentExamTmp.questionTypes.forEach((qtype) => {
      questionTypes.push(i18n.t(`questionTypes.${qtype}`));
    });

    const downloadedFiles =
      currentAttachedFiles && currentAttachedFiles.medias.length > 0
        ? currentAttachedFiles.medias.filter((file) => file.isDownloaded).length
        : 0;

    return (
      <ScrollView
        style={styles.viewStyle}
        contentContainerStyle={styles.contentContainer}
      >
        <ExamInfoOnboarding
          appDataPath={appDataPath}
          currentExam={currentExamTmp}
          delayExpired={delayExpired}
          displayAvailabilityTag={displayAvailabilityTag}
          downloadedFiles={downloadedFiles}
          hasAgreed={hasAgreed}
          isExamAvailable={isExamAvailable}
          isGoingBackToExam={isGoingBackToExam}
          isOnboardingStarted={isOnboardingStarted}
          isOnline={isOnline}
          navigation={navigation}
          token={token}
          userId={userId}
          tokenModalVisible={tokenModalVisible}
          currentAttachedFiles={currentAttachedFiles}
          createArchiveBeforeUpload={createArchiveBeforeUpload}
          getError={getError}
          onBoardingExam={() => this.onBoardingExam()}
          updateAgreement={() => this.updateAgreement()}
          startExam={() => this.startExam()}
        />
        <TokenModal
          onPress={async (offlinePublicKey) => {
            await this.canExamBeLaunched(currentExam, offlinePublicKey);
          }}
          schoolSubject={currentExam.name || ""}
          isVisible={tokenModalVisible}
          examLaunching={examLaunching}
          isTimer={currentExamTmp.hasTimer}
          showTokenModal={() => {
            toggleExamStart(false);
            this.setState({
              tokenModalVisible: false
            });
          }}
          examTime={`${
            currentExamTmp.assessmentType?.name || ""
          } - ${displayStaticTimer(currentExamTmp.duration)}`}
        />

        <LoadingModal
          isVisible={isExamLoading && !tokenModalVisible}
          loadingMessage={i18n.t("loadingExamModal")}
        />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    flex: 1,
    width: "100%"
  },
  contentContainer: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: COLOR_WHITE
  }
});

export default connect(
  (state: RootState) => {
    return {
      isExamLoading: state.status.isExamStarting,
      allExamTaking: state.examTaking.exams
    };
  },
  (dispatch: Dispatch) => {
    return {
      ...bindActionCreators(
        { createTimer: createExamTimer, toggleExamStart: toggleExamStarting },
        dispatch
      )
    };
  }
)(ExamInfo);
