/* eslint-disable no-loop-func */
import { ipcRenderer } from "electron";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import {
  COLOR_BLUE,
  COLOR_BLUE_LIGHTER,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_36,
  FONT_GILROY_EXTRA_BOLD,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  OnboardingSubStepperOption,
  ONBOARDING_ASSETS,
  ONBOARDING_CONNECTION,
  ONBOARDING_FACE_PHOTO,
  ONBOARDING_ID_PHOTO,
  ONBOARDING_MICROPHONE,
  ONBOARDING_ORDERED_STEPS,
  ONBOARDING_PERMISSIONS,
  ONBOARDING_VIDEO_360
} from "../../../static/misc/onboarding/onboardingsteps";
import { displayStaticTimer } from "../../../static/misc/utils";
import {
  CreateArchiveBeforeUploadAction,
  MediaObjectType
} from "../../modules/exams/types/attachedfiles";
import { ExamOnboardingStep, ExamType } from "../../modules/exams/types/exam";
import { GetErrorAction } from "../../modules/main/types/error";
import { UserState } from "../../modules/main/types/user";
import { StackNavigatorProp } from "../../router/StackNavigator";
import { examNeedsCamera, examNeedsMicrophone } from "../../services/equipment";
import i18n from "../../services/i18n";
import { checkMediaPermissions } from "../../services/permissions";
import { RootState } from "../../store/rootreducer";
import ExamInfoDetails from "../molecules/ExamInfoDetails";
import ExamInfoTitle from "../molecules/ExamInfoTitle";
import ExamTakingAllRules from "./ExamTakingAllRules";
import OnboardingStepper from "./OnboardingStepper";

interface ExamInfoOnboardingProps {
  appDataPath: string;
  currentUser: UserState;
  currentExam: ExamType;
  delayExpired: boolean;
  displayAvailabilityTag: boolean;
  hasAgreed: boolean;
  isExamAvailable: boolean;
  isGoingBackToExam: boolean;
  isOnboardingStarted: boolean;
  isOnline: boolean;
  navigation: StackNavigatorProp;
  token: string | undefined;
  tokenModalVisible: boolean;
  userId: string;
  currentAttachedFiles: MediaObjectType | undefined;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  onBoardingExam: () => Promise<boolean>;
  startExam: () => void;
  updateAgreement: () => void;
}

interface ExamInfoOnboardingState {
  currentStep: number;
  currentOnboardingStep: number;
}

class ExamInfoOnboarding extends React.PureComponent<
  ExamInfoOnboardingProps,
  ExamInfoOnboardingState
> {
  checkingPermissions = false;

  previousStep = -1;

  previousOnboardingStep = -1;

  constructor(props: ExamInfoOnboardingProps) {
    super(props);

    const steps = this.getCurrentOnboardingStep(0, 0);

    this.state = {
      currentStep: steps[0],
      currentOnboardingStep: steps[1]
    };
  }

  getCurrentOnboardingStep(nextStep: number, currentStep: number): number[] {
    if (this.checkingPermissions) {
      this.checkingPermissions = false;
      return [this.previousStep, this.previousOnboardingStep];
    }
    const { currentExam } = this.props;

    let newStep = nextStep;
    let currentOnboardingStep = -1;

    while (currentOnboardingStep === -1 && newStep >= 0) {
      currentOnboardingStep =
        currentExam.onboarding?.steps.findIndex((step: ExamOnboardingStep) => {
          const onboardingOrderedStepType =
            ONBOARDING_ORDERED_STEPS[newStep].type;
          const isSameType = step.type === onboardingOrderedStepType;

          const videoIndex = ONBOARDING_ASSETS.findIndex(
            (vid) => vid.demoVideo === step.demoVideo
          );

          const isSameVideoIndex =
            videoIndex > -1
              ? ONBOARDING_ORDERED_STEPS[newStep].videoIndex === videoIndex
              : true;

          return isSameType && isSameVideoIndex;
        }) ?? -1;
      if (currentOnboardingStep === -1) {
        if (currentStep <= newStep) newStep++;
        else newStep--;
      }
      if (
        !IS_WEB_ENABLE &&
        !IS_PREVIEW &&
        ONBOARDING_ORDERED_STEPS[newStep].type === ONBOARDING_CONNECTION
      ) {
        currentOnboardingStep = -1;
        if (currentStep <= newStep) newStep++;
        else newStep--;
      }
    }

    if (newStep < 0) {
      return this.getCurrentOnboardingStep(0, 0);
    }

    ipcRenderer.send(
      "LOG_INFO",
      `ONBOARDING - etape ${ONBOARDING_ORDERED_STEPS[newStep].type}`
    );

    return [newStep, currentOnboardingStep];
  }

  setPermissionsStep(): void {
    const { currentExam } = this.props;
    const { currentStep, currentOnboardingStep } = this.state;

    this.checkingPermissions = true;
    this.previousStep = currentStep;
    this.previousOnboardingStep = currentOnboardingStep;

    const newStep = ONBOARDING_ORDERED_STEPS.findIndex(
      (value: OnboardingSubStepperOption) =>
        value.type === ONBOARDING_PERMISSIONS
    );

    const newOnboardingStep =
      currentExam.onboarding?.steps.findIndex(
        (step: ExamOnboardingStep) => step.type === ONBOARDING_PERMISSIONS
      ) ?? -1;

    ipcRenderer.send(
      "LOG_INFO",
      "ONBOARDING - retour sur ONBOARDING_PERMISSIONS"
    );

    this.setState({
      currentStep: newStep,
      currentOnboardingStep: newOnboardingStep
    });
  }

  isLaunchButtonAvailable(): boolean {
    const {
      currentExam,
      isOnline,
      isExamAvailable,
      hasAgreed,
      isGoingBackToExam
    } = this.props;

    if (currentExam.examParams?.proctoring) {
      if (!isOnline) {
        return false;
      }
    }
    if (!isGoingBackToExam) {
      if (!(hasAgreed && isExamAvailable)) return false;
    }
    return true;
  }

  render(): JSX.Element {
    const {
      appDataPath,
      currentExam,
      delayExpired,
      displayAvailabilityTag,
      hasAgreed,
      isExamAvailable,
      isGoingBackToExam,
      isOnboardingStarted,
      token,
      tokenModalVisible,
      userId,
      navigation,
      currentAttachedFiles,
      currentUser,
      createArchiveBeforeUpload,
      getError,
      onBoardingExam,
      startExam,
      updateAgreement
    } = this.props;
    const { currentOnboardingStep, currentStep } = this.state;

    const canStartExam = this.isLaunchButtonAvailable();
    const infoDetails = [
      {
        label: i18n.t("homepage.subject"),
        value: currentExam.subject?.name || ""
      },
      {
        label: i18n.t("homepage.date"),
        value: moment(currentExam.startDate).format("LL")
      },
      {
        label: i18n.t("homepage.time"),
        value: moment(currentExam.startDate).format("HH[h]mm")
      },
      {
        label: i18n.t("homepage.duration"),
        value: displayStaticTimer(currentExam.duration)
      }
    ];
    if (currentUser.establishments.length > 1) {
      infoDetails.splice(1, 0, {
        label: i18n.t("homepage.establishment"),
        value: currentUser.currentEstablishment?.name || ""
      });
    }

    return (
      <>
        <View style={styles.examInfoContainer}>
          <View style={styles.headerContent}>
            <ExamInfoTitle
              title={currentExam.name || ""}
              containerStyle={styles.titleContainer}
              titleStyle={styles.title}
            />
            <View style={styles.examInfo}>
              <ExamInfoDetails details={infoDetails} />
            </View>
          </View>
          <OnboardingStepper
            currentExam={currentExam}
            currentStep={currentStep}
            currentOnboardingStep={currentOnboardingStep}
            getError={getError}
            appDataPath={appDataPath}
            createArchiveBeforeUpload={createArchiveBeforeUpload}
            token={token as string}
            tokenModalVisible={tokenModalVisible}
            userId={userId}
            canStartExam={canStartExam || isGoingBackToExam}
            delayExpired={delayExpired}
            displayAvailabilityTag={displayAvailabilityTag}
            isOnboardingStarted={isOnboardingStarted}
            isExamAvailable={isExamAvailable}
            navigation={navigation}
            hasAgreed={hasAgreed}
            isGoingBackToExam={isGoingBackToExam}
            currentAttachedFiles={currentAttachedFiles}
            onBoardingExam={onBoardingExam}
            updateAgreement={updateAgreement}
            startExam={() => {
              startExam();
            }}
            onStepChange={async (newStep: number) => {
              let canGoOn = true;
              if (IS_WEB_ENABLE) {
                const permissionsStepIndex = ONBOARDING_ORDERED_STEPS.findIndex(
                  (value: OnboardingSubStepperOption) =>
                    value.type === ONBOARDING_PERMISSIONS
                );
                if (newStep > permissionsStepIndex) {
                  if (
                    !_.includes(
                      [
                        ONBOARDING_MICROPHONE,
                        ONBOARDING_FACE_PHOTO,
                        ONBOARDING_ID_PHOTO,
                        ONBOARDING_VIDEO_360
                      ],
                      ONBOARDING_ORDERED_STEPS[newStep].type
                    )
                  ) {
                    const result = await checkMediaPermissions(
                      null,
                      null,
                      [],
                      examNeedsMicrophone(currentExam),
                      examNeedsCamera(currentExam)
                    );
                    if (result && (result.audioError || result.videoError)) {
                      canGoOn = false;
                      this.setPermissionsStep();
                    }
                  }
                }
              }
              if (canGoOn) {
                const steps = this.getCurrentOnboardingStep(
                  newStep,
                  currentStep
                );

                this.setState({
                  currentStep: steps[0],
                  currentOnboardingStep: steps[1]
                });
              }
            }}
          />
        </View>
        {ONBOARDING_ORDERED_STEPS[currentStep].showRules && (
          <ExamTakingAllRules
            examParams={currentExam.examParams}
            hasTimer={currentExam.hasTimer}
          />
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  examInfoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: COLOR_WHITE
  },
  headerContent: {
    backgroundColor: COLOR_BLUE_LIGHTER,
    padding: PADDING_SIDES * 0.5
  },
  title: {
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_36,
    lineHeight: 24,
    color: COLOR_BLUE
  },
  titleContainer: {
    flexDirection: "row",
    paddingTop: PADDING_SIDES * 0.1,
    paddingBottom: PADDING_SIDES * 0.2,
    alignItems: "baseline"
  },
  examInfo: {
    width: "100%",
    flexDirection: "row"
  }
});

export default connect((state: RootState) => {
  return {
    currentUser: state.user
  };
})(ExamInfoOnboarding);
