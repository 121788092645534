import React from "react";
import { ProgressSteps, ProgressStep } from "react-native-progress-steps";
import { StyleSheet, View } from "react-native";
import _ from "lodash";
import OnboardingItem from "./OnboardingItem";
import { GetErrorAction } from "../../modules/main/types/error";
import {
  ExamOnboarding,
  ExamOnboardingStep,
  ExamType
} from "../../modules/exams/types/exam";
import {
  CreateArchiveBeforeUploadAction,
  MediaObjectType
} from "../../modules/exams/types/attachedfiles";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";
import {
  COLOR_BLUE_STEPPER,
  COLOR_BLUE_TESTWE,
  COLOR_GREEN_STEPPER,
  COLOR_GREY_STEPPER
} from "../../../static/misc/colors";
import {
  OnboardingStepperOption,
  ONBOARDING_DEFAULT,
  ONBOARDING_ORDERED_STEPS,
  ONBOARDING_STEPPER_OPTIONS,
  ONBOARDING_UNLOCK_EXAM
} from "../../../static/misc/onboarding/onboardingsteps";
import { AUDIO_QUESTION } from "../../../static/misc/constants";
import { StackNavigatorProp } from "../../router/StackNavigator";

export interface OnboardingStepperProps {
  currentExam: ExamType;
  appDataPath: string;
  canStartExam: boolean;
  currentStep: number;
  currentOnboardingStep: number;
  delayExpired: boolean;
  displayAvailabilityTag: boolean;
  hasAgreed: boolean;
  isOnboardingStarted: boolean;
  isGoingBackToExam: boolean;
  isExamAvailable: boolean;
  token: string;
  tokenModalVisible: boolean;
  userId: string;
  currentAttachedFiles: MediaObjectType | undefined;
  navigation: StackNavigatorProp;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  onBoardingExam: () => Promise<boolean>;
  onStepChange: (value: number) => void;
  startExam: () => void;
  updateAgreement: () => void;
}

export interface OnboardingStepperState {
  activeStep: number;
}

class OnboardingStepper extends React.PureComponent<
  OnboardingStepperProps,
  OnboardingStepperState
> {
  stepperOptions: OnboardingStepperOption[] = ONBOARDING_STEPPER_OPTIONS;

  onboarding: ExamOnboarding = ONBOARDING_DEFAULT;

  constructor(props: OnboardingStepperProps) {
    super(props);
    this.setOnboarding();
    this.setStepperOptions();
    this.state = {
      activeStep: this.getActiveStep(props.currentStep)
    };
  }

  componentDidUpdate(prevProps: OnboardingStepperProps): void {
    const { currentStep, currentOnboardingStep } = this.props;

    if (currentStep !== prevProps.currentStep) {
      this.setState({
        activeStep: this.getActiveStep(currentStep)
      });
    }

    if (currentOnboardingStep !== prevProps.currentOnboardingStep) {
      this.setOnboarding();

      this.setState({
        activeStep: this.getActiveStep(currentStep)
      });

      this.setStepperOptions();
    }
  }

  getActiveStep(currentStep: number): number {
    const newType = ONBOARDING_ORDERED_STEPS[currentStep].type;
    if (newType) {
      const newActiveStep = this.stepperOptions.findIndex(
        (value: OnboardingStepperOption) =>
          value.elements.indexOf(newType) !== -1
      );
      return newActiveStep;
    }
    return 0;
  }

  setOnboarding(): void {
    const { currentExam } = this.props;
    this.onboarding =
      currentExam.onboarding &&
      _.isPlainObject(currentExam.onboarding) &&
      currentExam.onboarding.steps.length > 0
        ? currentExam.onboarding
        : ONBOARDING_DEFAULT;
  }

  setStepperOptions(): void {
    const { currentExam } = this.props;

    const filteredStepperOptions: OnboardingStepperOption[] = [];

    this.stepperOptions.forEach((option: OnboardingStepperOption) => {
      if (
        currentExam.onboarding?.steps.some((value: ExamOnboardingStep) =>
          option.elements.includes(value.type)
        )
      ) {
        filteredStepperOptions.push(option);
      }
    });

    this.stepperOptions = filteredStepperOptions;
  }

  render(): JSX.Element {
    const {
      appDataPath,
      canStartExam,
      currentStep,
      currentOnboardingStep,
      currentExam,
      delayExpired,
      displayAvailabilityTag,
      isOnboardingStarted,
      hasAgreed,
      isGoingBackToExam,
      currentAttachedFiles,
      navigation,
      isExamAvailable,
      onBoardingExam,
      updateAgreement,
      token,
      tokenModalVisible,
      userId,
      createArchiveBeforeUpload,
      getError,
      onStepChange,
      startExam
    } = this.props;
    const { activeStep } = this.state;

    return (
      <>
        {activeStep > -1 && (
          <ProgressSteps
            borderWidth={3}
            labelColor={COLOR_BLUE_TESTWE}
            activeLabelColor={COLOR_BLUE_TESTWE}
            completedLabelColor={COLOR_BLUE_TESTWE}
            activeStepNumColor={COLOR_BLUE_TESTWE}
            completedStepNumColor={COLOR_BLUE_TESTWE}
            disabledStepNumColor={COLOR_BLUE_TESTWE}
            completedCheckColor={COLOR_BLUE_TESTWE}
            activeStepIconBorderColor={COLOR_BLUE_STEPPER}
            progressBarColor={COLOR_GREY_STEPPER}
            completedProgressBarColor={COLOR_GREEN_STEPPER}
            completedStepIconColor={COLOR_GREEN_STEPPER}
            activeStepIconColor={COLOR_BLUE_STEPPER}
            disabledStepIconColor={COLOR_GREY_STEPPER}
            activeStep={activeStep}
            isComplete={
              this.onboarding.steps[currentOnboardingStep].type ===
              ONBOARDING_UNLOCK_EXAM
            }
            removeBtnRow
          >
            {this.stepperOptions.map((step: OnboardingStepperOption) => (
              <ProgressStep
                removeBtnRow
                key={guidGenerator()}
                label={i18n.t(step.label)}
              >
                <View style={styles.emptyContainer} />
              </ProgressStep>
            ))}
          </ProgressSteps>
        )}
        <View style={{ flex: 3 }}>
          <OnboardingItem
            currentExam={currentExam}
            delayExpired={delayExpired}
            displayAvailabilityTag={displayAvailabilityTag}
            isOnboardingStarted={isOnboardingStarted}
            onBoardingExam={onBoardingExam}
            updateAgreement={updateAgreement}
            currentStep={currentStep}
            currentOnboardingStep={currentOnboardingStep}
            onboarding={this.onboarding}
            hasAgreed={hasAgreed}
            isGoingBackToExam={isGoingBackToExam}
            isExamAvailable={isExamAvailable}
            currentAttachedFiles={currentAttachedFiles}
            navigation={navigation}
            audio={
              currentExam.questionTypes.find(
                (type) => type === AUDIO_QUESTION
              ) !== undefined ||
              currentExam.examParams?.proctoringLiveRecord === true
            }
            proctoring={
              (currentExam.examParams?.proctoring ||
                currentExam.examParams?.proctoringLive) ??
              false
            }
            userId={userId}
            appDataPath={appDataPath}
            examId={currentExam.id}
            token={token}
            tokenModalVisible={tokenModalVisible}
            getError={getError}
            createArchiveBeforeUpload={createArchiveBeforeUpload}
            canStartExam={canStartExam}
            startDate={currentExam.startDate}
            startExam={startExam}
            onStepChange={onStepChange}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  emptyContainer: {}
});

export default OnboardingStepper;
