import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { ipcRenderer } from "electron";
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import MainContainer from "../modules/main/MainViewContainer";
import ExamFlow from "./ExamFlow";
import { RootState } from "../store/rootreducer";
import ErrorNotification from "../atomic/organisms/ErrorNotification";
import { resetError } from "../modules/main/actions/error";
import { clearCredentials } from "../modules/authentification/actions/auth";
import ExamsContainer from "../modules/exams/ExamsContainer";
import { HOMEPAGE, EXAMSLIST, TESTING } from "../../static/misc/menu";
import ProfileContainer from "../modules/main/ProfileContainer";
import FaqWebview from "../atomic/atoms/FaqWebview";
import TestingContainer from "../modules/testing/TestingView";
import ExitAppModal from "../atomic/molecules/ExitAppModal";

export type AuthentifiedFlowParamList = {
  Main:
    | undefined
    | {
        pageSelected: number;
      };
  Exams: undefined;
  ExamFlow: {
    screen: string;
    params: any;
  };
  Testing: undefined;
  Profile: undefined;
  Faq: undefined;
};

export type MainScreenRouteProp = RouteProp<AuthentifiedFlowParamList, "Main">;
export type ProfileScreenRouteProp = RouteProp<
  AuthentifiedFlowParamList,
  "Profile"
>;
export type ExamFlowScreenRouteProp = RouteProp<
  AuthentifiedFlowParamList,
  "ExamFlow"
>;

const AuthentifiedStack = createStackNavigator<AuthentifiedFlowParamList>();
const Stack = createStackNavigator();
const useTypeSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function AuthentifiedFlow(): JSX.Element {
  const [exitingApplication, setExitingApplication] = useState(false);
  const dispatch = useDispatch();
  const error = useTypeSelector((state) => state.error);
  const eventExiting = useTypeSelector(
    (state) => state.status.exitingApplication
  );

  useEffect(() => {
    ipcRenderer.on("QUIT_APP", () => {
      setExitingApplication(true);
    });
  }, []);

  useEffect(() => {
    setExitingApplication(eventExiting);
  }, [eventExiting]);

  return (
    <>
      <ErrorNotification
        error={error}
        resetError={() => dispatch(resetError())}
        onForcedLogout={() => {
          ipcRenderer.send(
            "LOG_INFO",
            "An error occurred and forced the user to logout"
          );
          dispatch(clearCredentials());
        }}
      />

      <ExitAppModal isVisible={exitingApplication} onClose={() => undefined} />

      <AuthentifiedStack.Navigator initialRouteName="Main" headerMode="none">
        <AuthentifiedStack.Screen
          name="Main"
          key={HOMEPAGE}
          component={MainContainer}
          options={{ title: "TestWe" }}
        />
        <AuthentifiedStack.Screen
          name="Exams"
          key={EXAMSLIST}
          component={ExamsContainer}
          options={{ title: "TestWe" }}
        />
        <AuthentifiedStack.Screen
          name="Testing"
          key={TESTING}
          component={TestingContainer}
          options={{ title: "TestWe" }}
        />
        <AuthentifiedStack.Screen
          name="Profile"
          key="Profile"
          component={ProfileContainer}
          options={{ title: "TestWe" }}
        />
        <AuthentifiedStack.Screen name="Faq" key="Faq" component={FaqWebview} />
        <Stack.Screen name="ExamFlow" key="ExamFlow" component={ExamFlow} />
      </AuthentifiedStack.Navigator>
    </>
  );
}
