import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { ipcRenderer } from "electron";
import _ from "lodash";
import os from "os";
import HeaderContainer from "../../atomic/organisms/Header/HeaderContainer";
import ExamInfo from "../../atomic/organisms/ExamInfo";
import { ExamInfoRouteProp } from "../../router/ExamFlow";
import { StackNavigatorProp } from "../../router/StackNavigator";
import { ExamType } from "./types/exam";
import {
  CreatePublicKeyAction,
  GetExamPublicKeyAction,
  PublicKeyType
} from "./types/publickeys";
import { GetErrorAction } from "../main/types/error";
import {
  CreateExamTakingAction,
  EndExamAction,
  StartExamAction
} from "../examTaking/types/examTaking";
import {
  CreateArchiveBeforeUploadAction,
  MediaObjectType,
  UpdateMediaObjectAction
} from "./types/attachedfiles";
import { StudentPaperType } from "../examTaking/types/studentPaper";
import {
  ONBOARDING_CAMERA_PERMISSION,
  ONBOARDING_CONNECTION,
  ONBOARDING_DEFAULT,
  ONBOARDING_GENERAL_CONDITIONS,
  ONBOARDING_MICROPHONE,
  ONBOARDING_MICROPHONE_PERMISSION,
  ONBOARDING_PERMISSIONS,
  ONBOARDING_TEACHER_INSTRUCTIONS,
  ONBOARDING_WELCOME
} from "../../../static/misc/onboarding/onboardingsteps";
import { AUDIO_QUESTION, IS_WEB_ENABLE } from "../../../static/misc/constants";
import { examNeedsCamera, examNeedsMicrophone } from "../../services/equipment";
import {
  GetExamHashAction,
  GetSyncExamAction,
  GetSyncExamActionApiPayload
} from "./types/exams";
import { SetExamSyncStatusAction, SyncStatus } from "../main/types/status";

export interface ExamInfoViewProps {
  locale: string;
  navigation: StackNavigatorProp;
  mediaObjects: MediaObjectType[];
  route: ExamInfoRouteProp;
  userId: string;
  appDataPath: string;
  nextExams: ExamType[];
  gettingExam: boolean;
  gettingPublicKey: boolean;
  isOnline: boolean;
  token: string | undefined;
  publicKeys: PublicKeyType[];
  studentPapers: StudentPaperType[];
  currentExamTaking: ExamType | undefined;
  examInfoId: string;
  updateMediaObject: (
    examId: string,
    mediaObjectId: string,
    mimeType: string | undefined,
    isUnciphered: boolean
  ) => UpdateMediaObjectAction;
  createPublicKey: (examId: string, publicKey: string) => CreatePublicKeyAction;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  getExamPublicKey: (examId: string, token: string) => GetExamPublicKeyAction;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  createExamTaking: (exam: ExamType) => CreateExamTakingAction;
  startExamTaking: () => StartExamAction;
  endExamTaking: () => EndExamAction;
  getExamHash: (token: string, examId: string) => Promise<GetExamHashAction>;
  examSyncStatus: SyncStatus;
  setExamSyncStatus: (status: SyncStatus) => SetExamSyncStatusAction;
  getSyncExam: (
    userToken: string,
    examId: string
  ) => GetSyncExamAction<GetSyncExamActionApiPayload>;
}

interface ExamInfoState {
  currentExam?: ExamType;
  showMenu: boolean;
}

class ExamInfoView extends React.PureComponent<
  ExamInfoViewProps,
  ExamInfoState
> {
  handleSyncInterval?: NodeJS.Timer;

  constructor(props: ExamInfoViewProps) {
    super(props);

    this.state = {
      showMenu: false,
      currentExam: this.completeExamWithOnboarding()
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount(): void {
    const { currentExam } = this.state;
    ipcRenderer.send(
      "LOG_INFO",
      `PAGEMOVE | ExamInfoView uuid: ${currentExam?.id}`
    );

    const currentExamUpdated = this.completeExamWithOnboarding(currentExam);

    if (currentExamUpdated) this.setState({ currentExam: currentExamUpdated });

    const randomDelayInMs = Math.floor(
      // Between 2 and 4 min
      Math.random() * this.minToMs(2) + this.minToMs(2)
    );

    this.compareExamsHash();

    this.handleSyncInterval = setInterval(
      () => this.compareExamsHash(),
      randomDelayInMs
    );
  }

  componentDidUpdate(prevProps: ExamInfoViewProps): void {
    const { currentExam } = this.state;
    const {
      token,
      nextExams,
      examSyncStatus: nextExamSyncStatus,
      getSyncExam
    } = this.props;
    const { examSyncStatus: prevExamSyncStatus } = prevProps;

    // Should sync exam in global state
    if (
      token &&
      nextExamSyncStatus === SyncStatus.PENDING &&
      prevExamSyncStatus === SyncStatus.SUCCESS
    ) {
      if (currentExam?.id) {
        getSyncExam(token, currentExam.id);
      }
    }

    // Should update currentExam state
    if (
      currentExam?.id &&
      nextExamSyncStatus === SyncStatus.SUCCESS &&
      prevExamSyncStatus === SyncStatus.PENDING
    ) {
      const current = nextExams.find((exam) => exam.id === currentExam.id);

      if (current) {
        ipcRenderer.send(
          "LOG_INFO",
          `LAST_MIN_SYNC | exam uuid: ${currentExam?.id} - exam successfully synced`
        );

        this.setState({
          currentExam: this.completeExamWithOnboarding(current)
        });
      }
    }
  }

  componentWillUnmount(): void {
    if (this.handleSyncInterval) {
      clearInterval(this.handleSyncInterval);
    }
  }

  minToMs = (n: number): number => n * 60 * 1000;

  completeExamWithOnboarding(currentExam?: ExamType): ExamType | undefined {
    const { nextExams, route } = this.props;
    let currentExamTmp: ExamType | undefined = currentExam;
    if (
      !currentExamTmp &&
      nextExams.length > 0 &&
      route &&
      route.params &&
      route.params.examInfoId
    ) {
      currentExamTmp = nextExams.find(
        (exam) => exam.id === route.params?.examInfoId
      );
    }

    if (currentExamTmp && !currentExamTmp.onboarding) {
      ipcRenderer.send(
        "LOG_ERROR",
        `ONBOARDING - exam has no onboarding ${JSON.stringify(currentExamTmp)}`
      );
      currentExamTmp.onboarding = ONBOARDING_DEFAULT;
    }

    if (currentExamTmp && currentExamTmp.onboarding) {
      // if there is no onboarding on the backend, the API returns an empty array
      if (_.isArray(currentExamTmp.onboarding)) {
        currentExamTmp.onboarding = ONBOARDING_DEFAULT;
      }
      if (!currentExamTmp.onboarding.steps) {
        currentExamTmp.onboarding.steps = [];
      }

      if (
        currentExamTmp.examParams?.proctoring ||
        currentExamTmp.examParams?.proctoringLive
      ) {
        // we add the welcome page
        currentExamTmp.onboarding.steps.unshift({
          type: ONBOARDING_WELCOME,
          required: true,
          order: 0,
          title: "welcome"
        });
        // we add the camera permissions page
        if (!IS_WEB_ENABLE && os.platform() === "darwin") {
          currentExamTmp.onboarding.steps.unshift({
            type: ONBOARDING_CAMERA_PERMISSION,
            required: true,
            order: 0,
            title: "camera_step_title"
          });
        }
      }

      const hasAudio = examNeedsMicrophone(currentExamTmp);

      ipcRenderer.send(
        "LOG_INFO",
        `hasAudio: ${hasAudio} -- onboarding steps: ${JSON.stringify(
          currentExamTmp.onboarding.steps
        )}`
      );

      // we add the camera permissions page
      if (!IS_WEB_ENABLE && os.platform() === "darwin") {
        if (hasAudio) {
          currentExamTmp.onboarding.steps.unshift({
            type: ONBOARDING_MICROPHONE_PERMISSION,
            required: true,
            order: 0,
            title: "micro_step_title"
          });
        }
      }

      // we add the teacher's instructions page
      currentExamTmp.onboarding.steps.unshift({
        type: ONBOARDING_TEACHER_INSTRUCTIONS,
        required: true,
        order: 0
      });

      // we add the general conditions page
      currentExamTmp.onboarding.steps.unshift({
        type: ONBOARDING_GENERAL_CONDITIONS,
        required: true,
        order: 0,
        title: "generalConditions"
      });

      if (IS_WEB_ENABLE) {
        // we add the permissions check page
        if (hasAudio || examNeedsCamera(currentExamTmp)) {
          currentExamTmp.onboarding.steps.unshift({
            type: ONBOARDING_PERMISSIONS,
            required: true,
            order: 0,
            title: "permissionsCheck"
          });
        }

        // we add the connection check page
        currentExamTmp.onboarding.steps.unshift({
          type: ONBOARDING_CONNECTION,
          required: true,
          order: 0
        });
      }
      if (
        currentExamTmp.questionTypes.find((type) => type === AUDIO_QUESTION) !==
        undefined
      ) {
        currentExamTmp.onboarding.steps.unshift({
          actionText: "please_start_recording_and_say_something",
          order: 0,
          questionText: "do_you_hear_recorded_sound",
          recorderText: "recorder",
          required: true,
          subtitle: "microphone_step_subtitle",
          title: "microphone_step_title",
          type: ONBOARDING_MICROPHONE
        });
      }

      // if (
      //   currentExam.examParams?.proctoring ||
      //   currentExam.examParams?.proctoringLive
      // ) {
      //   // we add the connection check page
      //   currentExam.onboarding.steps.unshift({
      //     type: ONBOARDING_PROCTORING_INSTRUCTIONS,
      //     required: true,
      //     order: 0,
      //     title: "proctoringLiveInstructions"
      //   });
      // }
    }
    return currentExamTmp;
  }

  isSyncTime(startDate: ExamType["startDate"]): boolean {
    const quarterHourInMs = Math.floor(Math.random() * this.minToMs(15));

    const startTime = new Date(startDate).getTime();
    const syncTime = new Date(startTime - quarterHourInMs).getTime();

    const now = Date.now();

    // Between more and less 15 minutes before the start of the exam and the start date of the exam.
    return now > syncTime && now < startTime;
  }

  async compareExamsHash(): Promise<void> {
    const { token, getExamHash, setExamSyncStatus } = this.props;
    const { currentExam } = this.state;

    if (!currentExam || !token || !this.isSyncTime(currentExam.startDate))
      return;

    const previousHash = currentExam.hash;

    const nextHash = await getExamHash(token, currentExam.id).then(
      (res) => res.payload.data?.hash
    );

    // Check if the previous hash is not the same as the other one in the state
    if (previousHash !== nextHash) {
      ipcRenderer.send(
        "LOG_INFO",
        `LAST_MIN_SYNC | exam uuid: ${currentExam?.id} - hash is different ${previousHash}/${nextHash}`
      );
      setExamSyncStatus(SyncStatus.PENDING);
    }
  }

  // Toggle the user menu
  toggleMenu(toggle?: boolean): void {
    const { showMenu } = this.state;
    this.setState({
      showMenu: toggle !== undefined ? toggle : !showMenu
    });
  }

  render(): JSX.Element {
    const {
      getError,
      createPublicKey,
      getExamPublicKey,
      updateMediaObject,
      createExamTaking,
      createArchiveBeforeUpload,
      startExamTaking,
      endExamTaking,
      navigation,
      token,
      mediaObjects,
      isOnline,
      userId,
      gettingExam,
      gettingPublicKey,
      publicKeys,
      locale,
      studentPapers,
      currentExamTaking,
      appDataPath
    } = this.props;
    const { currentExam, showMenu } = this.state;
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (showMenu) this.toggleMenu(false);
        }}
      >
        <View style={{ flex: 1 }}>
          <HeaderContainer
            toggleMenu={this.toggleMenu}
            showMenu={showMenu}
            disableSynchronisation
            navigation={navigation}
          />
          {currentExam && (
            <ExamInfo
              userId={userId}
              appDataPath={appDataPath}
              updateMediaObject={updateMediaObject}
              currentAttachedFiles={mediaObjects.find(
                (e) => e.examId === currentExam?.id
              )}
              createExamTaking={createExamTaking}
              createPublicKey={createPublicKey}
              createArchiveBeforeUpload={createArchiveBeforeUpload}
              getError={getError}
              gettingExam={gettingExam}
              gettingPublicKey={gettingPublicKey}
              currentExam={currentExam}
              publicKeys={publicKeys}
              navigation={navigation}
              isOnline={isOnline}
              getExamPublicKey={getExamPublicKey}
              token={token}
              key={locale}
              studentPapers={studentPapers}
              currentExamTaking={currentExamTaking}
              startExamTaking={startExamTaking}
              endExamTaking={endExamTaking}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default ExamInfoView;
